import React, { createContext, useContext } from 'react';

const PresenceContext = createContext();

export const usePresence = () => useContext(PresenceContext);

export const PresenceProvider = (props) => {
    const [presenceChannel, setPresenceChannel] = React.useState(null);

    return (
        <PresenceContext.Provider value={{ presenceChannel, setPresenceChannel }}>
            {props.children}
        </PresenceContext.Provider>
    );
};