import { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
// import Functions
import { API_VERSION } from "../../Functions";

export default function Interests() {
    const { t } = useTranslation();
    // Set load more
    const [reqLimit, setReqLimit] = useState(24);
    // Set data
    const [data, setData] = useState([]);
    const [dataInt, setDataInt] = useState([]);
    // Set interests data
    const [interests, setInterests] = useState([]);
    const [interestCard, setIC] = useState({
        icon: 'fi-rr-plus',
        color: '#fff',
        background: 'transparent'
    })
    const interestRef = useRef([]);
    
    // Get token
    var token = undefined;
    if(Cookies.get('loginToken')){
        token = Cookies.get('loginToken');
    }else {
        token = "not_logged";
    }

    // Capitalize first letter
    function ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // CALL API TO VERIFY IF [USER] IS VERIFIED
    const userInterestsVerify = async () => {
        if(token !== "not_logged"){
            const response = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/user/${token}/interests`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        app: 'web'
                    }
                }
            ).then(res => res.data);
    
            setData(response)
        }else{
            setData({ interests: "not_logged" })
        }
    }

    // GET ALL INTERESTS
    const getInterests = async () => {
        const interest = await axios.get(
            `https://api.snot.fr/v${API_VERSION}/interests`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    req: 0,
                    limit: 12
                }
            }
        ).then(res => res.data);

        setDataInt(interest);
    }

    /* ----------------------
     * To build all interests
     * ----------------------
     */
    const AllInterests = () => {
        return (
            <>
                {dataInt.interests.map((response) => {
                    if(interestRef.current.includes(response.interest)) {
                        return (
                            <div className={"interestCard_iMCPCVB1 ref_"+response.interest} onClick={() => {selectInterest(response.interest)}} style={{ backgroundColor: interestCard.background, color: interestCard.color }}>
                                <span>{ucFirst(response.interest.replace('_', ' '))}</span>
                                <i className={"fi "+interestCard.icon}></i>
                            </div>
                        )
                    }else{
                        return (
                            <div className={"interestCard_iMCPCVB1 ref_"+response.interest} onClick={() => {selectInterest(response.interest)}}>
                                <span>{ucFirst(response.interest.replace('_', ' '))}</span>
                                <i className="fi fi-rr-plus"></i>
                            </div>
                        )
                    }
                })}
            </>
        );
    }

    /* ------------------- 
     * FUNCTIONS FOR MODAL
     * -------------------
     */
    const closeModal = (e) => {
        let modal = document.getElementById('interestCenter');

        e.preventDefault();
        modal.setAttribute('aria-hidden', 'true');
        modal.removeAttribute('aria-modal');
        modal.removeEventListener('click', closeModal);
        modal.querySelector('.js-modal-stop').removeEventListener('click', e.stopPropagation());
        const hideModal = function () {
            modal.style.display = "none";
            modal.removeEventListener('animationend', hideModal);
            modal = null;
        }
        modal.addEventListener('animationend', hideModal);
        document.body.style.overflow = "auto";
    }

    // Load more interests
    const getNewInterests = async (limit) => {
        const interest = await axios.get(
            `https://api.snot.fr/v${API_VERSION}/interests`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    req: 0,
                    limit: limit
                }
            }
        ).then(res => res.data);

        setDataInt(interest);
    }

    const loadMore = () => {
        setReqLimit(reqLimit + 12);
        // Get interests
        getNewInterests(reqLimit);
        // Set item already selected
        if(localStorage.getItem('interests_list')){
            var storage = localStorage.getItem('interests_list').split(',');
            storage.map((ref) => {
                // UI / UX
                setIC({
                    ...interestCard,
                    icon: "fi-rr-check",
                    color: '#000',
                    background: '#fff'
                });
                // Append to list
                var index = interests.indexOf(ref);
                if (index <= -1) {
                    interests.push(ref);
                }
                // Set to ref
                var indexRef = interestRef.current.indexOf(ref);
                if (indexRef <= -1) {
                    interestRef.current.push(ref);
                }
            });
        }
    }

    // Select interest
    const selectInterest = (ref) => {
        var card = $('.interestCard_iMCPCVB1.ref_'+ref);
        var icon = $('.interestCard_iMCPCVB1.ref_'+ref+' > i');
        // UI / UX
        if(icon.hasClass("fi-rr-plus")) {
            icon.removeClass("fi-rr-plus").addClass("fi-rr-check");
            card.css({ backgroundColor: '#fff', color: "#000" });
            // Append to list
            interests.push(ref);
            // Set to ref
            interestRef.current.push(ref);
        }else{
            icon.removeClass("fi-rr-plus").addClass("fi fi-rr-plus");
            card.css({ backgroundColor: 'transparent', color: "#fff" });
            // Remove from list
            var index = interests.indexOf(ref);
            if (index > -1) {
                interests.splice(index, 1);
            }
            // Remove from ref
            var indexRef = interestRef.current.indexOf(ref);
            if (indexRef > -1) {
                interestRef.current.splice(indexRef, 1);
            }
        }
        // Set to local storage
        localStorage.setItem('interests_list', interests);
    }

    // Add interests function
    const finishInterests = (e) => {
        if(interests.length < 3){
            let error = 'You must select at least 3.';
            if(i18n.resolvedLanguage === "fr"){
                error = 'Vous devez en sélectionner au moins 3.';
            }

            $('.interestCenter_mainContainerPCVB1 p').css({ marginTop: -20 }).text(error)
        }else {
            axios({
                method: "PUT",
                url: `https://api.snot.fr/v${API_VERSION}/user/${token}/interests/new`,
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    app: 'web'
                },
                data: interests
            })
            .then((response) => {
                $('.interestCenter_mainContainerPCVB1 p').css({ marginTop: 0 }).text('')
                // HIDE ELEMENTS / EDIT ELEMENTS
                $('.interestCenter_mainContainerPCVB1 h1').css({ width: '100%', textAlign: 'center', margin: '10px 0 0 0' }).text(t('interests.thanksPart.title'));
                $('.interestCenter_mainContainerPCVB1 h2').css({ width: '100%', textAlign: 'center', margin: 0 }).text(t('interests.thanksPart.subtitle'));
                $('.interestsMainContainer_iCMCPCVB1').css('display', 'none');
                $('.loadMoreButton_interestsPCVB1').css('display', 'none');
                // -- FOR ILLUSTRATION
                $('.interestsThanks_illustrationPCVB1').css('display', 'flex');
                // -- FOR BUTTONS
                $('.actionsButtons_interestsPCVB1 .saveInterests').text(t('interests.thanksPart.button')).on('click', () => closeModal(e));
                $('.actionsButtons_interestsPCVB1 .closeInterests').css('display', 'none');
                // Remove from local storage
                localStorage.removeItem('interests_list');
            })
        }
    }

    // Close modal with interests
    const cancelInterests = (e) => {
        axios({
            method: "PUT",
            url: `https://api.snot.fr/v${API_VERSION}/user/${token}/interests/new`,
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                app: 'web'
            },
            data: ['no_interest']
        })
        .then((response) => {
            closeModal(e)
            // Remove from local storage
            localStorage.removeItem('interests_list');
        })
    }

    // BUILD THE MODAL
    const Modal = () => {
        if(!data.interests && dataInt.interests){
            return(
                <>
                    <aside id="interestCenter" className="modal" aria-modal="true">
                        <div className="modal-wrapper js-modal-stop" onClick={(e) => e.stopPropagation()} style={{ padding: 10, background: "#101010", width: 400 }}>
                            <div className="interestCenter_mainContainerPCVB1">
                                <h1>{t('interests.title')}</h1>
                                <h2>{t('interests.subtitle')}</h2>
                                <p></p>
                                <div className="interestsMainContainer_iCMCPCVB1">
                                    <AllInterests />
                                </div>
                                {/* ILLUSTRATION START */}
                                <div className="interestsThanks_illustrationPCVB1" style={{ display: 'none', justifyContent: 'center', margin: "25px 0" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="186" height="190" fill="none" viewBox="0 0 186 190">
                                        <path fill="#26262C" d="M185.25 189.654a.353.353 0 0 1-.101.245.34.34 0 0 1-.245.101H.346a.345.345 0 0 1-.346-.346.347.347 0 0 1 .346-.347h184.558a.354.354 0 0 1 .245.101.354.354 0 0 1 .101.246Z"/>
                                        <path fill="#FF6366" d="M150.222 8.572a7.23 7.23 0 0 0-1.633-3.437 13.803 13.803 0 0 0-9.477-4.674 13.766 13.766 0 0 0-9.999 3.413l-.116.105a14.046 14.046 0 0 0-18.541-.928 14.101 14.101 0 0 0-4.107 5.297 13.177 13.177 0 0 0 .59 11.937 13.808 13.808 0 0 0 1.624 2.277c.636.728 17.811 19.882 17.982 20.075a.22.22 0 0 0 .166.075c.006 0 .014-.003.023-.003a.079.079 0 0 0 .035-.008.218.218 0 0 0 .136-.1l.09-.148.108-.183 1.589-2.662c4.407-7.383 10.348-14.349 17.66-20.703.471-.41.907-.858 1.305-1.34a11.803 11.803 0 0 0 2.701-6.628 9.28 9.28 0 0 0-.136-2.365Z"/>
                                        <path fill="#F2F2F2" d="m71.425 158.308 3.234-10.56a35.54 35.54 0 0 0-14.212-9.545c5.317 8.829.165 20.348 2.312 30.434a21.46 21.46 0 0 0 4.066 8.614 21.4 21.4 0 0 0 7.389 5.996l4.908 6.097a35.992 35.992 0 0 0 2.634-29.964 34.743 34.743 0 0 0-4.008-7.723c-2.917 3.438-6.323 6.651-6.323 6.651Z"/>
                                        <path fill="#F2F2F2" d="m53.639 159.657-1.803-10.897a35.469 35.469 0 0 0-16.967-2.19c8.687 5.526 9.2 18.139 15.609 26.207a21.39 21.39 0 0 0 7.471 5.891 21.328 21.328 0 0 0 9.281 2.064l7.106 3.263a36.002 36.002 0 0 0-2.423-15.205 35.922 35.922 0 0 0-8.55-12.792 34.621 34.621 0 0 0-7.023-5.12c-1.082 4.381-2.701 8.779-2.701 8.779Z"/>
                                        <path fill="#FFB6B6" d="m89.149 88.193-.893 2.082 1.46 7.395 14.89-2.075-.119-9.018-.299-1.975-15.04 3.59Zm-7.693 95.85h3.285l1.564-12.702h-4.849v12.702Z"/>
                                        <path fill="#2F2E41" d="m90.622 187.183-4.988-3.454v-2.256l-.58.036-3.598.215-1.07.063-.663 7.962h2.356l.418-2.229 1.078 2.229h6.25a1.4 1.4 0 0 0 1.34-.989 1.412 1.412 0 0 0-.543-1.577Z"/>
                                        <path fill="#FFB6B6" d="M99.909 184.044h3.285l1.933-18.701-6.173-.37.955 19.071Z"/>
                                        <path fill="#2F2E41" d="m109.075 187.183-4.988-3.454v-2.256l-.632.036-3.661.222-.956.056-.662 7.962h2.356l.418-2.229 1.078 2.229h6.25a1.401 1.401 0 0 0 1.34-.989 1.41 1.41 0 0 0-.543-1.577Z"/>
                                        <path fill="#BBB" d="M80.061 173.09h.013l.632-20.653a5.414 5.414 0 0 1 .07-5.104l.091-.164-.234-1.09a6.96 6.96 0 0 1 .395-4.178l.59-19.265c-2.106-13.68 7.93-22.602 7.93-22.602l-.923-7.17 16.231.37-.276 6.8 3.227 11.115c1.698 16.589 1.799 32.84.062 48.702a.29.29 0 0 0 .02.134 4.07 4.07 0 0 1-.327 3.269 1.215 1.215 0 0 0-.146.405c-.358 2.781-.405-.737-.879 2.02l.092.046c.332.115.475.577.318 1.031-.156.455-.552.73-.885.615l-8.676.093c-.333-.115-.475-.577-.319-1.031.157-.455.553-.73.885-.615l.54.274-.903-2.962-.317-.296a1.382 1.382 0 0 1 .175-2.156l.263-8.599.128-3.64-.264-4.13-1.217-18.576-4.606 22.843-4.705 24.744a.865.865 0 0 1 .27.801.894.894 0 0 1-.89.71h-6.387a.875.875 0 0 1-.838-1.105.882.882 0 0 1 .86-.636Z"/>
                                        <path fill="#FBF9C9" d="M85.741 53.05a8.07 8.07 0 0 0 3.54 6.678 8.023 8.023 0 0 0 10.648-1.493 8.075 8.075 0 0 0-.366-10.773l-.038-.039c-.065-.068-.129-.135-.197-.2h-.002v-.002a1.687 1.687 0 0 1-.255-.23c-1.084-1.52-2.314-2.794-5.294-1.997-4.29 1.146-8.036 3.605-8.036 8.055Z"/>
                                        <path fill="#FFB6B6" d="M129.885 49.601a2.952 2.952 0 0 1-3.335 3.05L105.826 69.7l-3.166-5.578 21.367-15.006a2.968 2.968 0 0 1 1.123-1.782 2.953 2.953 0 0 1 3.918.324c.5.525.791 1.216.817 1.942Z"/>
                                        <path fill="#E6E6E6" d="M97.075 74.728s.675 1.405 3.3-.59c2.103-1.597 12.138-8.487 14.367-11.4a2.308 2.308 0 0 0 2.757-.662l-5.483-5.292c-1.72.116-1.738 1.27-1.591 1.933l-4.406 1.755-11.477 6.61 2.026 6.64.507 1.006Z"/>
                                        <path fill="#FFB7B7" d="M95.758 61.667c3.721 0 6.738-3.024 6.738-6.754 0-3.73-3.017-6.755-6.738-6.755-3.722 0-6.739 3.024-6.739 6.755 0 3.73 3.017 6.755 6.739 6.755Z"/>
                                        <path fill="#E6E6E6" d="m104.949 88.42.069-3.769c.274-.376 2.567-3.662 2.053-6.945-.241-1.546-1.077-2.844-2.481-3.854l-.906-2.114-1.341-3.124-3.924-2.342-.543-1.11-.448-.921-5.413-.542-1.335 1.8-.026.004a8.748 8.748 0 0 0-3.377 1.585 8.952 8.952 0 0 0-3.525 7.127l.033 4.38.007.627.003.608 3.377 9.112.128.353 1.325 3.57-.228.377a2.004 2.004 0 0 0 .438 2.566l.159 2.232.286-.079 1.631-.456 12.335-3.45c.464-.13.875-.408 1.17-.79a.644.644 0 0 0 .029-.043 1.26 1.26 0 0 0 .129-.195c.28-.71.309-.902.313-1.1a2.718 2.718 0 0 0 .131-3.415l-.069-.092Z"/>
                                        <path fill="#FBF9C9" d="M88.706 56.684c.06-.01.122-.023.183-.032 1.05-.187 2.102-.37 3.15-.56l.096-2.382 1.27 2.133c2.896.963 5.606.419 8.155-.783.841-.4 1.656-.85 2.443-1.35.259-4.027-1.365-6.605-4.388-6.335-.11.01-.19-.103-.286-.151h-.001l-.001-.001v-.001l-.001-.001a9.804 9.804 0 0 0-.498-.22 7.406 7.406 0 0 0-7.792 1.515c-2.057 1.969-2.983 5.22-2.33 8.168Z"/>
                                        <path fill="#FBF9C9" d="M93.096 45.188a1.253 1.253 0 0 1-1.031.892c-.906 1.123-1.944 2.312-3.364 2.536a1.61 1.61 0 0 1-1.183-.205.658.658 0 0 1-.118-.095c-.337-.32-.363-.965.036-1.206-1.786.71-2.83 2.642-3.094 4.548-.263 1.905.102 3.831.343 5.74.24 1.909.346 3.914-.402 5.687-.883 2.09-2.913 3.593-5.127 4.072-.197.043-.395.08-.596.103a6.108 6.108 0 0 0-.982-2.53 5.469 5.469 0 0 1-.234 2.616 10.377 10.377 0 0 1-4.767-1.236c-2.006-1.06-3.677-2.678-5.044-4.491 2.356 1.674 5.61 2.302 8.174.977 2.293-1.189 3.647-3.685 4.24-6.202.593-2.52-1.9-5.607-1.63-8.18.274-2.573 3.37-4.757 5.063-6.709 1.08-1.245 2.675-2.166 4.32-2.01a3.874 3.874 0 0 1 2.797 1.621 3.896 3.896 0 0 1 .602 3.184c.468-.664 1.677-.519 1.977.237a.99.99 0 0 1 .02.651Z"/>
                                        <path fill="#FD6584" d="M88.889 56.652s1.996-7.47 10.553-9.193a.842.842 0 0 0 .084-.036c.026-.016.058-.032.09-.048a6.127 6.127 0 0 0-.286-.151h-.001l-.002-.001v-.003c-.084-.077-.168-.154-.254-.228l-.245.01s-8.586-1.364-9.94 9.65Z"/>
                                        <path fill="#8F8BCC" d="M110.363 92.246a1.132 1.132 0 0 0-.942-.715 2.7 2.7 0 0 0-1.222.177 9.4 9.4 0 0 0-3.976 2.591l.019.756a3.625 3.625 0 0 0 .059 4.54c.297.366.701.628 1.156.75a1.303 1.303 0 0 0 1.274-.385c.456-.556.235-1.394-.099-2.03a7.39 7.39 0 0 0-2.487-2.733 11.943 11.943 0 0 0 5.027-1.252c.347-.152.66-.372.92-.647a1.142 1.142 0 0 0 .271-1.051Z"/>
                                        <path fill="#F2F2F2" d="m141.683 136.732-3.261.13a10.58 10.58 0 0 0-1.267 4.917c1.94-2.355 5.656-2.042 8.257-3.632a6.342 6.342 0 0 0 2.95-4.641l1.218-1.973a10.596 10.596 0 0 0-8.611 2.2 10.319 10.319 0 0 0-1.76 1.88 28.236 28.236 0 0 1 2.474 1.119Zm-16.268 35.53-3.261.129a10.568 10.568 0 0 0-1.268 4.917c1.94-2.355 5.657-2.041 8.258-3.632a6.361 6.361 0 0 0 2.95-4.641l1.218-1.972a10.588 10.588 0 0 0-8.612 2.2 10.215 10.215 0 0 0-1.759 1.88 28.236 28.236 0 0 1 2.474 1.119Zm-76.852-50.306-2.764-1.739a10.543 10.543 0 0 0-3.816 3.34c2.928-.845 5.818 1.518 8.86 1.678a6.315 6.315 0 0 0 5.05-2.16l2.116-.939a10.61 10.61 0 0 0-8.345-3.059c-.857.079-1.701.265-2.512.555a28.362 28.362 0 0 1 1.411 2.324Z"/>
                                        <path fill="#fff" d="M130.004 46.091c-.065-.438.409-.75.586-1.155.114-.362.114-.75 0-1.111-.103-.492-.22-1.013-.575-1.367a2.336 2.336 0 0 0-1.281-.538 11.206 11.206 0 0 0-3.47-.062l.874.053a4.594 4.594 0 0 0-1.631.19 1.655 1.655 0 0 0-1.094 1.142c-.109.542.261 1.175.809 1.222a1.055 1.055 0 0 0-1.246.821 1.06 1.06 0 0 0 .812 1.254c-.126.342-.501.506-.823.676-.321.171-.66.463-.605.824.072.477.707.575 1.187.553l5.231-.228c.45.016.898-.077 1.304-.272.388-.227.659-.723.479-1.136-.138-.318-.506-.522-.557-.866ZM69.347 92.786c-.718 3.166-1.06 6.754.857 9.587a6.562 6.562 0 0 0 3.399 2.581 9.614 9.614 0 0 0 4.33.267c3.27-.454 6.176-2.162 9.392-2.825 1.419-.292 2.925-.389 4.291.174 1.024.422 2.102 1.27 2.107 2.48.005 1.242-1.072 2.067-2.142 2.449-1.428.509-2.913.312-4.394.248-1.596-.069-3.223.075-4.549 1.055a7.928 7.928 0 0 0-2.527 3.435c-.667 1.621-.937 3.364-1.363 5.056-.38 1.52-.92 2.996-1.607 4.404a22.812 22.812 0 0 1-5.562 7.227c-1.144.987-2.535 1.993-4.098 2.084-1.205.07-2.67-.438-3.135-1.662a2.392 2.392 0 0 1 1.114-2.834c1.034-.522 2.159.223 2.492 1.247a1.597 1.597 0 0 1-.165 1.438c-.314.468.44.906.752.441.722-1.08.345-2.532-.54-3.398a2.536 2.536 0 0 0-3.34-.249 3.308 3.308 0 0 0-1.207 3.419c.372 1.344 1.69 2.199 3.005 2.411 1.626.262 3.19-.378 4.502-1.303a20.216 20.216 0 0 0 3.564-3.301 23.73 23.73 0 0 0 4.72-8.46c.959-3.081 1.029-7.152 3.88-9.233 1.466-1.071 3.263-.952 4.983-.854 1.518.087 2.999.071 4.391-.618 1.15-.569 2.078-1.619 2.097-2.956.02-1.351-.937-2.437-2.064-3.052-2.977-1.626-6.552-.315-9.477.771-2.94 1.092-6.3 2.374-9.425 1.225a5.853 5.853 0 0 1-3.23-3.094c-.757-1.609-.864-3.435-.69-5.181.104-.925.264-1.842.48-2.747.124-.547-.716-.78-.84-.232Z"/>
                                        <path fill="#FFB6B6" d="M83.782 110.402a2.953 2.953 0 0 1 .907-4.432l-4.43-13.148 3.617-13.932 5.834 2.643-3.6 10.51 2.693 15.296a2.972 2.972 0 0 1-1.153 3.767 2.95 2.95 0 0 1-3.868-.704Z"/>
                                        <path fill="#E6E6E6" d="M89.12 67.41s-1.37-.737-2.322 2.425c-.763 2.53-5.954 16.274-6.048 19.943a2.311 2.311 0 0 0-1.84 2.162l7.54 1.046c1.322-1.109.66-2.053.151-2.502l2.568-4.36 5.368-11.771-4.417-6.427-1-.515Z"/>
                                        <path fill="#FF6366" d="M74.69 83.906a29.402 29.402 0 0 1-5.172 9.896 9.462 9.462 0 0 0-1.293-1.811c-1.757-1.937-4.113-3.204-6.4-4.474-2.286-1.27-4.607-2.632-6.222-4.691a8.278 8.278 0 0 1-1.522-3.142c-.367-1.553-.227-3.172.676-4.468 1.314-1.884 3.931-2.548 6.156-2.004 2.229.545 4.094 2.097 5.54 3.88-.473-2.19 1.83-4.305 4.059-4.112 2.228.195 4.015 2.146 4.607 4.307.596 2.158.21 4.47-.43 6.62Z"/>
                                        <path fill="#3F3D56" d="M117.762 3.784a8.82 8.82 0 0 0-5.871 3.746 8.575 8.575 0 0 0-1.137 7.041 8.734 8.734 0 0 0 2.032 3.685c.383.413.998-.206.616-.618a8.013 8.013 0 0 1-2.04-6.247 7.699 7.699 0 0 1 3.262-5.414 7.923 7.923 0 0 1 3.37-1.351c.553-.083.318-.925-.232-.842Z"/>
                                        <path fill="#000" d="M147.657 17.565c-.398.482-.834.93-1.305 1.34-7.312 6.354-13.253 13.32-17.66 20.703l-1.589 2.662-.108.183a4.378 4.378 0 0 1-.967-1.01c-1.017-1.483-1.023-3.43-.773-5.213a21.75 21.75 0 0 1 7.609-13.665 21.646 21.646 0 0 1 14.793-5Zm-76.82 74.448a31.243 31.243 0 0 1-1.319 1.788 9.458 9.458 0 0 0-1.293-1.811c-1.757-1.936-4.113-3.203-6.4-4.473-2.286-1.27-4.607-2.632-6.222-4.691a8.277 8.277 0 0 1-1.522-3.142 21.994 21.994 0 0 1 13.334 7.09 21.468 21.468 0 0 1 3.422 5.24Z" opacity=".2"/>
                                        <path fill="#fff" d="M126.542 42.39a1.164 1.164 0 0 0 0-2.33 1.164 1.164 0 0 0 0 2.33Z"/>
                                    </svg>
                                </div>
                                {/* ILLUSTRATION END */}
                                <div className="loadMoreButton_interestsPCVB1" onClick={loadMore}>
                                    <span>{t('interests.load')}</span>
                                </div>
                                <div id="separator"></div>
                                <div className="actionsButtons_interestsPCVB1">
                                    <div className="saveInterests" onClick={(e) => finishInterests(e)}>
                                        <span>{t('interests.buttons.save')}</span>
                                    </div>
                                    <div className="closeInterests" onClick={(e) => cancelInterests(e)}>
                                        <span>{t('interests.buttons.cancel')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                </>
            );
        }else {
            return <></>;
        }
    }

    useEffect(() => {
        // Fetch all interests
        getInterests();
        // Fetch if user has interests
        userInterestsVerify();

        // Set item already selected
        if(localStorage.getItem('interests_list')){
            var storage = localStorage.getItem('interests_list').split(',');
            storage.map((ref) => {
                // UI / UX
                interestRef.current.push(ref);
                setIC({
                    ...interestCard,
                    icon: "fi-rr-check",
                    color: '#000',
                    background: '#fff'
                });
                // Append to list
                var index = interests.indexOf(ref);
                if (index <= -1) {
                    interests.push(ref);
                }
            });
        }
    }, [])

    return <Modal />;
}