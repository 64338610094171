import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Cookies from "js-cookie";
import axios from "axios";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
import { VerticalMenu } from "../../../Components/Pages/Settings";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../../Components/Functions";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";

const SettingsAppearance = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    const [selected, setSelected] = useState({
        light: '',
        dark: '',
        sync: '',
        exclus: {
            sky: '',
            abyss: '',
            nature: '',
            bonbon: '',
            hacker: '',
            sunset: '',
            chocolate: '',
            moonlight: '',
        },
        check: <div className="themeSelected"><i className="fi fi-rr-check"></i></div>
    })
    const [infos, setInfos] = useState([]);

    // OPTIMIZATION
    const themesCookies = [
        "light_theme",
        "dark_theme",
        "sync_theme",
        "sky_theme",
        "abyss_theme",
        "nature_theme",
        "bonbon_theme",
        "hacker_theme",
        "sunset_theme",
        "chocolat_theme",
        "moonlight_theme"
    ];
    const currentTheme = Cookies.get('_theme');

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])

    // GLOBAL FUNCTIONS
    const save = (user, theme) => {
        var [light, dark, sync, sky, abyss, nature, bonbon, hacker, sunset, chocolate, moonlight] = "";
        if(theme === "light"){ light = "selected" }
        else if(theme === "dark"){ dark = "selected" }
        else if(theme === "sync"){ sync = "selected" }
        // Set selected
        setSelected({
            ...selected,
            light: light,
            dark: dark,
            sync: sync,
            exclus: {
                sky: sky,
                abyss: abyss,
                nature: nature,
                bonbon: bonbon,
                hacker: hacker,
                sunset: sunset,
                chocolate: chocolate,
                moonlight: moonlight,
            },
        });
        // Set theme
        localStorage.setItem('theme', theme)
        Cookies.set('_theme', theme+"_theme");
        // Edit in DB
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/${user}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                data: { setting: "setting_theme", term: theme+"_theme" }
            }
        )
    }

    useEffect(() => {
        // Set theme + set selected
        if(localStorage.getItem("theme")){            
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }else{
                setTheme('darkTheme');
            }
        }

        // AUTO THEME CHANGE
        let selectedThemes = {
            light: "",
            dark: "",
            sync: "",
            exclus: {
                sky: "",
                abyss: "",
                nature: "",
                bonbon: "",
                hacker: "",
                sunset: "",
                chocolate: "",
                moonlight: ""
            },
            check: <div className="themeSelected"><i className="fi fi-rr-check"></i></div>
        };

        if (themesCookies.includes(currentTheme)) {
            if (currentTheme === "light_theme") selectedThemes.light = "selected";
            else if (currentTheme === "dark_theme") selectedThemes.dark = "selected";
            else if (currentTheme === "sync_theme") selectedThemes.sync = "selected";
            else {
                const exclusThemesMap = {
                    "sky_theme": "sky",
                    "abyss_theme": "abyss",
                    "nature_theme": "nature",
                    "bonbon_theme": "bonbon",
                    "hacker_theme": "hacker",
                    "sunset_theme": "sunset",
                    "chocolate_theme": "chocolate",
                    "moonlight_theme": "moonlight"
                };
                selectedThemes.exclus[exclusThemesMap[currentTheme]] = "selected";
            }
        }
          
        setSelected(selectedThemes);
    }, [localStorage.getItem('theme')])

    useEffect(() => {
        retreiveInfos().then(res => {
            setInfos(res);
        })
    }, [])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/appearance" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.appearance')}/>
                <meta property="og:url" content={"https://snot.fr/settings/appearance"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.appearance')}/>
                <title>{t('title.settings.appearance')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <h1 id="title">{t('settings.appearance.titleStrong')}</h1>
                        <div className={"saveBtnContainer_setMain-pcVB1 noButton "+theme}>
                            <span className="text_saveBtnC-sMpcVB1">{t('settings.appearance.autosave')}</span>
                        </div>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="AccountSettings" className="privacy appearance" style={{marginTop:-20}}>
                            <div className="theme_appSetMVB1" style={{borderBottom: 0}}>
                                <h1 className="title_appSetPcVB1" style={{marginBottom: 0}}>{t('settings.appearance.theme.title')}</h1>
                                <h2 className="info_appSetPcVB1">{t('settings.appearance.theme.description.simpleText')} <span style={{fontWeight: "600"}}>{t('settings.appearance.theme.description.mediumText')}</span>.</h2>
                                <div className="themeContainer_appSetMVB1">
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeLight "+selected.light} onClick={() => save(infos.uid, 'light')}>
                                            { selected.light === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description">{t('settings.appearance.theme.light')}</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeDark "+selected.dark} onClick={() => save(infos.uid, 'dark')}>
                                            { selected.dark === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="dark">{t('settings.appearance.theme.dark')}</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeSync "+selected.sync} data-tooltip={t('settings.appearance.theme.syncTip')} data-tooltip-location="top" style={{ zIndex: 0 }} onClick={() => save(infos.uid, 'sync')}>
                                            <div id="syncIcon"><i className="fi fi-rr-refresh"></i></div>
                                            { selected.sync === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="sync">Sync</span>
                                    </div>
                                </div>
                                {/*
                                <div className="exclusThemes_appSetMVB1">
                                    <div id="TitlePart">
                                        <h1 style={{ margin: 0 }}>{t('settings.appearance.theme.exclus.title')}</h1>
                                        <div className="newInfoTag_exclusTPcVB1">
                                            <span>{t('settings.appearance.theme.exclus.new')}</span>
                                        </div>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeSky "+selected.exclus.sky} onClick={() => save(infos.uid, 'sky')}>
                                            { selected.exclus.sky === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="sky">Sky</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeAbyss "+selected.exclus.abyss} onClick={() => save(infos.uid, 'abyss')}>
                                            { selected.exclus.abyss === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="abyss">Abyss</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeNature "+selected.exclus.nature} onClick={() => save(infos.uid, 'nature')}>
                                            { selected.exclus.nature === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="nature">Nature</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeBonbon "+selected.exclus.bonbon} onClick={() => save(infos.uid, 'bonbon')}>
                                            { selected.exclus.bonbon === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="bonbon">Bonbon</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeHacker "+selected.exclus.hacker} onClick={() => save(infos.uid, 'hacker')}>
                                            { selected.exclus.hacker === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="hacker">Hacker</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeSunset "+selected.exclus.sunset} onClick={() => save(infos.uid, 'sunset')}>
                                            { selected.exclus.sunset === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="sunset">Sunset</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeChocolate "+selected.exclus.chocolate} onClick={() => save(infos.uid, 'chocolate')}>
                                            { selected.exclus.hacker === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="chocolate">Chocolate</span>
                                    </div>
                                    <div id="ThemeContainer">
                                        <div id="theme" className={"themeMoonlight "+selected.exclus.moonlight} onClick={() => save(infos.uid, 'moonlight')}>
                                            { selected.exclus.moonlight === "selected" ? selected.check: '' }
                                        </div>
                                        <span id="description" className="moonlight">Moonlight</span>
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsAppearance;