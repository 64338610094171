import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import LoginModal from '../Modals/Common/LogIn';
import News from "../Modals/Settings/News";
// import Hooks
import { handleImageError } from "../../Hooks/handleAvatarError";
// import Functions
import { retreiveInfos } from "../Functions/Auth";
import { API_VERSION } from "../Functions";
import { openMenu } from "../Functions/Menu";
// import Images
/*import confettiImg from '../../img/feature/confetti.svg';
import sparklesImg from '../../img/feature/sparkles.svg';*/

const Navbar = () => {
    const { t } = useTranslation();
    const location = useLocation();

    // Set HeaderView Function -- IMPORTANT
    const HeaderView = () => location.pathname;

    // Set data
    const [logged, setLogged] = useState(0);
    const [loggedClass, setLoggedClass] = useState('not-logged');
    const [loading, setLoading] = useState(true);
    const [notSeen, setNotSeen] = useState(0);
    const [profileContent, setProfileContent] = useState(
        <div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active" }
            onMouseEnter={() => {document.querySelector('#profile i').className = 'fi fi-sr-circle-user'}}
            onMouseLeave={() => {
                if(document.getElementById("profile").className !== "active") {
                    document.querySelector('#profile i').className = 'fi fi-rr-circle-user';
                }
            }}
        >
            <i className={(HeaderView() === "/profile" || HeaderView().includes('/@')) ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i>
            <p>{t('general.navbar.profile')}</p>
        </div>
    )
    // Set features data
    //const [backgroundSettings, setBgSettings] = useState("#26262C");
    const [update, setUpdate] = useState(false);
    // Set Theme Data
    const [theme, setTheme] = useState('darkTheme');
    // Set links
    const [links, setLinks] = useState({
        friendsLink: "/login",
        chatLink: "/login",
        profileLink: "/profile",
        addLink: "/login",
    });

    // -- Set logged
    useEffect(() => {
        const loggedStatus = Cookies.get('logged') === '1';
        setLogged(loggedStatus);
        setLoggedClass('logged');
        setLoading(false);

        if (loggedStatus) {
            retrieveUserData();
        } else {
            var pathname = "?rel="+HeaderView();

            setLinks({
                friendsLink: HeaderView() !== "/" ? "/login"+pathname: "/login",
                chatLink: HeaderView() !== "/" ? "/login"+pathname: "/login",
                profileLink: "/profile",
                addLink: HeaderView() !== "/" ? "/login"+pathname: "/login",
            });

            setProfileContent(
                <div id="profile" className={(HeaderView() === "/profile" || HeaderView().includes('/@')) && "active" }
                    onMouseEnter={() => {document.querySelector('#profile i').className = 'fi fi-sr-circle-user'}}
                    onMouseLeave={() => {
                        if(document.getElementById("profile").className !== "active") {
                            document.querySelector('#profile i').className = 'fi fi-rr-circle-user';
                        }
                    }}
                >
                    <i className={(HeaderView() === "/profile" || HeaderView().includes('/@')) ? "fi fi-sr-circle-user": "fi fi-rr-circle-user"}></i>
                    <p>{t('general.navbar.profile')}</p>
                </div>
            )
        }

        // Set theme
        const savedTheme = localStorage.getItem("theme");
        setTheme(savedTheme === "light" ? 'lightTheme' : 'darkTheme');
    }, [location, localStorage.getItem("theme")])

    const retrieveUserData = async () => {
        const savedTheme = localStorage.getItem("theme");
        const infos = await retreiveInfos();
        setNotSeen(await getNotSeenChat(infos.uid));
        setLinks({
            friendsLink: "/friends",
            chatLink: "/friends/chat",
            profileLink: "/@" + infos.usertag.toLowerCase().replace('.', '_'),
            addLink: HeaderView() !== "/" ? "/add?rel=" + HeaderView() : "/add",
        });

        setProfileContent(
            <div id="profile" className={isActive("/profile") ? "active" : ""}>
                <img
                    className="avatar_pIconMvEB1"
                    alt="avatar"
                    src={infos.avatar}
                    {...(isActive('/profile') || isActive('/@') ? {style: {outline: '2px solid', borderColor: savedTheme === "light" ? '#000': '#fff', marginBottom: 5}}: {style: {marginBottom: 5}})}
                    onError={handleImageError}
                />
            </div>
        );

        // Menu
        const changeMenuX = (pos) => {
            if(pos.matches) {
                $('#settingsMenu .menu-wrapper').css('bottom', 80);
            }else {
                $('#settingsMenu .menu-wrapper').removeAttr('style');
            }
        }
        var size = window.matchMedia("(max-height: 750px)");
        // Attach listener function on state changes
        size.addEventListener("change", () => changeMenuX(size));

        // Verify update
        axios.get(
            `https://api.snot.fr/v${API_VERSION}/new/update`,
            {
                headers:{
                    "Content-Type": "application/json",
                },
                params: { token: Cookies.get('loginToken') },
            }
        ).then((res) => {
            if(!res.data.error){
                setUpdate(true);
            }
        })
    };

    // CHECK IF MESSAGES ARE NOT SEEN
    const getNotSeenChat = async (user) => {
        const response = await axios.get(
            `https://api.snot.fr/v${API_VERSION}/chat/all/not-seen`,
            {
                params: {
                    token: Cookies.get('loginToken'),
                    uid: user,
                    app: 'web'
                }
            }
        );
        return response.data.count;
    }

    // FOR NEW FEATURES (in navbar)
    /*const nextStepFeature = () => {
        $('.newFeature__overlay').addClass('slide-out-top');
        $('.newFeature_containerPCvB1').removeClass('slide-in-bck-bl').addClass('slide-out-bck-bl');
        $('.newFeature__overlay #settings').removeClass('slide-in-bck-bl').addClass('slide-out-bck-bl');
        $('.newFeature__overlay #donate').removeClass('slide-in-bck-bl').addClass('slide-out-bck-bl');
        // Change text
        $('#settings-navBar').addClass('show-fade');
        $('#donate-navBar').addClass('show-fade');
        // Register to database
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/update/view/update`,
            {
                headers:{
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*'
                },
                data: { user: Cookies.get('loginToken') }
            }
        ).then((res) => {
            if(!res.data.error && res.data.success){
                setUpdate(false);
            }
        })
    }

    const [chrono, setChrono]= useState(null);
    const highlightDonate = () => {
        $('.newFeature__overlay #donateHighlight').css({borderRadius: 10, border: '3px solid #F2ECCB'});
        if (chrono != null){
            clearTimeout(chrono);
            setChrono(setTimeout(() => $('.newFeature__overlay #donateHighlight').css({border: 0}), 1500));
        }else{
            setChrono(setTimeout(() => $('.newFeature__overlay #donateHighlight').css({border: 0}), 1500));
        }
    }*/

    const isActive = (path) => {
        if(path === "/friends" && HeaderView() === "/friends/chat"){
            return false;
        }else{
            return HeaderView() === path || HeaderView().includes(path);
        }
    };

    return (
        <>
        {
            logged ?
                update && (
                    <News autoShow />
                    /*<aside className="modal newFeature__overlay">
                        <div className={"newFeature_containerPCvB1 slide-in-bck-bl "+theme}>
                            <img src={sparklesImg} alt="Sparkles" title="SNOT - New feature!" className="sparklesIllustration" />
                            <img src={confettiImg} alt="Confetti" title="SNOT - New feature!" className="confettiIllustration" />
                            <h1 id="newFeature-title">IL Y A DU NOUVEAU !</h1>
                            <p id="newFeature-subtitle">
                                Vous pouvez désormais accéder à vos <Link to="/settings" title="Aller aux paramètres">paramètres</Link> depuis ce bouton (pratique hein) !
                                <br/><br/>
                                Nous avons également ajouter <span onClick={highlightDonate} style={{ cursor: "pointer" }}>ce bouton</span> qui permet de nous faire un don !
                            </p>
                            <div className="followGuide">
                                <p className="isNext" onClick={nextStepFeature}>D'accord <i className="fi fi-rr-arrow-right" style={{ marginLeft: 5 }}></i></p>
                            </div>
                        </div>
                        <div
                            id="settings"
                            className="slide-in-bck-bl"
                            style={{ width: 50,
                                height: 50,
                                position: "absolute",
                                bottom: 100,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 5,
                                background: backgroundSettings,
                                left: 25
                            }}
                        >
                            <div style={{ fontSize: 40 }}><i className="fi fi-rs-settings" style={{display: "flex"}}></i></div>
                        </div>
                        <div
                            id="donate"
                            className="slide-in-bck-bl"
                            style={{
                                display: "flex",
                                position: "absolute",
                                bottom: 25,
                                left: 25
                            }}
                        >
                            <form>
                                <img alt="Donate with PayPal !" id="donateHighlight" src="https://pics.paypal.com/00/s/NDQyZGQyNDYtM2JmNi00NGQ3LWE4MTItNWE3NzAyZmNmODMy/file.PNG" border={0} width={50} height={50} style={{cursor: "default"}} />
                            </form>
                        </div>
                    </aside>*/
                )
            : ''
        }
        <nav className={"navBar_pcverSB1 "+theme+" "+loggedClass}>
            <div className="actions_bNBmVeB1">
                <NavLink to="/" index="home" icon="fi fi-rr-home" label={t('general.navbar.home')} isActive={HeaderView()} />
                <NavLink to={links.friendsLink} index="friends" icon="fi fi-rr-user" label={t('general.navbar.friends')} isActive={isActive("/friends")} />
                <NavLink to={links.chatLink} index="messages" icon="fi fi-rr-comment-alt" label={t('general.navbar.chat')} isActive={isActive("/chat")} logged={logged} notSeen={notSeen} />
                <Link to={links.profileLink}>
                    {profileContent}
                </Link>
                <NavLink to={links.addLink} index="add" icon="fi fi-rr-add" label={t('general.navbar.post')} isActive={isActive("/add")} />
                {logged && <SettingsMenu isActive={isActive("/settings")} />}
            </div>
            <div id="donate-navBar">
                <form action="https://www.paypal.com/donate" method="post" target="_top" {...((logged || (!logged && HeaderView().includes("/integrate"))) ? { style: { bottom: 30, position: "fixed" }}: { style: { bottom: 110, position: "fixed" } })} data-tooltip={i18n.resolvedLanguage === "fr" ? "Donation": "Donate"} data-tooltip-location="top" data-tooltip-theme={theme}>
                    <input type="hidden" name="hosted_button_id" value="FYMQEUYP3QNMU" />
                    <input type="image" src="https://pics.paypal.com/00/s/NDQyZGQyNDYtM2JmNi00NGQ3LWE4MTItNWE3NzAyZmNmODMy/file.PNG" style={{ border: 0 }} name="submit" title="Donate to SNOT with PayPal" alt="Donate with PayPal button" />
                </form>
            </div>
        </nav>
        {
            !logged ?
            <LoginModal />
            :
            <aside id="settingsMenu" className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className="menu-wrapper js-menu-stop" style={{width: "auto",maxWidth: "none", borderRadius: 10 }}>
                    <div className="settings_menuMVB1">
                        <Link to="/settings" id="goToSettings">
                            <span>{t('settings.menu.choice.1')}</span>
                        </Link>
                        <Link to={links.profileLink+'?open=edit'} id="editProfileNav">
                            <span>{t('settings.menu.choice.2')}</span>
                        </Link>
                    </div>
                </div>
            </aside>
        }
        </>
    );
}

const NavLink = ({ to, index, icon, label, isActive, logged, notSeen }) => (
    <Link to={to}>
         <div
            key={index}
            id={index}
            className={((isActive && index !== "home") || (isActive === "/" && index === "home")) ? "active": ''}
            onMouseEnter={() => {document.querySelector('#'+index+' i').className = icon.replace('rr-', 'sr-')}}
            onMouseLeave={() => {
                if(document.getElementById(index).className !== "active") {
                    document.querySelector('#'+index+' i').className = icon;
                }
            }}
        >
            <i className={((isActive && index !== "home") || (isActive === "/" && index === "home")) ? icon.replace('rr-', 'sr-') : icon}></i>
            <p>{label}</p>
            {
                index === "messages" ?
                    logged ? notSeen === 0 ?
                    <div id="notifIndice" className="newChat-notifIndice" style={{display: "none", margin: "-5px 8px 0 0", backgroundColor: "#ff2323" }}></div>:
                    <div id="notifIndice" className="newChat-notifIndice" style={{display: "flex", margin: "-5px 8px 0 0", backgroundColor: "#ff2323" }}></div>
                    : ''
                : ''
            }
        </div>
    </Link>
);

const SettingsMenu = ({ isActive }) => (
    <div id="settings-navBar" data-container="settingsMenu" onClick={(e) => openMenu(e)}>
        <div
            id="settings"
            className={isActive ? "active": ""}
            onMouseEnter={() => {document.querySelector('#settings-navBar #settings i').className = "fi fi-ss-settings"}}
            onMouseLeave={() => {
                if(document.querySelector('#settings-navBar #settings').className !== "active") {
                    document.querySelector('#settings-navBar #settings i').className = "fi fi-rs-settings"
                }
            }}
            data-container="settingsMenu"
        >
            <i className={isActive ? "fi fi-ss-settings": "fi fi-rs-settings"} data-container="settingsMenu"></i>
        </div>
    </div>
);

export default Navbar;