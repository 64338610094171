// import Posts loading
import FriendCardLoading from "../LoadingFriendCard";
import { LoadingAppBar, LoadingNavBar } from "../Common/Loading";

export const LoadingFriends = () => {
    document.body.className = "darkTheme";

    return (
        <>
            <LoadingAppBar />
            <LoadingNavBar />
            <section className="topMenu_pcverSB1 darkTheme">
                <div className="others_fBmVeB1 darkTheme">
                    <div id="latest" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="subs" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="trending" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
                <div className="separator_fBmVeB1 darkTheme"></div>
                <div className="filter_fBmVeB1 darkTheme">
                    <h1 className="skeleton darkTheme" style={{ height: 25, borderRadius: 5, width: "calc(100% - 50px)" }}></h1>
                    <div className="subs_fMaMVB1 current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
            </section>
            <main id="friendsPart" style={{ overflow: "hidden", position: "absolute" }}>
                <div id="friendsMain">
                    <div className="topPart_friendsMMVB1 darkTheme" style={{ height: 60 }}>
                        <h1 className="skeleton darkTheme" style={{ height: 40, width: 200, borderRadius: 5, margin: "20px 0" }}></h1>
                    </div>
                    <div className="mainPart_friendsMMVB1 darkTheme">
                        <FriendCardLoading widthUser={185} widthUserTag={225} />
                        <FriendCardLoading widthUser={120} widthUserTag={170} />
                        <FriendCardLoading widthUser={250} widthUserTag={300} />
                        <FriendCardLoading widthUser={150} widthUserTag={200} />
                    </div>
                </div>
            </main>
        </>
      )
}