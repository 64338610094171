import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import $ from 'jquery';
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
import { VerticalMenu } from "../../../Components/Pages/Settings";
// import Functions
import { API_VERSION } from "../../../Components/Functions";
import * as Settings from "../../../Components/Functions/Settings";
// import i18next
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";

const SettingsPrivacy = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [visibility, setVisibility] = useState({
        account: "",
        like: "",
        followers: "",
        subs: ""
    })
    const [infos, setInfos] = useState([]);
    const [settingsInfos, setSettingsInfos] = useState([]);
    // Set theme
    const [theme, setTheme] = useState('darkTheme');

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])
 
    /* -----------------
     * GENERAL FUNCTIONS
     * -----------------
     */
    const save = () => {
        var visibility = $('.selectBox_visSetMVB1.vis').find(":selected").attr('name');
        var likeVisibility = $('.selectBox_visSetMVB1.rm').find(":selected").attr('name');
        var followersAccess = $('.selectBox_visSetMVB1.fllw').find(":selected").attr('name');
        var subsAccess = $('.selectBox_visSetMVB1.subs').find(":selected").attr('name');

        // UI/UX
        $('#infoSave').css({ display: "block" });
        $('.updateBtn_tPSAMVB1').css({ marginLeft: "0" });
        // ...
        // -- Change account visibility
        if(visibility !== infos.access){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit/account-access?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { access: visibility }
                }
            )
        }
        // -- Change like visibility setting
        if(likeVisibility !== settingsInfos.privacy_setting1){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { setting: 'privacy_setting1', term: likeVisibility }
                }
            )
        }
        // -- Change followers visibility setting
        if(followersAccess !== settingsInfos.privacy_setting2){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { setting: 'privacy_setting2', term: followersAccess }
                }
            )
        }
        // -- Change subs visibility setting
        if(subsAccess !== settingsInfos.privacy_setting3){
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { setting: 'privacy_setting3', term: subsAccess }
                }
            )
        }
    }

    const change = (vis) => {
        var iconVis = document.querySelector("#icon.vis i");
        var iconRm = document.querySelector("#icon.rm i");
        var iconFllw = document.querySelector("#icon.fllw i");
        var iconSubs = document.querySelector("#icon.subs i");

        if (vis === "public") {
            iconVis.className = "fi fi-sr-earth-americas";
        }else if (vis === "private") {
            iconVis.className = "fi fi-sr-lock";
        }else if (vis === "allow") {
            iconRm.className = "fi fi-sr-lock";
        }else if (vis === "disallow") {
            iconRm.className = "fi fi-sr-earth-americas";
        }else if (vis === "follows_private") {
            iconFllw.className = "fi fi-sr-lock";
        }else if (vis === "follows_public") {
            iconFllw.className = "fi fi-sr-earth-americas";
        }else if (vis === "subs_private") {
            iconSubs.className = "fi fi-sr-lock";
        }else if (vis === "subs_public") {
            iconSubs.className = "fi fi-sr-earth-americas";
        }
    }

    const demand = () => {
        $("#infoDemand").text(t('settings.privacy.data.sending'));
        $("#infoDemand").css({ display: "block", marginTop: "0", color: "#505050" });
        // ...
        // Mail configuration
        var plaintextContent = '[DATA DEMAND]\n\nWe\'ve received a new data demand from user @'+infos.usertag.toLowerCase().replace('.', '_')+'.\n\nTo send his data package, use this email address : '+infos.email+'\n\nThis is an automatic message, do not reply to it.';
        var htmlContent = '[DATA DEMAND]<br><br>We\'ve received a new data demand from user <a href="https://snot.fr/@'+infos.usertag.toLowerCase().replace('.', '_')+'" style="font-family:Consolas, sans-serif;">@'+infos.usertag.toLowerCase().replace('.', '_')+'</a>.<br><br>To send his data package, use this email address : <span style="font-family:Consolas, sans-serif;">'+infos.email+'</span><br><br>This is an automatic message, do not reply to it.';
        var subject = 'DATA DEMAND: from @'+infos.usertag.toLowerCase().replace('.', '_');
        // Call API in order to send email
        axios({
            method: 'POST',
            url: `https://api.snot.fr/v${API_VERSION}/send/mail`,
            data: {
                sender: "SNOT for Support <no-reply@snot.fr>",
                receiver: 'SNOT Support <support@snot.fr>',
                subject: subject,
                messageHtml: htmlContent,
                textContent: plaintextContent,
                type: "dataDemand"
            }
        })
        .then(response => {
            // Code sent
            if (response.data.msg === 'success') {
                $("#infoDemand").text(t('settings.privacy.data.sent'));
                $("#infoDemand").css({ color: "#1D9BF0" });
            }
            else if(response.data.msg === 'fail') {
                // Error text
                $("#infoDemand").text("The email could'nt be sent, please try again.");
                if (i18n.resolvedLanguage === "fr"){
                    $("#infoDemand").text('L\'email n\'a pas pu être envoyé, veuillez réessayer.')
                }
                $("#infoDemand").css({ color: "#db4e4e" });
            }
        })
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
        
        // Retreive [user] infos
        retreiveInfos().then(res => {
            setInfos(res);
            // Retreive settings
            Settings.retreiveSettings(res.uid).then(setting => {
                setSettingsInfos(setting[0]);
                if(setting !== null){
                    // For visibility (text)
                    var likePrivacy = setting[0].privacy_setting1;
                    var followersPrivacy = setting[0].privacy_setting2;
                    var subsPrivacy = setting[0].privacy_setting3;
                    var accountPrivacy = res.access;
                    if(setting[0].privacy_setting1 === "Private") likePrivacy = t('settings.privacy.private');
                    if(res.access === "Private") accountPrivacy = t('settings.privacy.private');
                    if(setting[0].privacy_setting2 === "Private") followersPrivacy = t('settings.privacy.private');
                    if(setting[0].privacy_setting3 === "Private") subsPrivacy = t('settings.privacy.private');

                    setVisibility({ account: accountPrivacy, like: likePrivacy, followers: followersPrivacy, subs: subsPrivacy })
                    // ...
                    // Account
                    if(res.access === "Private"){
                        $('#private').attr('selected', true);
                        document.querySelector("#icon.vis i").className = "fi fi-sr-lock";
                    }else{
                        $('#public').attr('selected', true);
                        document.querySelector("#icon.vis i").className = "fi fi-sr-earth-americas";
                    }
                    // Like
                    if(setting[0].privacy_setting1 === "Private"){
                        $('#disallow').attr('selected', true);
                        document.querySelector("#icon.rm i").className = "fi fi-sr-lock";
                    }else{
                        $('#allow').attr('selected', true);
                        document.querySelector("#icon.rm i").className = "fi fi-sr-earth-americas";
                    }
                    // Followers
                    if(setting[0].privacy_setting2 === "Private"){
                        $('#follows_private').attr('selected', true);
                        document.querySelector("#icon.fllw i").className = "fi fi-sr-lock";
                    }else{
                        $('#follows_public').attr('selected', true);
                        document.querySelector("#icon.fllw i").className = "fi fi-sr-earth-americas";
                    }
                    // Subs
                    if(setting[0].privacy_setting3 === "Private"){
                        $('#subs_private').attr('selected', true);
                        document.querySelector("#icon.subs i").className = "fi fi-sr-lock";
                    }else{
                        $('#subs_public').attr('selected', true);
                        document.querySelector("#icon.subs i").className = "fi fi-sr-earth-americas";
                    }
                }
            })
        })
    }, [localStorage.getItem("theme"), ])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/privacy" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.privacy')}/>
                <meta property="og:url" content={"https://snot.fr/settings/privacy"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.privacy')}/>
                <title>{t('title.settings.privacy')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <h1 id="title">{t('settings.privacy.titleStrong')}</h1>
                        <div className={"saveBtnContainer_setMain-pcVB1 "+theme}>
                            <span className="text_saveBtnC-sMpcVB1">{t('settings.general.save')}</span>
                            <span id="infoSave" className="info_saveBtnC-sMpcVB1">{t('settings.general.saved')}</span>
                            <div className={"updateBtn_tPSAMVB1 "+theme+" fr"} tabIndex={1} onClick={() => save()}>{t('settings.general.saveButton')}</div>
                        </div>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme} style={{marginTop:-20}}>
                        <div id="AccountSettings" className="privacy">
                            <div className="visibilitySetting_accSetMVB1">
                                <h1 className="title_vsbtySet-prvSetPcVB1" style={{marginBottom: 0}}>{t('settings.privacy.visibility.title')}</h1>
                                <h2 className="info_vsbtySet-prvSetPcVB1">{t('settings.privacy.visibility.description')} <span id="visibility" style={{fontWeight: "600"}}>{visibility.account.toLowerCase()}</span>.</h2>
                                <form method="post" id="formSetVisibility">
                                    <div className="selectBox_visSetMVB1 vis" role="listbox">
                                        <div id="icon" className="vis"><i className="fi fi-sr-earth-americas"></i></div>
                                        <select id="select" tabIndex={2}>
                                            <option value={0} id="public" name="Public" onClick={() => change('public')}>Public</option>
                                            <option value={1} id="private" name="Private" onClick={() => change('private')}>{t('settings.privacy.private')}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="likeVisibilitySetting_accSetMVB1">
                                <h1 className="title_likeSet-prvSetPcVB1" style={{marginBottom: 0}}>{t('settings.privacy.like.title')}</h1>
                                <h2 className="info_likeSet-prvSetPcVB1">{t('settings.privacy.like.description')} <span id="visibility" style={{fontWeight: "600"}}>{visibility.like.toLowerCase()}</span>.</h2>
                                <form method="post" id="formSetLikeVisibility">
                                    <div className="selectBox_visSetMVB1 rm" role="listbox">
                                        <div id="icon" className="rm"><i className="fi fi-sr-lock"></i></div>
                                        <select id="select" tabIndex={3}>
                                            <option value={0} id="disallow" name="Private" onClick={() => change('allow')}>{t('settings.privacy.private')}</option>
                                            <option value={1} id="allow" name="Public" onClick={() => change('disallow')}>Public</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="followersVisibilitySetting_accSetMVB1">
                                <h1 className="title_followersSet-prvSetPcVB1" style={{marginBottom: 0}}>{t('settings.privacy.followers.title')}</h1>
                                <h2 className="info_followersSet-prvSetPcVB1">{t('settings.privacy.followers.description')} <span id="visibility" style={{fontWeight: "600"}}>{visibility.followers.toLowerCase()}</span>.</h2>
                                <form method="post" id="formSetFollowersAccess">
                                    <div className="selectBox_visSetMVB1 fllw" role="listbox">
                                        <div id="icon" className="fllw"><i className="fi fi-sr-lock"></i></div>
                                        <select id="select" tabIndex={3}>
                                            <option value={1} id="follows_public" name="Public" onClick={() => change('follows_public')}>Public</option>
                                            <option value={0} id="follows_private" name="Private" onClick={() => change('follows_private')}>{t('settings.privacy.private')}</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="subsVisibilitySetting_accSetMVB1">
                                <h1 className="title_subsSet-prvSetPcVB1" style={{marginBottom: 0}}>{t('settings.privacy.subs.title')}</h1>
                                <h2 className="info_subsSet-prvSetPcVB1">{t('settings.privacy.subs.description')} <span id="visibility" style={{fontWeight: "600"}}>{visibility.subs.toLowerCase()}</span>.</h2>
                                <form method="post" id="formSetSubsAccess">
                                    <div className="selectBox_visSetMVB1 subs" role="listbox">
                                        <div id="icon" className="subs"><i className="fi fi-sr-lock"></i></div>
                                        <select id="select" tabIndex={3}>
                                            <option value={0} id="subs_private" name="Private" onClick={() => change('subs_private')}>{t('settings.privacy.private')}</option>
                                            <option value={1} id="subs_public" name="Public" onClick={() => change('subs_public')}>Public</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="demandCopySetting_privacySetMVB1">
                                <h1 className="title_dataet-prvSetPcVB1" style={{marginBottom: 0}}>{t('settings.privacy.data.title')}</h1>
                                <h2 className="info_dataSet-prvSetPcVB1">{t('settings.privacy.data.description')}</h2>
                                <span id="infoDemand" className="info_saveBtnC-sMpcVB1">{t('settings.privacy.data.sent')}</span>
                                <div className="demandDataBtn_privacySetMVB1" tabIndex={4} onClick={() => demand()}>
                                    <span>{t('settings.privacy.data.button')}</span>
                                    <i className="fi fi-rr-file-user"></i>
                                </div>            
                            </div>
                            <div className="seeOurPoliciesBlock">
                                <div className="seeOurPolicies" style={{maxWidth: 600, width: "fit-content"}}>
                                    {t('settings.privacy.terms.part1')} <a href={"https://about.snot.fr/"+i18n.resolvedLanguage+"/tos"} tabIndex={5} lang={i18n.resolvedLanguage} hreflang={i18n.resolvedLanguage} target="_blank" rel="noreferrer">{t('footer.tos')}</a> {t('settings.privacy.terms.part2')} <a href={"https://about.snot.fr/"+i18n.resolvedLanguage+"/privacy"} tabIndex={6} lang={i18n.resolvedLanguage} hreflang={i18n.resolvedLanguage} target="_blank" rel="noreferrer">{t('footer.privacy')}</a>.
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsPrivacy;