import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
import { VerticalMenu } from "../../../Components/Pages/Settings";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import * as Settings from '../../../Components/Functions/Settings';
import { API_VERSION } from "../../../Components/Functions";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";

const SettingsAccessibility = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    const [links, setLinks] = useState({
        text: t('settings.accessibility.links.action.inactive'),
        status: t('settings.accessibility.links.action.inactive_status'),
        icon: "cross-circle"
    });
    const [infos, setInfos] = useState([]);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])

    // GLOBAL FUNCTIONS
    const changeLinks = () => {
        var term = "No";
        if(links.text === "Actif"){
            setLinks({
                text: t('settings.accessibility.links.action.inactive'),
                status: t('settings.accessibility.links.action.inactive_status'),
                icon: "cross-circle"
            })
            // Live change
            $('a').css({ textDecorationLine: "none" });
        }else{
            term = "Yes";
            setLinks({
                text: t('settings.accessibility.links.action.active'),
                status: t('settings.accessibility.links.action.active_status'),
                icon: "check-circle"
            })
            // Live change
            $('a').css({ textDecorationLine: "underline" });
            $('.navBar_pcverSB1 a').css({ textDecorationLine: "none" });
            $('.topMenu_pcverSB1 a').css({ textDecorationLine: "none" });
        }
        // Edit in DB
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                data: { setting: "accessibility_setting2", term: term }
            }
        )
    }

    const save = () => {
        var saturation = $('.selectBox_visSetMVB1.saturation').find(":selected").text();
        if (saturation === "100% ("+t('settings.accessibility.saturation.default')+")") saturation = "100%";
        // Live change
        document.body.style.filter = "saturate("+saturation+")";
        // Edit in DB
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit?token=${Cookies.get('loginToken')}&app=web`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                data: { setting: "accessibility_setting1", term: saturation }
            }
        )
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }

        // Retreive settings
        retreiveInfos().then(res => {
            setInfos(res);
            Settings.retreiveSettings(res.uid).then(settings => {
                if(settings[0] !== null){
                    // SATURATION
                    if(settings[0].accessibility_setting1){
                        if(settings[0].accessibility_setting1 === "100%"){
                            $('#default').attr('selected', true);
                        }else if(settings[0].accessibility_setting1 === "75%"){
                            $('#second').attr('selected', true);
                        }else if(settings[0].accessibility_setting1 === "50%"){
                            $('#third').attr('selected', true);
                        }else if(settings[0].accessibility_setting1 === "25%"){
                            $('#fourth').attr('selected', true);
                        }else if(settings[0].accessibility_setting1 === "0%"){
                            $('#last').attr('selected', true);
                        }
                    }
                    // LINKS
                    if(settings[0].accessibility_setting2 === "No"){
                        setLinks({
                            text: t('settings.accessibility.links.action.inactive'),
                            status: t('settings.accessibility.links.action.inactive_status'),
                            icon: "cross-circle"
                        })
                    }else{
                        setLinks({
                            text: t('settings.accessibility.links.action.active'),
                            status: t('settings.accessibility.links.action.active_status'),
                            icon: "check-circle"
                        })
                    }
                }
            })
        })
    }, [localStorage.getItem("theme")])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/accessibility" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.accessibility')}/>
                <meta property="og:url" content={"https://snot.fr/settings/accessibility"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.accessibility')}/>
                <title>{t('title.settings.accessibility')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <h1 id="title">{t('settings.accessibility.titleStrong')}</h1>
                        <div className={"saveBtnContainer_setMain-pcVB1 noButton "+theme}>
                            <span className="text_saveBtnC-sMpcVB1">{t('settings.general.autosave')}</span>
                        </div>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="AccountSettings" className="privacy accessiblity" style={{marginTop:-20}}>
                            <div className="saturation_accessSetMVB1">
                                <h1 className="title_satSet-accessSetPcVB1" style={{marginBottom: 0}}>{t('settings.accessibility.saturation.title')}</h1>
                                <h2 className="info_satSet-accessSetPcVB1">{t('settings.accessibility.saturation.description')}</h2>
                                <div className="selectBox_visSetMVB1 saturation" style={{ width: 170 }}>
                                    <select id="select" tabIndex={1}>
                                        <option value="0" id="default" onClick={() => save()}>100% ({t('settings.accessibility.saturation.default')})</option>
                                        <option value="1" id="second" onClick={() => save()}>75%</option>
                                        <option value="2" id="third" onClick={() => save()}>50%</option>
                                        <option value="3" id="fourth" onClick={() => save()}>25%</option>
                                        <option value="4" id="last" onClick={() => save()}>0%</option>
                                    </select>
                                </div>
                            </div>
                            <div className="saturation_accessSetMVB1" style={{borderBottom: 0}}>
                                <h1 className="title_linksSet-accessSetPcVB1" style={{marginBottom: 0}}>{t('settings.accessibility.links.title')}</h1>
                                <h2 className="info_linksSet-accessSetPcVB1">{t('settings.accessibility.links.description')}</h2>
                                <div className="underlineLinks_buttonContainer" style={{marginTop: -20,borderBottom: 0}}>
                                    <div className="twofaBtn_2faSetMVB1 links" id="underlineLinks" role="button" title="Activer" tabIndex={2} onClick={changeLinks}>
                                        <i className={"fi fi-sr-"+links.icon} style={{ height: "fit-content", display: "flex", marginRight: 10 }}></i>
                                        <span id="linksBtnText">{links.text}</span>
                                    </div>
                                    <span id="statusAccess">{links.status}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsAccessibility;