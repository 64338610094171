import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from '../../Functions';
import { retreiveCreatorInfos } from '../../Functions/Home';

const ManageMembers = ({ user, members, pKey, theme }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set data
    const [membersInfos, setMembersInfos] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [error, setError] = useState('');
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    const MembersList = () => {
        useEffect(() => {
            membersInfos.map((response) => {
                $('.friend_cGrPcVB1.manage.ref_'+response.uid).off('click').on('click', () => selectMembers(response.uid));
            })
        }, [membersInfos]);

        if (membersInfos.length !== 0) {
            return (
                <>
                    {membersInfos.map((response) => (
                        <div className={`friend_cGrPcVB1 manage ref_${response.uid}`} style={{ alignItems: 'center' }} key={response.uid}>
                            <div id="profilePart">
                                <div className={loadingAvatar ? `avatar skeleton ${theme}` : `avatar ${theme}`}>
                                    <img alt={"@" + response.usertag.replace('.', '_').toLowerCase()} title={response.username} src={response.avatar}
                                        onError={(e) => {
                                            if (!e.currentTarget.src.includes('.googleusercontent.com')) {
                                                e.currentTarget.src = 'https://api.snot.fr/v' + API_VERSION + '/content/icon_profile?ext=webp';
                                            }
                                        }}
                                        style={loadingAvatar ? { display: "none" } : {}}
                                        onLoad={() => setLoadingAvatar(false)}
                                        referrerPolicy="no-referrer"
                                    />
                                    <div id="presence" style={{ width: "100%", marginLeft: 2, marginTop: -18 }}>
                                        <div className={`presence_presencePPartMVB1 user_${response.uid}`} style={{ outline: "3px solid #101010", border: "2px solid transparent", width: 10, height: 10 }}></div>
                                    </div>
                                </div>
                                <div className="user" style={{ marginLeft: 15 }}><span id="username">{response.username}</span></div>
                                <div className="usertag"><span id="usertag">@{response.usertag.replace('.', '_').toLowerCase()}</span></div>
                            </div>
                            <i className='fi fi-rr-plus' style={{ display: 'flex', margin: '0 5px 0 auto' }}></i>
                        </div>
                    ))}
                </>
            );
        } else {
            return (
                <p className="text_frFPB1" style={{ textAlign: "center", width: 350, margin: "20px auto", fontSize: 14 }}>
                    <i className="fi fi-rr-interrogation" style={{ display: "flex", alignItems: "center", height: "fit-content", fontSize: 36, justifyContent: "center", color: theme === "darkTheme" ? "#9A9A9A" : "#707070" }}></i>
                    <span className="texton_frPFPB1" style={{ display: "flex", marginTop: 15, justifyContent: "center", fontSize: 24 }}>{t('profile.error.noFollowersSubs.title')}</span>
                    {t('profile.error.noResults.description')}
                </p>
            );
        }
    };

    const selectMembers = (ref) => {
        var card = $('.friend_cGrPcVB1.manage.ref_'+ref);
        var icon = $('.friend_cGrPcVB1.manage.ref_'+ref+' > i');
        // UI / UX
        if(icon.hasClass("fi-rr-plus")) {
            icon.removeClass("fi-rr-plus").addClass("fi-rr-check");
            if(!card.hasClass('selected')) card.addClass('selected');
            // Append to list
            selectedMembers.push(ref);
        }else{
            icon.removeClass("fi-rr-plus").addClass("fi fi-rr-plus");
            if(card.hasClass('selected')) card.removeClass('selected');
            // Remove from list
            var index = selectedMembers.indexOf(ref);
            if (index > -1) {
                selectedMembers.splice(index, 1);
            }
        }
    }

    const removeMembers = () => {
        if (selectedMembers.length >= 1) {
            if (JSON.parse(members).length - selectedMembers.length < 3) {
                setError(t('groups.manageMembers.modal.errors.max'));
                return;
            }

            // Set loading
            $("#manageMembers .mainPart_fSLMPcB1 .error").css('color', theme === "darkTheme" ? "#9A9A9A": "#707070");
            setError(t('groups.manageMembers.modal.loading'));

            axios.put(
                `https://api.snot.fr/v${API_VERSION}/group/remove/members/${pKey}`,
                { addMembers: selectedMembers, members: members },
                {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: user.uid,
                        app: 'web'
                    }
                }
            ).then(response => {
                if (response.data.success) {
                    $('#manageMembers').trigger('click'); // Close modal
                }
            }).catch(err => {
                $('#manageMembers .mainPart_fSLMPcB1 .error').css('color', 'rgb(255, 99, 99)');
                setError('Failed to remove members.');
            });
        } else {
            setError(t('groups.addMembers.modal.errors.friends.min'));
        }
    };

    useEffect(() => {
        const fetchMembersInfos = async () => {
            const membersList = JSON.parse(members);
            membersList.splice(user.uid, 1);
    
            const results = await Promise.all(membersList.map(member => retreiveCreatorInfos(member)));
            const existingUids = new Set(membersInfos.map(info => info.uid));

            const newMembers = results.filter(res => {
                if (!existingUids.has(res.uid)) {
                    existingUids.add(res.uid);
                    return true;
                }
                return false;
            });
    
            setMembersInfos(prevMembers => {
                const updatedMembers = [...prevMembers, ...newMembers];
                return Array.from(new Set(updatedMembers.map(member => member.uid)))
                            .map(uid => updatedMembers.find(member => member.uid === uid));
            });
        };
    
        fetchMembersInfos();
    }, []);

    useEffect(() => {
        $('.actions_gPcVB1 #create.remove').off('click').on('click', removeMembers);
    }, [selectedMembers, error])

    return (
        <>
        <aside id="manageMembers" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div id='scrollableFriends' className="modal-wrapper js-modal-stop" style={{backgroundColor: theme === "darkTheme" ? "#101010": "#E3E3E3",color: theme === "darkTheme" ? "#fff": "#000",padding: 25,width: 400,maxHeight: 500}}>
                <div className="topPart_groupPcVB1">
                    <h1>{t('groups.manageMembers.button')}</h1>
                    <h2>
                        {t('groups.manageMembers.subtitle')}
                    </h2>
                </div>
                <div className="mainPart_fSLMPcB1" style={{marginTop: 25}}>
                    <p className='error' style={{ margin: 0, color: 'rgb(255, 99, 99)', fontSize: 14, marginBottom: error !== '' && 15 }}>{error}</p>
                    {MembersList()}
                    <div className="stickyBottom_aGPcVB1">
                        <div className="actions_gPcVB1">
                            <div id="cancel" className='js-modal-close-other' tabIndex={4}>
                                <i className="fi fi-rr-arrow-left"></i>
                                <span>{t('groups.modals.goBack')}</span>
                            </div>
                            <div id="create" className='remove' tabIndex={3}>
                                <i className="fi fi-rr-check"></i>
                                <span>{t('groups.manageMembers.modal.button')}</span>
                            </div>
                        </div>
                        <p className="note_gPcVB1">{t('groups.manageMembers.modal.note')}</p>
                    </div>
                </div>
            </div>
        </aside>
        </>
    )
}

export default ManageMembers;