// import Images
import errorMediaDark from '../../../img/error/media_dark.png';
import errorMediaLight from '../../../img/error/media_light.png';

export default function SeeMedia({type: { video, image }, media, theme}) {
    // BUILD THE MODAL
    return (
        <>
            <aside id="seeMedia" className="modal darkTheme" aria-hidden="true" aria-modal="false" style={{ display: 'none' }}>
                <div className="modal-wrapper js-modal-stop" style={{padding:0,paddingBottom:10,borderRadius: "0 0 20px 20px",width:"auto"}}>
                    <div className="mediaVideo_sMappMVeB1" style={{ display: video }}>
                        <div className="videoContainer_videoAMB1">
                            <video id="videoPlayer" src={media} preload="metadata"></video>
                            <div className="overlay">
                                <div className="play-pause-button"><i className="fi fi-sr-play"></i></div>
                                <div className="bottom_bar">
                                    <div className="playpauseBtn_bBarB1"><i className="fi fi-sr-play"></i></div>
                                    <div className="progressBar_bBarB1">
                                        <div className="progress-bar-fill"></div>
                                    </div>
                                    <div className="volumeControl_bBarB1" style={{marginRight: 20}}>
                                        <div className="volume-icon"><i className="fi fi-ss-volume"></i></div>
                                        <input type="range" className="volume-slider" min="0" max="1" step="0.05" value="0.5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mediaImage_sMappMVeB1" style={{ display: image }}>
                        <img id="mediaImg" src={media} alt="Image" title={media}
                            onError={({ currentTarget }) => {
                                currentTarget.src = errorMediaLight;
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                    <a id="seeNavigator" href={media} target="_blank" rel="noreferrer" style={{color:"#9A9A9A",textDecorationLine:"underline",fontSize:14,margin:20}}>Ouvrir dans un nouvel onglet</a>
                </div>
            </aside>
        </>
    );
}