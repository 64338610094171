import $ from 'jquery';

const changeMedia = (src, type) => {
    if($(('#seeMedia'))){
        if(type === "image"){
            $('.mediaImage_sMappMVeB1').css({ display: 'flex' });
            $('.mediaVideo_sMappMVeB1').css({ display: 'none' });
            $('#mediaImg').attr('src', src);
            $('#mediaImg').attr('title', src);
        }else{
            $('.mediaVideo_sMappMVeB1').css({ display: 'flex' });
            $('.mediaImage_sMappMVeB1').css({ display: 'none' });
            $('#videoPlayer').attr('src', src);
        }
        // HREF
        $('#seeNavigator').attr('href', src)
    }
}

export default changeMedia;