import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
// import Components
import News from "../Modals/Settings/News";
import LogOut from "../Modals/Settings/LogOut";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../Functions";
import { openModal } from "../Functions/Modal";
import { openMenu } from "../Functions/Menu";
// import Auth
import { retreiveInfos } from "../Functions/Auth";

const StaffMenu = ({theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
        <section className={"topMenu_pcverSB1 "+theme+" logged"} style={{overflow:"auto"}}>
            <div className={"others_fBmVeB1 "+theme} style={{userSelect: "none",WebkitUserSelect: "none"}}>
                <div id="reports" className={(location.pathname === "/staff" || location.pathname === "/staff/") ? "current": ""} onClick={() => navigate("/staff")}>
                    <i className={(location.pathname === "/staff" || location.pathname === "/staff/") ? "fi fi-sr-flag": "fi fi-rr-flag"}></i>
                    <span>{t("staff.reports.title")}</span>
                </div>
                <div id="logs" className={location.pathname === "/staff/logs" ? "current": ""} onClick={() => navigate("/staff/logs")}>
                    <i className={location.pathname === "/staff/logs" ? "fi fi-sr-clock": "fi fi-rr-clock"}></i>
                    <span>{t("staff.logs.title")}</span>
                </div>
                <div id="certification" className={location.pathname === "/staff/certification" ? "current": ""} onClick={() => navigate("/staff/certification")}>
                    <i className={location.pathname === "/staff/certification" ? "fi fi-sr-badge-check": "fi fi-rr-badge-check"}></i>
                    <span>{t("staff.certification.title")}</span>
                </div>
            </div>
        </section>
        <News />
        <LogOut theme={theme} />
        </>
    )
}

export {
    StaffMenu,
};