import { useEffect, useState } from "react";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";

const BlockModal = ({uid, user, theme}) => {
    const { t } = useTranslation();
    const [modal, setModal] = useState(<></>);

    // Get private key
    const getKeyPM = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/friends/${user}/key`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    uid: uid,
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.key);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    // Verify if [user] has blocked [user_2]
    const blockedUser = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${uid}/blocked/${user}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.blocked);
            }).catch((error) => {
                resolve(0);
            })
        })
    }

    useEffect(() => {
        getKeyPM().then(key => {
            blockedUser().then(res => {
                if(res === 0){
                    setModal(<>
                        <aside id="confirmBlock" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                            <div className="modal-wrapper js-modal-stop" style={{width:400}}>
                                <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10,position:"absolute",right:20}}>
                                    <i className="fi fi-rr-cross"></i>
                                </button>
                                <div className={"confirm remove "+theme}>
                                    <div id="logoTop">
                                        <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                                    </div>
                                    <h1 id="title" style={{ width: "100%" }}>{t('general.modal.block.title')}</h1>
                                    <p id="subtitle" style={{ width: "100%" }}>{t('general.modal.block.subtitle')}</p>
                                    <div id="actionsButton" style={{ width: "100%" }}>
                                        <div className="button_returnMVB1"><span>{t('general.buttons.cancel')}</span></div>
                                        <div className="button_confirmMVB1"><span>{t('general.modal.block.button')}</span></div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </>);
                    // EVENT LISTENERS
                    $('.button_returnMVB1').off('click').on('click', () => $('#confirmBlock').trigger('click'));
                    $('.button_confirmMVB1').off('click').on('click', () => {
                        // Block user (API)
                        axios.post(
                            `https://api.snot.fr/v${API_VERSION}/block/${user}`,
                            { uid: uid, key: key },
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                params: {
                                    token: Cookies.get('loginToken'),
                                    app: "web"
                                }
                            }
                        ).then(res => {
                            if(res.data.message === "BLOCK_USER"){
                                window.location.reload();
                            }
                        }).catch(error => console.warn("Oops! Something went wrong. Here's the details : "+error.response.data.message.replace('_', ' ')))
                    })
                }else{
                    setModal(<>
                        <aside id="confirmUnblock" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                            <div className="modal-wrapper js-modal-stop" style={{width:400}}>
                                <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10,position:"absolute",right:20}}>
                                    <i className="fi fi-rr-cross"></i>
                                </button>
                                <div className={"confirm remove "+theme}>
                                <div id="logoTop">
                                        <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                                    </div>
                                    <h1 id="title" style={{ width: "100%" }}>{t('general.modal.unblock.title')}</h1>
                                    <p id="subtitle" style={{ width: "100%" }}>{t('general.modal.unblock.subtitle')}</p>
                                    <div id="actionsButton" style={{ width: "100%" }}>
                                        <div className="button_returnMVB1"><span>{t('general.buttons.cancel')}</span></div>
                                        <div className="button_confirmMVB1"><span>{t('friends.blocked.button')}</span></div>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </>);
                    // EVENT LISTENER
                    $('.button_returnMVB1').off('click').on('click', () => $('#confirmUnblock').trigger('click'));
                    $('.button_confirmMVB1').off('click').on('click', () => {
                        // Unblock user (API)
                        axios.delete(
                            `https://api.snot.fr/v${API_VERSION}/unblock/${user}`,
                            {
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                params: {
                                    token: Cookies.get('loginToken'),
                                    uid: uid,
                                    app: "web",
                                }
                            }
                        ).then(res => {
                            if(res.data.message === "UNBLOCK_USER"){
                                window.location.reload();
                            }
                        }).catch(error => console.warn("Oops! Something went wrong. Here's the details : "+error.response.data.message.replace('_', ' ')))
                    })
                }
            })
        })
    }, [])

    return modal;
}

export default BlockModal;