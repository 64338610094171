import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";

const ReportModal = ({myUID, theme}) => {
    const { t } = useTranslation();
    const [selectedReason, setSelectedReason] = useState(null);
    const [details, setDetails] = useState({ title: '', rules: [] });
    const [type, setType] = useState(undefined);
    const [uid, setUid] = useState(undefined);

    const reasons = [
        { title: t('general.report.reasons.1.title'), details: [t('general.report.reasons.1.details.1'), t('general.report.reasons.1.details.2'), t('general.report.reasons.1.details.3')] },
        { title: t('general.report.reasons.2.title'), details: [t('general.report.reasons.2.details.1'), t('general.report.reasons.2.details.2'), t('general.report.reasons.2.details.3'), t('general.report.reasons.2.details.4')] },
        { title: t('general.report.reasons.3.title'), details: [t('general.report.reasons.3.details.1'), t('general.report.reasons.3.details.2'), t('general.report.reasons.3.details.3'), t('general.report.reasons.3.details.4'), t('general.report.reasons.3.details.5'), t('general.report.reasons.3.details.6')] },
        { title: t('general.report.reasons.4.title'), details: [t('general.report.reasons.4.details.1'), t('general.report.reasons.4.details.2')] },
        { title: t('general.report.reasons.5.title'), details: [t('general.report.reasons.5.details.1'), t('general.report.reasons.5.details.2'), t('general.report.reasons.5.details.3'), t('general.report.reasons.5.details.4')] },
        { title: t('general.report.reasons.6.title'), details: [t('general.report.reasons.6.details.1'), t('general.report.reasons.6.details.2')] },
        { title: t('general.report.reasons.7.title'), details: [t('general.report.reasons.7.details.1'), t('general.report.reasons.7.details.2'), t('general.report.reasons.7.details.3'), t('general.report.reasons.7.details.4'), t('general.report.reasons.7.details.5')] },
        { title: t('general.report.reasons.8.title'), details: [t('general.report.reasons.8.details.1'), t('general.report.reasons.8.details.2')] },
        { title: t('general.report.reasons.9.title'), details: [t('general.report.reasons.9.details.1')] },
        { title: t('general.report.reasons.10.title'), details: [t('general.report.reasons.10.details.1'), t('general.report.reasons.10.details.2'), t('general.report.reasons.10.details.3')] },
        { title: t('general.report.reasons.11.title'), details: [t('general.report.reasons.11.details.1')] },
        { title: t('general.report.reasons.12.title'), details: [t('general.report.reasons.12.details.1')] },
        { title: t('general.report.reasons.13.title'), details: [t('general.report.reasons.13.details.1')] },
    ];

    useEffect(() => {
        const blocs = document.querySelectorAll('.mainPart_rMGVB1 > div');
        blocs.forEach((el, index) => {
            el.removeEventListener('click');
            el.addEventListener('click', () => {
                setSelectedReason(index);
                const { title, details } = reasons[index];
                setDetails({ title, rules: details });
                // UI
                $('.details_rMGVB1').css('display', 'flex');
                $('.mainPart_rMGVB1').css('display', 'none');
                $(".topTitle_container").css({
                    flexDirection: 'row',
                    alignItems: 'center'
                });
                $('.topTitle_container h2').css('display', 'none');
                $(".topTitle_container > i").css({
                    display: 'flex',
                    marginRight: 15,
                    cursor: 'pointer'
                });
                $('.topPart_fSLMPcB1 .js-modal-close').css({ margin: 'auto 15px auto auto' });
            })
        });

        $(".topTitle_container > i").off('click').on('click', () => {
            $('.details_rMGVB1').css('display', 'none');
            $('.mainPart_rMGVB1').css('display', 'flex');
            $(".topTitle_container").css({
                flexDirection: 'column',
                alignItems: 'baseline'
            });
            $('.topTitle_container h2').css('display', 'block');
            $(".topTitle_container > i").css({display: 'none'});
            $('.topPart_fSLMPcB1 .js-modal-close').css({ margin: '20px 20px auto auto' });
        });

        $('#reportModal').on('click', () => {
            $('.details_rMGVB1').css('display', 'none');
            $('.mainPart_rMGVB1').css('display', 'flex');
            $(".topTitle_container").css({
                flexDirection: 'column',
                alignItems: 'baseline'
            });
            $('.topTitle_container h2').css('display', 'block');
            $(".topTitle_container > i").css({display: 'none'});
            $('.topPart_fSLMPcB1 .js-modal-close').css({ margin: '20px 20px auto auto' });
        });
        $('#reportModal .js-modal-close').on('click', () => {
            $('.details_rMGVB1').css('display', 'none');
            $('.mainPart_rMGVB1').css('display', 'flex');
            $(".topTitle_container").css({
                flexDirection: 'column',
                alignItems: 'baseline'
            });
            $('.topTitle_container h2').css('display', 'block');
            $(".topTitle_container > i").css({display: 'none'});
            $('.topPart_fSLMPcB1 .js-modal-close').css({ margin: '20px 20px auto auto' });
        });
    }, [])

    useEffect(() => {
        $('.details_rMGVB1 > #send').off('click').on('click', () => {
            axios.post(
                `https://api.snot.fr/v${API_VERSION}/report`,
                { user: myUID, reported: uid, reason: details.title, cr: type !== 'spost' ? type: null },
                {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(() => $('#reportModal').trigger('click'));
        });
    }, [details, type, uid, myUID])

    useEffect(() => {
        if(type !== localStorage.getItem('report-type')){
            setType(localStorage.getItem('report-type'));
        }
    }, [type, localStorage.getItem('report-type')])

    useEffect(() => {
        if(uid !== localStorage.getItem('report-uid')){
            setUid(localStorage.getItem('report-uid'));
        }
    }, [uid, localStorage.getItem('report-uid')])

    return (
        <aside id="reportModal" className={"modal "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div className="modal-wrapper js-modal-stop" style={{backgroundColor: theme === "darkTheme" ? "#101010": "#EEE",padding: 0,height: 500,width: 400}}>
                <div className={"topPart_fSLMPcB1 "+theme} style={{ alignItems: "baseline", height: 'auto' }}>
                    <div className="topTitle_container">
                        <i className="fi fi-rr-angle-left" style={{ display: 'none' }}></i>
                        <h1>{t('general.report.title.'+(type === "com" ? "comment": type))}</h1>
                        <h2>{t('general.report.subtitle')}</h2>
                    </div>
                    <button className={"js-modal-close "+theme} style={{ margin: "20px 20px auto auto" }}>
                        <i className="fi fi-rr-cross"></i>
                    </button>
                </div>
                <div className={"mainPart_rMGVB1 "+theme}>
                    {reasons.map((reason, index) => (
                        <div key={index} {...(index === 12 && { style: {border: 0} })}>
                            <p id="reason">{reason.title}</p>
                            <i className="fi fi-ss-angle-right"></i>
                        </div>
                    ))}
                </div>
                <div className="details_rMGVB1" style={{ display: "none" }}>
                    <h1 id="reason">{details.title}</h1>
                    <p id="rules">
                        {t('general.report.detail')}<br/>
                        <ul i18nIsDynamicList>
                            {details.rules.map((rule, idx) => (
                                <li key={idx}>{rule}</li>
                            ))}
                        </ul>
                    </p>
                    <div id="send">
                        <span>{t('general.report.button')}</span>
                        <i className="fi fi-rr-flag"></i>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default ReportModal;