import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";
import { retreiveInfos } from "../../Functions/Auth";
// import Images
// -- Gift
import case1 from '../../../img/emojis/specials/xmas/bears/bear_gift.webp'; // Emoji case #1
import case2 from '../../../img/decorations/xmas/garland-preview.svg'; // Avatar decoration #2
import case3 from '../../../img/emojis/specials/xmas/dogs/dog_disguised.png'; // Emoji case #3
import case4 from '../../../img/emojis/specials/xmas/bears/bearsEmojiPack.png'; // Emoji case #4
import case5 from '../../../img/emojis/specials/xmas/dogs/dogsEmojiPack.png'; // Emoji case #5
import case6 from '../../../img/emojis/specials/polar_bear/pBearEmojiPack.png'; // Emoji pack case #6
import case7 from '../../../img/decorations/xmas/snow-preview.svg'; // Avatar decoration #7
import case9 from '../../../img/badges/xmas/xmas24-dark.png'; // XMAS Badge #9

const AdventCalendar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentDay, setCurrentDay] = useState(null);
    const [claimed, setClaimed] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [show, setShow] = useState(false);
    const [showBtn, setShowBtn] = useState(false);
    const [loading, setLoading] = useState(true);

    const associatedGifts = [
        t('special.christmas.adventCalendar.gifts.1'), t('special.christmas.adventCalendar.gifts.2'), t('special.christmas.adventCalendar.gifts.3'), t('special.christmas.adventCalendar.gifts.4'), t('special.christmas.adventCalendar.gifts.5'), 
        t('special.christmas.adventCalendar.gifts.6'), t('special.christmas.adventCalendar.gifts.7'), t('special.christmas.adventCalendar.gifts.8'), t('special.christmas.adventCalendar.gifts.9'), t('special.christmas.adventCalendar.gifts.10'),
        t('special.christmas.adventCalendar.gifts.11'), t('special.christmas.adventCalendar.gifts.12')
    ];
    const associatedGiftsImages = [
        case1, case2, case3, case4, case5, 
        case6, case7, '', case9, t('special.christmas.adventCalendar.gifts.10'),
        t('special.christmas.adventCalendar.gifts.11'), t('special.christmas.adventCalendar.gifts.12')
    ];
    // ASSOCIATED DATE
    const associateDates = [
        new Date('12-1-2024'),
        new Date('12-3-2024'),
        new Date('12-5-2024'),
        new Date('12-7-2024'),
        new Date('12-9-2024'),
        new Date('12-11-2024'),
        new Date('12-13-2024'),
        new Date('12-15-2024'),
        new Date('12-17-2024'),
        new Date('12-19-2024'),
        new Date('12-21-2024'),
        new Date('12-23-2024')
    ];

    const closeModal = (e) => {
        let modal = document.getElementById('adventCalendar');

        e.preventDefault();
        modal.setAttribute('aria-hidden', 'true');
        modal.removeAttribute('aria-modal');
        modal.removeEventListener('click', closeModal);
        modal.querySelector('.js-modal-stop').removeEventListener('click', e.stopPropagation());
        const hideModal = function () {
            modal.style.display = "none";
            modal.removeEventListener('animationend', hideModal);
            modal = null;
        }
        modal.addEventListener('animationend', hideModal);
        document.body.style.overflow = "auto";
    }

    // FETCH CLAIMED CASES
    const claimedCases = async (uid) => {
        try {
            const claimed = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/christmas/${uid}/gift/claimed`,
                {
                    headers: { "Content-Type": 'application/json' },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(res => res.data.claimed)

            if(claimed === null){
                setClaimed([]);
            }else{
                setClaimed(claimed);
            }
        }catch(err) {
            console.log('Error while fetching claimed cases : ', err)
        }finally {
            setLoading(false);
        }
    }

    // FETCH IF SHOW IS DISABLED
    const showDisabled = async (uid, only=null) => {
        try {
            const calendar = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/christmas/${uid}/calendar`,
                {
                    headers: { "Content-Type": 'application/json' },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(res => res.data)

            if(only === null){
                setShow(calendar);
                setShowBtn(calendar);
            }else{
                setShow(false);
                setShowBtn(calendar);
            }
        }catch(err) {
            console.log('Error while fetching show calendar : ', err)
        }
    }

    useEffect(() => {
        const today = new Date();
        setCurrentDay(today.getDate());

        // SYNCHRONIZE OPEN CASES
        retreiveInfos().then(async (res) => {
            setUserInfo(res);
            // SHOW
            if(Cookies.get('calendar-show') === 'false'){
                await showDisabled(res.uid, "button");
            }else{
                await showDisabled(res.uid);
            }
            // ...
            await claimedCases(res.uid);
        })
    }, [])

    useEffect(() => {
        // GIFTS EVENTS
        const gift = $('.gift_gCPcVB1.close.light');
        const animateGift = $('.animateGift_adventCalendar');

        if(gift) {
            gift.off('click').on('click', (e) => {
                // ANIMATION
                const giftIndex = $(e.currentTarget).data('index');
                animateGift.find('.gift span').text(associatedGifts[giftIndex]);
                animateGift.find('.gift img').attr('src', associatedGiftsImages[giftIndex]);
                animateGift.find('.gift img').css('display', 'block');
                if(giftIndex === 6 || giftIndex === 1 || giftIndex === 8){
                    animateGift.find('.gift span').css('font-size', '15px');
                }else if(giftIndex === 7){
                    animateGift.find('.gift img').css('display', 'none');
                }else{
                    animateGift.find('.gift span').css('font-size', '16px');
                }

                $('.giftIndex_'+giftIndex+' #gift .lid').css({
                    transform: 'translate(0px, 51px) rotate(0deg)',
                });
                $('.giftIndex_'+giftIndex+' #gift .body').css('fill', '#B93F3F');
                $('.giftIndex_'+giftIndex+' #gift .yellow').css('fill', '#C8BC66');
                $('.giftIndex_'+giftIndex+' .giftClaimed').animate({
                    opacity: 1
                }, 500);

                // -- Light
                $('.animateLight').animate({
                    opacity: 1,
                    transform: 'translate(-50%, -50%) scale(1)'
                }, 500);
                setTimeout(() => {
                    $('.animateLight').css('animation', 'pulseLight 2.5s ease-in-out 1');
                }, 700);
                // -- Shaking page
                document.body.classList.add('shake')
                setTimeout(() => {
                    document.body.classList.remove('shake');
                    // -- Open gift
                    $('.animateLight').css('animation', 'pulseLightDown 6s ease-in-out infinite');
                    
                    animateGift.addClass('open').css('z-index', 999);
                    animateGift.animate({
                        opacity: 1
                    }, 1000);

                    setTimeout(() => {
                        $('.animateGift_adventCalendar .gift').css('transform', 'translateY(-50px)').animate({
                            opacity: 1
                        }, 500);
                    }, 700)

                    // GIFT ANIMATION (CLOSE)
                    if(animateGift && animateGift.hasClass('open')){
                        animateGift.off('click').on('click', () => {
                            animateGift.removeClass('open').css({
                                zIndex: -1,
                                opacity: 0
                            });
                            $('.animateGift_adventCalendar .gift').css('transform', 'translateY(0)');
                            $('.animateLight').css({
                                opacity: 0,
                                transform: 'translate(-50%, -50%) scale(1)',
                                animation: 'none'
                            }, 500);
                            $(e.currentTarget).off('click');
                        })
                    }
                }, 3000);

                // CREATE SQL ENTRY
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/christmas/${userInfo.uid}/gift/${giftIndex}/claim`,
                    undefined,
                    {
                        headers: { "Content-Type": 'application/json' },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).catch(err => console.log('Something went wrong : ', err.response.data.message));
            })
        }
        // ACTIONS BUTTONS
        const closeBtn = $('.closeButton_adventCPcVB1');
        if(closeBtn){
            closeBtn.off('click').on('click', (e) => {
                Cookies.set('calendar-show', false, {expires: 1, secure: true});
                closeModal(e);
            })
        }
        // -- WHEN ANIMATION'S OVER
        const shareBtn = $('.buttons_adventCalendar > #shareGift');
        if(shareBtn){
            shareBtn.off('click').on('click', () => {
                navigate('/add');
                $('#adventCalendar').trigger('click')
            })
        }

        const dontShowBtn = $('.askButton_adventCPcVB1');
        if(dontShowBtn){
            dontShowBtn.off('click').on('click', (e) => {
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/christmas/${userInfo.uid}/calendar/${showBtn ? 'hide': 'show'}`,
                    undefined,
                    {
                        headers: { "Content-Type": "application/json" },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                ).then(result => {
                    window.location.reload();
                })
            })
        }
    }, [userInfo, loading])

    return (
    <><aside id="adventCalendar" className="modal" aria-hidden={!show ? 'true': "false"} aria-modal={!show ? 'false': "true"} style={{display: !show ? 'none': "flex"}}>
        <div className="modal-wrapper newColors js-modal-stop" style={{padding: 0, width: 'fit-content', height: 'auto'}}>
            <div className="topPart_fSLMPcB1" style={{ alignItems: "baseline", justifyContent:'center', height: 'auto', border: 'none' }}>
                <h1 style={{ margin: 0, marginTop: 30, fontSize: 24, marginBottom: 20 }}>{t('special.christmas.adventCalendar.title')}</h1>
            </div>
            {
                !loading ? (<>
                <div className="giftsContainer_adventCPcVB1">
                    {Array.from({ length: 12 }, (_, index) => {
                        const verifClaimed = claimed.map(claimedGifts => {
                            return claimedGifts.case_day;
                        });

                        const currentDay = new Date();

                        const isDisabled = () => {
                            const caseDay = index * 2 + 1;
                            const caseDate = new Date(currentDay);
                                  caseDate.setDate(caseDay);
                            
                            let nextActiveDate = null;
                            for (let i = index + 1; i < associateDates.length; i++) {
                                if (associateDates[i] > caseDate) {
                                nextActiveDate = associateDates[i];
                                break;
                                }
                            }

                            const isActive = (currentDay >= caseDate && (!nextActiveDate || currentDay < nextActiveDate));
                            return !isActive;
                        };
                
                        return (
                            <div key={index} className={`gift_gCPcVB1 ${verifClaimed.includes(index) ? 'open': 'close'} giftIndex_${index} ${(isDisabled() || verifClaimed.includes(index)) ? 'darker': 'light'}`} data-index={index}>
                                <div className="giftClaimed">
                                    <svg width="80" height="50" viewBox="0 0 120 50" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="10" y="10" width="100" height="30" rx="5" ry="5" fill="none" stroke="#FF6565" strokeWidth="2" transform="rotate(-15 60,27)"/>
                                        <text x="62" y="23" textAnchor="middle" fontSize="18" fill="#FF6565" fontWeight="bold" transform="rotate(-16 90,27)">{t('special.christmas.adventCalendar.claimed')}</text>
                                    </svg>
                                </div>
                                <svg width={110} height={101} viewBox="-5 -12 120 111" fill="none" xmlns="http://www.w3.org/2000/svg" id="gift">
                                    <path className="body" fillRule="evenodd" clipRule="evenodd" d="M63 100.07H105V54.5696H63V100.07ZM47 54.5696H5V100.07H47V54.5696Z" fill="#FF6565"/>
                                    <path className="yellow" d="M47 100.07H63V54.5696H47V100.07Z" fill="#EBDD75"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M65 48.0696H105V54.5696H63V48.0696H65ZM47 48.0696H45H5V54.5696H47V48.0696Z" fill="#FF6565"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M65 48.0696H105V54.5696H63V48.0696H65ZM47 48.0696H45H5V54.5696H47V48.0696Z" fill="black" fillOpacity="0.25"/>
                                    <path d="M63 48.0696H47V54.5696H63V48.0696Z" fill="#EBDD75"/>
                                    <path d="M63 48.0696H47V54.5696H63V48.0696Z" fill="black" fillOpacity="0.1"/>
                                    <path className="lid" fillRule="evenodd" clipRule="evenodd" d="M96 23.0696H110V48.0696H105H65V23.0696H87.5H96ZM45 23.0696H0V48.0696H5H45V23.0696Z" fill="#FF6565"/>
                                    <path className="lid" d="M65 23.0696H45V48.0696H47H63H65V23.0696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M65 14.0696H45C45 14.0696 46 16.7694 46 18.5696C46 20.3699 45 23.0696 45 23.0696C45 23.0696 51.0753 22.0696 55 22.0696C58.9247 22.0696 65 23.0696 65 23.0696C65 23.0696 64 20.6817 64 18.5696C64 16.4575 65 14.0696 65 14.0696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M64 18.5696C64 20.6817 65 23.0696 65 23.0696V14.0696C65 14.0696 64 16.4575 64 18.5696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M64 18.5696C64 20.6817 65 23.0696 65 23.0696V14.0696C65 14.0696 64 16.4575 64 18.5696Z" fill="black" fillOpacity="0.1"/>
                                    <path className="lid" d="M45 23.0696H65C65 23.0696 58.9247 22.0696 55 22.0696C51.0753 22.0696 45 23.0696 45 23.0696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M45 23.0696H65C65 23.0696 58.9247 22.0696 55 22.0696C51.0753 22.0696 45 23.0696 45 23.0696Z" fill="black" fillOpacity="0.1"/>
                                    <path className="lid" d="M45 23.0696C45 23.0696 46 20.3699 46 18.5696C46 16.7694 45 14.0696 45 14.0696V23.0696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M45 23.0696C45 23.0696 46 20.3699 46 18.5696C46 16.7694 45 14.0696 45 14.0696V23.0696Z" fill="black" fillOpacity="0.1"/>
                                    <path className="lid" d="M105 14.0696C102.799 8.0729 94.2997 3.56157 87.5 6.56961C85.507 7.45124 82 9.86962 82 9.86962C88.8055 11.6935 91.798 14.1683 87.5 23.0696H96C99 23.0696 106.336 18.0756 105 14.0696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M105 14.0696C102.799 8.0729 94.2997 3.56157 87.5 6.56961C85.507 7.45124 82 9.86962 82 9.86962C88.8055 11.6935 91.798 14.1683 87.5 23.0696H96C99 23.0696 106.336 18.0756 105 14.0696Z" fill="black" fillOpacity="0.1"/>
                                    <path className="lid" d="M14 23.0696H22.5C18.202 14.1683 21.1945 11.6935 28 9.86962C28 9.86962 24.493 7.45126 22.5 6.56962C15.7003 3.56158 7.20129 8.07291 5 14.0696C3.66361 18.0756 11 23.0696 14 23.0696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M14 23.0696H22.5C18.202 14.1683 21.1945 11.6935 28 9.86962C28 9.86962 24.493 7.45126 22.5 6.56962C15.7003 3.56158 7.20129 8.07291 5 14.0696C3.66361 18.0756 11 23.0696 14 23.0696Z" fill="black" fillOpacity="0.1"/>
                                    <path className="lid" d="M105 14.0696C102.086 5.81703 96.191 1.10116 87.5 0.06959C77.2232 -1.1502 65 14.0696 65 14.0696V23.0696L82 9.86962C82 9.86962 85.507 7.45124 87.5 6.56961C94.2997 3.56157 102.799 8.0729 105 14.0696Z" fill="#EBDD75"/>
                                    <path className="lid" d="M22.5 0.0696244C13.809 1.1012 7.9142 5.81703 5 14.0696C7.20129 8.07291 15.7003 3.56158 22.5 6.56962C24.493 7.45126 28 9.86962 28 9.86962L45 23.0696V14.0696C45 14.0696 32.7768 -1.15017 22.5 0.0696244Z" fill="#EBDD75"/>
                                </svg>
                                <div className="circle">
                                    <span>{index + 1}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="actionsButtons_adventPcVB1">
                    <div className="askButton_adventCPcVB1" style={{marginRight: 10}}>
                        <span>{showBtn ? t('special.christmas.adventCalendar.dontShow'): t('special.christmas.adventCalendar.show')}</span>
                    </div>
                    <div className="closeButton_adventCPcVB1">
                        <span>{t('special.christmas.adventCalendar.close')}</span>
                        <i className="fi fi-rr-cross-circle"></i>
                    </div>
                </div>
                <div className="animateGift_adventCalendar">
                    <div className="gift">
                        <span style={{marginTop: 15, marginBottom: 10}}>Gift</span>
                        <img title="Gift claimed" alt="gift" style={{width: '100%', padding: 10, background: "#CFCFCF", borderRadius: 25}} />
                    </div>
                    <svg width={305} height={210} viewBox="-45 -15 150 115" fill="none" xmlns="http://www.w3.org/2000/svg" id="gift" style={{marginLeft: -95}}>
                        <path fillRule="evenodd" clipRule="evenodd" d="M63 100.07H105V54.5696H63V100.07ZM47 54.5696H5V100.07H47V54.5696Z" fill="#FF6565"></path>
                        <path d="M47 100.07H63V54.5696H47V100.07Z" fill="#EBDD75"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M65 48.0696H105V54.5696H63V48.0696H65ZM47 48.0696H45H5V54.5696H47V48.0696Z" fill="#FF6565"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M65 48.0696H105V54.5696H63V48.0696H65ZM47 48.0696H45H5V54.5696H47V48.0696Z" fill="black" fillOpacity="0.25"></path>
                        <path d="M63 48.0696H47V54.5696H63V48.0696Z" fill="#EBDD75"></path>
                        <path d="M63 48.0696H47V54.5696H63V48.0696Z" fill="black" fillOpacity="0.1"></path>
                        <path className="lid" fillRule="evenodd" clipRule="evenodd" d="M96 23.0696H110V48.0696H105H65V23.0696H87.5H96ZM45 23.0696H0V48.0696H5H45V23.0696Z" fill="#FF6565"></path>
                        <path className="lid" d="M65 23.0696H45V48.0696H47H63H65V23.0696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M65 14.0696H45C45 14.0696 46 16.7694 46 18.5696C46 20.3699 45 23.0696 45 23.0696C45 23.0696 51.0753 22.0696 55 22.0696C58.9247 22.0696 65 23.0696 65 23.0696C65 23.0696 64 20.6817 64 18.5696C64 16.4575 65 14.0696 65 14.0696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M64 18.5696C64 20.6817 65 23.0696 65 23.0696V14.0696C65 14.0696 64 16.4575 64 18.5696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M64 18.5696C64 20.6817 65 23.0696 65 23.0696V14.0696C65 14.0696 64 16.4575 64 18.5696Z" fill="black" fillOpacity="0.1"></path>
                        <path className="lid" d="M45 23.0696H65C65 23.0696 58.9247 22.0696 55 22.0696C51.0753 22.0696 45 23.0696 45 23.0696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M45 23.0696H65C65 23.0696 58.9247 22.0696 55 22.0696C51.0753 22.0696 45 23.0696 45 23.0696Z" fill="black" fillOpacity="0.1"></path>
                        <path className="lid" d="M45 23.0696C45 23.0696 46 20.3699 46 18.5696C46 16.7694 45 14.0696 45 14.0696V23.0696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M45 23.0696C45 23.0696 46 20.3699 46 18.5696C46 16.7694 45 14.0696 45 14.0696V23.0696Z" fill="black" fillOpacity="0.1"></path>
                        <path className="lid" d="M105 14.0696C102.799 8.0729 94.2997 3.56157 87.5 6.56961C85.507 7.45124 82 9.86962 82 9.86962C88.8055 11.6935 91.798 14.1683 87.5 23.0696H96C99 23.0696 106.336 18.0756 105 14.0696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M105 14.0696C102.799 8.0729 94.2997 3.56157 87.5 6.56961C85.507 7.45124 82 9.86962 82 9.86962C88.8055 11.6935 91.798 14.1683 87.5 23.0696H96C99 23.0696 106.336 18.0756 105 14.0696Z" fill="black" fillOpacity="0.1"></path>
                        <path className="lid" d="M14 23.0696H22.5C18.202 14.1683 21.1945 11.6935 28 9.86962C28 9.86962 24.493 7.45126 22.5 6.56962C15.7003 3.56158 7.20129 8.07291 5 14.0696C3.66361 18.0756 11 23.0696 14 23.0696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M14 23.0696H22.5C18.202 14.1683 21.1945 11.6935 28 9.86962C28 9.86962 24.493 7.45126 22.5 6.56962C15.7003 3.56158 7.20129 8.07291 5 14.0696C3.66361 18.0756 11 23.0696 14 23.0696Z" fill="black" fillOpacity="0.1"></path>
                        <path className="lid" d="M105 14.0696C102.086 5.81703 96.191 1.10116 87.5 0.06959C77.2232 -1.1502 65 14.0696 65 14.0696V23.0696L82 9.86962C82 9.86962 85.507 7.45124 87.5 6.56961C94.2997 3.56157 102.799 8.0729 105 14.0696Z" fill="#EBDD75"></path>
                        <path className="lid" d="M22.5 0.0696244C13.809 1.1012 7.9142 5.81703 5 14.0696C7.20129 8.07291 15.7003 3.56158 22.5 6.56962C24.493 7.45126 28 9.86962 28 9.86962L45 23.0696V14.0696C45 14.0696 32.7768 -1.15017 22.5 0.0696244Z" fill="#EBDD75"></path>
                    </svg>
                    <div className="buttons_adventCalendar">
                        <div id="shareGift">
                            <svg width="183" height="65" viewBox="0 0 183 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="2" y="10" width="175" height="50" rx="20" fill="url(#paint0_linear_1906_176)"/>
                                <path d="M167 58L164 65L161.5 58L154 55L161.5 52L164 45L167 52L174.5 55L167 58Z" fill="url(#paint1_linear_1906_176)"/>
                                <path d="M20 13L17 20L14.5 13L7 10L14.5 7L17 0L20 7L27.5 10L20 13Z" fill="url(#paint2_linear_1906_176)"/>
                                <path d="M6.65854 22.5L5.12195 26L3.84146 22.5L0 21L3.84146 19.5L5.12195 16L6.65854 19.5L10.5 21L6.65854 22.5Z" fill="url(#paint3_linear_1906_176)"/>
                                <path d="M178.659 46.5L177.122 50L175.841 46.5L172 45L175.841 43.5L177.122 40L178.659 43.5L182.5 45L178.659 46.5Z" fill="url(#paint4_linear_1906_176)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1906_176" x1="146.5" y1="10" x2="83" y2="96.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#B1FCAD"/>
                                        <stop offset="0.5" stopColor="#6DBB68"/>
                                        <stop offset="1" stopColor="#257021"/>
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_1906_176" x1="164" y1="47" x2="164" y2="68.5" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FDFFB7"/>
                                        <stop offset="0.5" stopColor="#E4E88E"/>
                                        <stop offset="1" stopColor="#6C6D47"/>
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_1906_176" x1="27" y1="10" x2="5" y2="18" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#FDFFC5"/>
                                        <stop offset="0.5" stopColor="#DCDF8B"/>
                                        <stop offset="1" stopColor="#6C6D47"/>
                                    </linearGradient>
                                    <linearGradient id="paint3_linear_1906_176" x1="8.96341" y1="17" x2="1.70939" y2="24.1747" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#C692EE"/>
                                        <stop offset="0.5" stopColor="#B37EDA"/>
                                        <stop offset="1" stopColor="#764C95"/>
                                    </linearGradient>
                                    <linearGradient id="paint4_linear_1906_176" x1="174.5" y1="41" x2="177.215" y2="49.9346" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#E8C9FF"/>
                                        <stop offset="0.5" stopColor="#B37EDA"/>
                                        <stop offset="1" stopColor="#764C95"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span style={{marginTop: 4}}>{t('special.christmas.adventCalendar.buttons.share')}</span>
                        </div>
                        <div id="continue" style={{marginRight: 0}}>
                            <svg width="150" height="50" viewBox="0 0 150 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="150" height="50" rx="20" fill="url(#paint0_linear_1906_171)"/>
                                <defs>
                                <linearGradient id="paint0_linear_1906_171" x1="50.1429" y1="5.5" x2="57.1127" y2="59.7653" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FE9C9C"/>
                                    <stop offset="0.5" stopColor="#FF6565"/>
                                    <stop offset="1" stopColor="#6D2323"/>
                                </linearGradient>
                                </defs>
                            </svg>
                            <span>{t('special.christmas.adventCalendar.buttons.continue')}</span>
                        </div>
                    </div>
                </div>
                </>): (
                    <div className="giftsContainer_adventCPcVB1">
                        {Array.from({ length: 12 }, (_, index) => {                    
                            return (
                                <div key={index} className={`gift_gCPcVB1 giftIndex_${index} darker`} data-index={index}>
                                    <svg width={110} height={101} viewBox="-5 -12 120 111" fill="none" xmlns="http://www.w3.org/2000/svg" id="gift">
                                        <path className="body" fillRule="evenodd" clipRule="evenodd" d="M63 100.07H105V54.5696H63V100.07ZM47 54.5696H5V100.07H47V54.5696Z" fill="#FF6565"/>
                                        <path className="yellow" d="M47 100.07H63V54.5696H47V100.07Z" fill="#EBDD75"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M65 48.0696H105V54.5696H63V48.0696H65ZM47 48.0696H45H5V54.5696H47V48.0696Z" fill="#FF6565"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M65 48.0696H105V54.5696H63V48.0696H65ZM47 48.0696H45H5V54.5696H47V48.0696Z" fill="black" fillOpacity="0.25"/>
                                        <path d="M63 48.0696H47V54.5696H63V48.0696Z" fill="#EBDD75"/>
                                        <path d="M63 48.0696H47V54.5696H63V48.0696Z" fill="black" fillOpacity="0.1"/>
                                        <path className="lid" fillRule="evenodd" clipRule="evenodd" d="M96 23.0696H110V48.0696H105H65V23.0696H87.5H96ZM45 23.0696H0V48.0696H5H45V23.0696Z" fill="#FF6565"/>
                                        <path className="lid" d="M65 23.0696H45V48.0696H47H63H65V23.0696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M65 14.0696H45C45 14.0696 46 16.7694 46 18.5696C46 20.3699 45 23.0696 45 23.0696C45 23.0696 51.0753 22.0696 55 22.0696C58.9247 22.0696 65 23.0696 65 23.0696C65 23.0696 64 20.6817 64 18.5696C64 16.4575 65 14.0696 65 14.0696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M64 18.5696C64 20.6817 65 23.0696 65 23.0696V14.0696C65 14.0696 64 16.4575 64 18.5696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M64 18.5696C64 20.6817 65 23.0696 65 23.0696V14.0696C65 14.0696 64 16.4575 64 18.5696Z" fill="black" fillOpacity="0.1"/>
                                        <path className="lid" d="M45 23.0696H65C65 23.0696 58.9247 22.0696 55 22.0696C51.0753 22.0696 45 23.0696 45 23.0696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M45 23.0696H65C65 23.0696 58.9247 22.0696 55 22.0696C51.0753 22.0696 45 23.0696 45 23.0696Z" fill="black" fillOpacity="0.1"/>
                                        <path className="lid" d="M45 23.0696C45 23.0696 46 20.3699 46 18.5696C46 16.7694 45 14.0696 45 14.0696V23.0696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M45 23.0696C45 23.0696 46 20.3699 46 18.5696C46 16.7694 45 14.0696 45 14.0696V23.0696Z" fill="black" fillOpacity="0.1"/>
                                        <path className="lid" d="M105 14.0696C102.799 8.0729 94.2997 3.56157 87.5 6.56961C85.507 7.45124 82 9.86962 82 9.86962C88.8055 11.6935 91.798 14.1683 87.5 23.0696H96C99 23.0696 106.336 18.0756 105 14.0696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M105 14.0696C102.799 8.0729 94.2997 3.56157 87.5 6.56961C85.507 7.45124 82 9.86962 82 9.86962C88.8055 11.6935 91.798 14.1683 87.5 23.0696H96C99 23.0696 106.336 18.0756 105 14.0696Z" fill="black" fillOpacity="0.1"/>
                                        <path className="lid" d="M14 23.0696H22.5C18.202 14.1683 21.1945 11.6935 28 9.86962C28 9.86962 24.493 7.45126 22.5 6.56962C15.7003 3.56158 7.20129 8.07291 5 14.0696C3.66361 18.0756 11 23.0696 14 23.0696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M14 23.0696H22.5C18.202 14.1683 21.1945 11.6935 28 9.86962C28 9.86962 24.493 7.45126 22.5 6.56962C15.7003 3.56158 7.20129 8.07291 5 14.0696C3.66361 18.0756 11 23.0696 14 23.0696Z" fill="black" fillOpacity="0.1"/>
                                        <path className="lid" d="M105 14.0696C102.086 5.81703 96.191 1.10116 87.5 0.06959C77.2232 -1.1502 65 14.0696 65 14.0696V23.0696L82 9.86962C82 9.86962 85.507 7.45124 87.5 6.56961C94.2997 3.56157 102.799 8.0729 105 14.0696Z" fill="#EBDD75"/>
                                        <path className="lid" d="M22.5 0.0696244C13.809 1.1012 7.9142 5.81703 5 14.0696C7.20129 8.07291 15.7003 3.56158 22.5 6.56962C24.493 7.45126 28 9.86962 28 9.86962L45 23.0696V14.0696C45 14.0696 32.7768 -1.15017 22.5 0.0696244Z" fill="#EBDD75"/>
                                    </svg>
                                    <div className="circle">
                                        <span>{index + 1}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )
            }
        </div>
    </aside>
    <div className="animateLight"></div></>
    )
}

export default AdventCalendar;