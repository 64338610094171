import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from '../../Functions';
import { openModal } from '../../Functions/Modal';

const GroupSettings = ({ user, groupInfos, pKey, theme }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set data
    const [name, setName] = useState('');
    const [picture, setPicture] = useState('');
    const [error, setError] = useState('');

    const editGroup = () => {
        if(name !== '' && picture !== ''){
            if(name !== groupInfos.name && picture !== groupInfos.picture){
                // Set loading
                $("#groupSettings .mainPart_fSLMPcB1 .error").css('color', theme === "darkTheme" ? "#9A9A9A": "#707070");
                setError(t('groups.settings.loading'));

                // Create file object
                var fileObject = fetch(picture.preview) 
                .then(r => r.blob())
                .then(blob => {
                    fileObject = new File([blob], picture.name, {type: picture.type});
                    // ...
                    const formData = new FormData();
                    formData.append("image", fileObject, picture.name);
                    // UPLOAD
                    axios.post(`https://api.snot.fr/v${API_VERSION}/upload/groups`, formData, {
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: user.uid,
                            app: 'web'
                        }
                    })
                })

                // SEND CHANGES
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/group/both/edit/${pKey}`,
                    { value: { name: name, picture: picture }},
                    {
                        headers: { "Content-Type": "application/json" },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: user.uid,
                            app: 'web'
                        }
                    }
                ).then(response => {
                    if (response.data.success) {
                        $('#groupSettings').trigger('click'); // Close modal
                    }
                }).catch(err => {
                    $('#groupSettings .mainPart_fSLMPcB1 .error').css('color', 'rgb(255, 99, 99)');
                    setError('Failed to edit the group.');
                });
            }else{
                $('#groupSettings').trigger('click');
            }
        }
        else if(name !== '' && picture === ''){
            if(name !== groupInfos.name){
                // Set loading
                $("#groupSettings .mainPart_fSLMPcB1 .error").css('color', theme === "darkTheme" ? "#9A9A9A": "#707070");
                setError(t('groups.settings.loading'));

                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/group/name/edit/${pKey}`,
                    { value: name },
                    {
                        headers: { "Content-Type": "application/json" },
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: user.uid,
                            app: 'web'
                        }
                    }
                ).then(response => {
                    if (response.data.success) {
                        $('#groupSettings').trigger('click'); // Close modal
                    }
                }).catch(err => {
                    $('#groupSettings .mainPart_fSLMPcB1 .error').css('color', 'rgb(255, 99, 99)');
                    setError('Failed to edit the group.');
                });
            }else{
                $('#groupSettings').trigger('click');
            }
        }
        else if(picture !== '' && name === ''){
            if(picture !== groupInfos.picture){
                // Set loading
                $("#groupSettings .mainPart_fSLMPcB1 .error").css('color', theme === "darkTheme" ? "#9A9A9A": "#707070");
                setError(t('groups.settings.loading'));

                // Create file object
                var fileObject = fetch(picture.preview) 
                .then(r => r.blob())
                .then(blob => {
                    fileObject = new File([blob], picture.name, {type: picture.type});
                    // ...
                    const formData = new FormData();
                    formData.append("image", fileObject, picture.name);
                    // UPLOAD
                    axios.post(`https://api.snot.fr/v${API_VERSION}/upload/groups`, formData, {
                        params: {
                            token: Cookies.get('loginToken'),
                            uid: user.uid,
                            app: 'web'
                        }
                    })
                    .then(res => {
                        if(res.data.message === "SUCCESS_UPLOAD"){
                            axios.put(
                                `https://api.snot.fr/v${API_VERSION}/group/picture/edit/${pKey}`,
                                { value: 'https://api.snot.fr/v1/group/picture/'+pKey },
                                {
                                    headers: { "Content-Type": "application/json" },
                                    params: {
                                        token: Cookies.get('loginToken'),
                                        uid: user.uid,
                                        app: 'web'
                                    }
                                }
                            ).then(response => {
                                if (response.data.success) {
                                    $('#groupSettings').trigger('click'); // Close modal
                                }
                            }).catch(err => {
                                $('#groupSettings .mainPart_fSLMPcB1 .error').css('color', 'rgb(255, 99, 99)');
                                setError('Failed to edit the group.');
                            });
                        }
                    })
                })
            }else{
                $('#groupSettings').trigger('click');
            }
        }else{
            $('#groupSettings .mainPart_fSLMPcB1 .error').css('color', 'rgb(255, 99, 99)');
            setError(t('groups.settings.errorEdit'));
        }
    };

    // -- Add picture
    const fileTypes = [
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
        "image/gif",
    ];

    function validFileType(file) {
        return fileTypes.includes(file.type);
    }

    const filePreview = (e) => {
        if(window.File && window.FileList && window.FileReader) {
            var file = e.target.files[0];
            var groupPicture = document.querySelector('.addPicture_gPcVB1');
    
            if(validFileType(file)){
                if (file.size <= 6291456) {
                    var preview = URL.createObjectURL(file);
                    setPicture({type: 'image/webp', name: pKey+".webp", preview: preview});
                    // UI
                    $('#addIcon').css('display', 'none');
                    $('#pictureIcon').css('display', 'none');
                    var pic = document.getElementById('previewPic');
                        pic.src = preview;
                        pic.style.display = "flex";
                        pic.style.background = theme === "darkTheme" ? "#101010": "#E3E3E3";
                    // -- -- Append 'img'
                    groupPicture.appendChild(pic, null);
                }else {
                    setError('Your picture can\'t exceed 10MB.');
                }     
            }else{
                setError('You must upload an image.');
            }
        }else {
            console.log("Your browser does not support File API");
        }
    }

    useEffect(() => {
        // EVENTS
        $('.addPicture_gPcVB1 #picture').off('change').on('change', filePreview);
    }, [])

    useEffect(() => {
        $('.actions_gPcVB1 #create.edit').off('click').on('click', editGroup);
        $('.deleteGroup_gPcVB1 #deleteButton').off('click').on('click', openModal);

        // EVENT FOR DELETE
        $('#confirmDeleteGroup .button_confirmMVB1.deleteGroup').off('click').on('click', () => {
            axios.delete(
                `https://api.snot.fr/v${API_VERSION}/group/delete/${pKey}`,
                {
                    headers: { "Content-Type": 'application/json' },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: user.uid,
                        app: 'web'
                    }
                }
            ).then((deleted) => {
                if(deleted.data.success){
                    navigate('/friends');
                }else{
                    setError('An error occured while deleting the group.');
                }
            })
        })
    }, [name, picture, error])

    return (
        <>
        <aside id="groupSettings" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div id='scrollableFriends' className="modal-wrapper js-modal-stop" style={{backgroundColor: theme === "darkTheme" ? "#101010": "#E3E3E3",color: theme === "darkTheme" ? "#fff": "#000",padding: 25,width: 400}}>
                <div className="topPart_groupPcVB1">
                    <h1>{t('groups.settings.title')}</h1>
                    <h2>
                    {t('groups.settings.subtitle')}
                    </h2>
                </div>
                <div className="mainPart_fSLMPcB1" style={{marginTop: 25}}>
                    <p className='error' style={{ margin: 0, color: 'rgb(255, 99, 99)', fontSize: 14, marginBottom: error !== '' && 15 }}>{error}</p>
                    <form method='POST' encType="multipart/form-data" style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                        <label className="addPicture_gPcVB1" tabIndex={1} htmlFor="picture">
                            {
                                (groupInfos.picture === "" || groupInfos.picture === null) &&
                                <>
                                    <div id="addIcon">
                                        <i className="fi fi-rr-plus"></i>
                                    </div>
                                    <i id="pictureIcon" className="fi fi-rr-picture"></i>
                                </>
                            }
                            <input id="picture" name="picture" type="file" accept="image/*" style={{ display: 'none' }} />
                            <img id='previewPic' alt={t('groups.create.modal.customPart.picture')} title={t('groups.create.modal.customPart.picture')} {...((groupInfos.picture !== "" || groupInfos.picture !== null) && {src: groupInfos.picture})} referrerPolicy="no-referrer" style={{display: (groupInfos.picture === "" || groupInfos.picture === null) ? 'none': 'block'}} />
                        </label>
                        <div className="groupName_gPcVB1">
                            <label>{t('groups.create.modal.customPart.name')}</label>
                            <input type="text" name="name" id="name" autoComplete="name" placeholder="TeamA" maxLength={25} minLength={3} {...(groupInfos.name !== "" && {defaultValue: groupInfos.name})} tabIndex={2}
                                onChange={(e) => {
                                    var nameValue = e.currentTarget.value.trim();
                                    // MIN LENGTH
                                    if(nameValue.length > 0 && nameValue.length < 3){
                                        setError(t('groups.create.modal.errors.name.min'));
                                    }
                                    // MAX LENGTH
                                    else if(nameValue.length > 25){
                                        setError(t('groups.create.modal.errors.name.max'));
                                    }
                                    // NORMAL LENGTH
                                    else {
                                        setError('');
                                        setName(DOMPurify.sanitize(nameValue, {USE_PROFILES: {html: false, svg: false, mathMl: false}}));
                                    }
                                }} />
                        </div>
                    </form>
                    <div className='deleteGroup_gPcVB1'>
                        <p style={{ fontSize: 14 }}>{t('groups.delete.title')}</p>
                        <div id='deleteButton' data-modal="confirmDeleteGroup">
                            <i className='fi fi-rr-trash' data-modal="confirmDeleteGroup"></i>
                            <span data-modal="confirmDeleteGroup">{t('groups.delete.button')}</span>
                        </div>
                    </div>
                    <div className="actions_gPcVB1">
                        <div id="cancel" className='js-modal-close-other' tabIndex={4}>
                            <i className="fi fi-rr-arrow-left"></i>
                            <span>{t('groups.modals.goBack')}</span>
                        </div>
                        <div id="create" className='edit' tabIndex={3}>
                            <i className="fi fi-rr-check"></i>
                            <span>{t('groups.settings.button')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        <aside id="confirmDeleteGroup" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div className="modal-wrapper js-modal-stop" style={{ width: 400 }}>
                <button className="js-modal-close" style={{display: "flex",justifyContent:"center",alignItems:"center",padding:10,position:"absolute",right:20}}>
                    <i className="fi fi-rr-cross"></i>
                </button>
                <div className={"confirm remove "+theme}>
                    <div id="logoTop">
                        <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                    </div>
                    <h1 id="title" style={{ width: "100%" }}>{t('groups.delete.modal.title')}</h1>
                    <p id="subtitle" style={{ width: "100%", padding: "0 25px" }}>{t('groups.delete.modal.subtitle')}</p>
                    <div id="actionsButton" style={{ width: "100%" }}>
                        <div className="button_returnMVB1 js-modal-close-other"><span>{t('general.buttons.no')}</span></div>
                        <div className="button_confirmMVB1 deleteGroup"><span>{t('groups.delete.modal.button')}</span></div>
                    </div>
                </div>
            </div>
        </aside>
        </>
    )
}

export default GroupSettings;