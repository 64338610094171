import axios from "axios";
import Cookies from "js-cookie";
// API VERSION
import API_VERSION from "./ApiVersion";

const retreiveUserInfos = (user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/user/${user}`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            resolve(response.data.infos);
        }).catch((error) => {
            reject(error);
        })
    })
}

// Retreive followers & subs
// -- FOLLOWERS
const getFollowersCount = (user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/user/${user}/followers`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            resolve(response.data.followers);
        }).catch((error) => resolve(0))
    })
}

const getFollowers = (user, myUID, initReq, initLimit) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/user/${user}/followers/list`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                token: Cookies.get('loginToken'),
                uid: myUID,
                app: 'web',
                req: initReq,
                limit: initLimit
            }
        }).then((response) => {
            resolve(response.data.followers);
        }).catch((error) => resolve(0))
    })
}
// -- SUBS
const getSubscriptionsCount = (user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/user/${user}/subs`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            resolve(response.data.subs);
        }).catch((error) => {
            reject(error);
        })
    })
}

const getSubscriptions = (user, myUID, initReq, initLimit) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/user/${user}/subs/list`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                token: Cookies.get('loginToken'),
                uid: myUID,
                app: 'web',
                req: initReq,
                limit: initLimit
            }
        }).then((response) => {
            resolve(response.data.subs);
        }).catch((error) => resolve(0))
    })
}

// Verify if [user] follow, is friend with, ... [user 2]
const verifyTypeUser = (type, user, creator) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `https://api.snot.fr/v${API_VERSION}/verify/${type}`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                token: Cookies.get('loginToken'),
                app: 'web'
            },
            data: { user: user, creator: creator }
        }).then((response) => {
            resolve(response.data.verified);
        }).catch((error) => {
            reject(error);
        })
    })
}

export {
    retreiveUserInfos,
    getFollowersCount,
    getFollowers,
    getSubscriptionsCount,
    getSubscriptions,
    verifyTypeUser
}