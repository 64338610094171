import { useEffect } from 'react';
import $ from 'jquery';
// import Images
// -- Specials
    // -- -- Bears
    import bearBauble from '../../../img/emojis/specials/xmas/bears/bear_bauble.webp';
    import bearRun from '../../../img/emojis/specials/xmas/bears/bear_run.webp';
    import bearSurprise from '../../../img/emojis/specials/xmas/bears/bear_surprise.webp';
    import bearGift from '../../../img/emojis/specials/xmas/bears/bear_gift.webp';
    import bearHeart from '../../../img/emojis/specials/xmas/bears/bear_heart.png';
    import bear1 from '../../../img/emojis/specials/xmas/bears/bear_1.webp';
    // -- -- Dogs
    import dogDisguised from '../../../img/emojis/specials/xmas/dogs/dog_disguised.png';
    import dogSnowman from '../../../img/emojis/specials/xmas/dogs/dog_snowman.png';
    import dogSanta from '../../../img/emojis/specials/xmas/dogs/dog_santa.png';
    import dogGift from '../../../img/emojis/specials/xmas/dogs/dog_gift.png';
    import dogElf from '../../../img/emojis/specials/xmas/dogs/dog_elf.png';
    import dogReindeer from '../../../img/emojis/specials/xmas/dogs/dog_reindeer.png';
    // -- -- Polar bear
    import pBear from '../../../img/emojis/specials/polar_bear/pbear.png';
    import pBearSolo from '../../../img/emojis/specials/polar_bear/pbear_solo.png';
    import pBearTired from '../../../img/emojis/specials/polar_bear/pbear_tired.png';
    import pBearPlaying from '../../../img/emojis/specials/polar_bear/pbear_playing.png';
    import pBearSweating from '../../../img/emojis/specials/polar_bear/pbear_sweating.png';
    import pBearPinguin from '../../../img/emojis/specials/polar_bear/pbear_pinguin.png';

/*export default EmojiPicker = () => {
    return (
        <>
        <aside id="emojiMenu" className={"menu "+theme} aria-hidden="true" aria-modal="false" style="display:none;">
            <div className="menu-wrapper js-menu-stop" style={{width:485,maxWidth: 'none',maxHeight:450,right:10,bottom:90}}>
                <div className="emoji-picker">
                    <div className={"emoji-selectables "+theme}>
                        <span className="picker-emoji-sel emoji-smileys active" data-emoji="smileys"><img draggable="false" className="emoji" alt=":grinning:" src="/twemoji/assets/svg/1f600.svg" /></span>
                        <span className="picker-emoji-sel emoji-nature" data-emoji="nature"><img draggable="false" className="emoji" alt=":four_leaf_clover:" src="/twemoji/assets/svg/1f340.svg" /></span>
                        <span className="picker-emoji-sel emoji-food" data-emoji="food"><img draggable="false" className="emoji" alt=":banana:" src="/twemoji/assets/svg/1f34c.svg" /></span>
                        <span className="picker-emoji-sel emoji-activity" data-emoji="activity"><img draggable="false" className="emoji" alt=":basketball:" src="/twemoji/assets/svg/1f3c0.svg" /></span>
                        <span className="picker-emoji-sel emoji-travel" data-emoji="travel"><img draggable="false" className="emoji" alt=":airplane:" src="/twemoji/assets/svg/2708.svg" /></span>
                        <span className="picker-emoji-sel emoji-symbos" data-emoji="symbos"><img draggable="false" className="emoji" alt=":heart:" src="/twemoji/assets/svg/2764.svg" /></span>
                    </div>
                    <div className="emoji-content">
                        <div id="emoji-smileys" className="picker-emoji picker-emoji-content emoji-smileys active">
                            <span><img draggable="false" className="emoji" alt=":grinning:" src="/twemoji/assets/svg/1f600.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smiley:" src="/twemoji/assets/svg/1f603.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smile:" src="/twemoji/assets/svg/1f604.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":grin:" src="/twemoji/assets/svg/1f601.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":satisfied:" src="/twemoji/assets/svg/1f606.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_holding_back_tears:" src="/twemoji/assets/svg/1f979.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sweat_smile:" src="/twemoji/assets/svg/1f605.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":joy:" src="/twemoji/assets/svg/1f602.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rolling_on_the_floor_laughing:" src="/twemoji/assets/svg/1f923.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smiling_face_with_tear:" src="/twemoji/assets/svg/1f972.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":relaxed:" src="/twemoji/assets/svg/263a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blush:" src="/twemoji/assets/svg/1f60a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":innocent:" src="/twemoji/assets/svg/1f607.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":slightly_smiling_face:" src="/twemoji/assets/svg/1f642.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":upside_down_face:" src="/twemoji/assets/svg/1f643.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wink:" src="/twemoji/assets/svg/1f609.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":relieved:" src="/twemoji/assets/svg/1f60c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heart_eyes:" src="/twemoji/assets/svg/1f60d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smiling_face_with_hearts:" src="/twemoji/assets/svg/1f970.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kissing_heart:" src="/twemoji/assets/svg/1f618.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kissing:" src="/twemoji/assets/svg/1f617.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kissing_smiling_eyes:" src="/twemoji/assets/svg/1f619.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kissing_closed_eyes:" src="/twemoji/assets/svg/1f61a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":yum:" src="/twemoji/assets/svg/1f60b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stuck_out_tongue:" src="/twemoji/assets/svg/1f61b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stuck_out_tongue_closed_eyes:" src="/twemoji/assets/svg/1f61d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stuck_out_tongue_winking_eyes:" src="/twemoji/assets/svg/1f61c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":zany_face:" src="/twemoji/assets/svg/1f92a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_raised_eyebrow:" src="/twemoji/assets/svg/1f928.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_monocle:" src="/twemoji/assets/svg/1f9d0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":nerd_face:" src="/twemoji/assets/svg/1f913.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sunglasses:" src="/twemoji/assets/svg/1f60e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":disguised_face:" src="/twemoji/assets/svg/1f978.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":star_struck:" src="/twemoji/assets/svg/1f929.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":partying_face:" src="/twemoji/assets/svg/1f973.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smirk:" src="/twemoji/assets/svg/1f60f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":unamused:" src="/twemoji/assets/svg/1f612.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":disappointed:" src="/twemoji/assets/svg/1f61e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pensive:" src="/twemoji/assets/svg/1f614.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":worried:" src="/twemoji/assets/svg/1f61f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":confused:" src="/twemoji/assets/svg/1f615.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":slightly_frowning_face:" src="/twemoji/assets/svg/1f641.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_frowning_face:" src="/twemoji/assets/svg/2639.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":persevere:" src="/twemoji/assets/svg/1f623.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":confounded:" src="/twemoji/assets/svg/1f616.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tired_face:" src="/twemoji/assets/svg/1f62b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":weary:" src="/twemoji/assets/svg/1f629.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pleading_face:" src="/twemoji/assets/svg/1f97a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cry:" src="/twemoji/assets/svg/1f622.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sob:" src="/twemoji/assets/svg/1f62d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":triumph:" src="/twemoji/assets/svg/1f624.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":angry:" src="/twemoji/assets/svg/1f620.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rage:" src="/twemoji/assets/svg/1f621.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_symbols_over_face:" src="/twemoji/assets/svg/1f92c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":exploding_face:" src="/twemoji/assets/svg/1f92f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":flushed:" src="/twemoji/assets/svg/1f633.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hot_face:" src="/twemoji/assets/svg/1f975.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cold_face:" src="/twemoji/assets/svg/1f976.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_in_clouds:" src="/twemoji/assets/svg/1f636-200d-1f32b-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":scream:" src="/twemoji/assets/svg/1f631.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fearful:" src="/twemoji/assets/svg/1f628.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cold_sweat:" src="/twemoji/assets/svg/1f630.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":disappointed_releived:" src="/twemoji/assets/svg/1f625.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sweat:" src="/twemoji/assets/svg/1f613.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hugging_face:" src="/twemoji/assets/svg/1f917.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":thinking_face:" src="/twemoji/assets/svg/1f914.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_peeking_eye:" src="/twemoji/assets/svg/1fae3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_hand_over_mouth:" src="/twemoji/assets/svg/1f92d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_open_eyes_and_hand_over_mouth:" src="/twemoji/assets/svg/1fae2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":saluting_face:" src="/twemoji/assets/svg/1fae1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shushing_face:" src="/twemoji/assets/svg/1f92b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":melting_face:" src="/twemoji/assets/svg/1fae0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lying_face:" src="/twemoji/assets/svg/1f925.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_mouth:" src="/twemoji/assets/svg/1f636.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dotted_line_face:" src="/twemoji/assets/svg/1fae5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":neutral_face:" src="/twemoji/assets/svg/1f610.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_diagonal_mouth:" src="/twemoji/assets/svg/1fae4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":expressionless:" src="/twemoji/assets/svg/1f611.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":grimacing:" src="/twemoji/assets/svg/1f62c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_rolling_eyes:" src="/twemoji/assets/svg/1f644.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hushed:" src="/twemoji/assets/svg/1f62f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":frowning:" src="/twemoji/assets/svg/1f626.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":anguished:" src="/twemoji/assets/svg/1f627.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":open_mouth:" src="/twemoji/assets/svg/1f62e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":astonished:" src="/twemoji/assets/svg/1f632.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":yawning_face:" src="/twemoji/assets/svg/1f971.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sleeping:" src="/twemoji/assets/svg/1f634.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":drolling_face:" src="/twemoji/assets/svg/1f924.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sleepy:" src="/twemoji/assets/svg/1f62a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_exhaling:" src="/twemoji/assets/svg/1f62e-200d-1f4a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dizzy_face:" src="/twemoji/assets/svg/1f635.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_spiral_eyes:" src="/twemoji/assets/svg/1f635-200d-1f4ab.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":zipper_mouth_face:" src="/twemoji/assets/svg/1f910.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woozy_face:" src="/twemoji/assets/svg/1f974.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":nauseated_face:" src="/twemoji/assets/svg/1f922.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_vomiting:" src="/twemoji/assets/svg/1f92e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sneezing_face:" src="/twemoji/assets/svg/1f927.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mask:" src="/twemoji/assets/svg/1f637.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_thermometer:" src="/twemoji/assets/svg/1f912.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_head_bandage:" src="/twemoji/assets/svg/1f915.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":money_mouth_face:" src="/twemoji/assets/svg/1f911.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":face_with_cowboy_hat:" src="/twemoji/assets/svg/1f920.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smiling_imp:" src="/twemoji/assets/svg/1f608.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":imp:" src="/twemoji/assets/svg/1f47f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":japanese_ogre:" src="/twemoji/assets/svg/1f479.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":japanese_goblin:" src="/twemoji/assets/svg/1f47a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clown_face:" src="/twemoji/assets/svg/1f921.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":poop:" src="/twemoji/assets/svg/1f4a9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ghost:" src="/twemoji/assets/svg/1f47b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":skull:" src="/twemoji/assets/svg/1f480.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":skull_crossbones:" src="/twemoji/assets/svg/2620.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":alien:" src="/twemoji/assets/svg/1f47d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":space_invader:" src="/twemoji/assets/svg/1f47e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":robot_face:" src="/twemoji/assets/svg/1f916.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":jack_o_lantern:" src="/twemoji/assets/svg/1f383.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smiley_cat:" src="/twemoji/assets/svg/1f63a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smile_cat:" src="/twemoji/assets/svg/1f638.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":joy_cat:" src="/twemoji/assets/svg/1f639.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heart_eyes_cat:" src="/twemoji/assets/svg/1f63b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":smirk_cat:" src="/twemoji/assets/svg/1f63c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kissing_cat:" src="/twemoji/assets/svg/1f63d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":scream_cat:" src="/twemoji/assets/svg/1f640.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":crying_cat_face:" src="/twemoji/assets/svg/1f63f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pouting_cat:" src="/twemoji/assets/svg/1f63e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wave:" src="/twemoji/assets/svg/1f44b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":raised_back_of_hand:" src="/twemoji/assets/svg/1f91a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hand_splayed:" src="/twemoji/assets/svg/1f590.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":raised_hand:" src="/twemoji/assets/svg/270b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":vulcan:" src="/twemoji/assets/svg/1f596.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ok_hand:" src="/twemoji/assets/svg/1f44c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pinching_hand:" src="/twemoji/assets/svg/1f90f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pinched_fingers:" src="/twemoji/assets/svg/1f90c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":v:" src="/twemoji/assets/svg/270c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fingers_crossed:" src="/twemoji/assets/svg/1f91e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hand_with_index_finger_and_thumb_crossed:" src="/twemoji/assets/svg/1faf0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":love_you_gesture:" src="/twemoji/assets/svg/1f91f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sign_of_the_horns:" src="/twemoji/assets/svg/1f918.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":call_me_hand:" src="/twemoji/assets/svg/1f919.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":point_left:" src="/twemoji/assets/svg/1f448.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":point_right:" src="/twemoji/assets/svg/1f449.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":point_up_2:" src="/twemoji/assets/svg/1f446.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":middle_finger:" src="/twemoji/assets/svg/1f595.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":point_down:" src="/twemoji/assets/svg/1f447.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":point_up:" src="/twemoji/assets/svg/261d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":thumbsup:" src="/twemoji/assets/svg/1f44d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":thumbsdown:" src="/twemoji/assets/svg/1f44e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fist:" src="/twemoji/assets/svg/270a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":punch:" src="/twemoji/assets/svg/1f44a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":left_facing_fist:" src="/twemoji/assets/svg/1f91b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":right_facing_fist:" src="/twemoji/assets/svg/1f91c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heart_hand:" src="/twemoji/assets/svg/1faf6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clap:" src="/twemoji/assets/svg/1f44f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":raised_hands:" src="/twemoji/assets/svg/1f64c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":open_hands:" src="/twemoji/assets/svg/1f450.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":palms_up_together:" src="/twemoji/assets/svg/1f932.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":palm_up_hand:" src="/twemoji/assets/svg/1faf3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":palm_down_hand:" src="/twemoji/assets/svg/1faf4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":handshake:" src="/twemoji/assets/svg/1f91d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pray:" src="/twemoji/assets/svg/1f64f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":index_pointing_at_the_viewer:" src="/twemoji/assets/svg/1faf5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":leftwards_hand:" src="/twemoji/assets/svg/1faf2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rightwards_hand:" src="/twemoji/assets/svg/1faf1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":writing_hand:" src="/twemoji/assets/svg/270d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":nail_care:" src="/twemoji/assets/svg/1f485.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":selfie:" src="/twemoji/assets/svg/1f933.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":muscle:" src="/twemoji/assets/svg/1f4aa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mechanical_arm:" src="/twemoji/assets/svg/1f9be.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":leg:" src="/twemoji/assets/svg/1f9b5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mechanical_leg:" src="/twemoji/assets/svg/1f9bf.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":foot:" src="/twemoji/assets/svg/1f9b6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":footprint:" src="/twemoji/assets/svg/1f463.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ear:" src="/twemoji/assets/svg/1f442.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ear_with_hearing_aid:" src="/twemoji/assets/svg/1f9bb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":nose:" src="/twemoji/assets/svg/1f443.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":brain:" src="/twemoji/assets/svg/1f9e0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lungs:" src="/twemoji/assets/svg/1fac1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":anatomical_heart:" src="/twemoji/assets/svg/1fac0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tooth:" src="/twemoji/assets/svg/1f9b7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eyes:" src="/twemoji/assets/svg/1f440.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eye:" src="/twemoji/assets/svg/1f441.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tongue:" src="/twemoji/assets/svg/1f445.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lipstick:" src="/twemoji/assets/svg/1f484.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lips:" src="/twemoji/assets/svg/1f444.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":biting_lip:" src="/twemoji/assets/svg/1fae6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kiss:" src="/twemoji/assets/svg/1f48b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":drop_of_blood:" src="/twemoji/assets/svg/1fa78.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":speaking_head:" src="/twemoji/assets/svg/1f5e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bust_in_silhouette:" src="/twemoji/assets/svg/1f464.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":busts_in_silhouette:" src="/twemoji/assets/svg/1f465.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":people_hugging:" src="/twemoji/assets/svg/1fac2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":baby:" src="/twemoji/assets/svg/1f476.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":child:" src="/twemoji/assets/svg/1f9d2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":boy:" src="/twemoji/assets/svg/1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":girl:" src="/twemoji/assets/svg/1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person:" src="/twemoji/assets/svg/1f9d1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man:" src="/twemoji/assets/svg/1f468.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman:" src="/twemoji/assets/svg/1f469.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_culry_hair:" src="/twemoji/assets/svg/1f469-200d-1f9b1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_curly_hair:" src="/twemoji/assets/svg/1f468-200d-1f9b1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_curly_hair:" src="/twemoji/assets/svg/1f9d1-200d-1f9b1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blond_haired_person:" src="/twemoji/assets/svg/1f471.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blond_haired_woman:" src="/twemoji/assets/svg/1f471-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blond_haired_man:" src="/twemoji/assets/svg/1f471-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_red_haired:" src="/twemoji/assets/svg/1f9d1-200d-1f9b0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_red_haired:" src="/twemoji/assets/svg/1f469-200d-1f9b0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_red_haired:" src="/twemoji/assets/svg/1f468-200d-1f9b0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_white_haired:" src="/twemoji/assets/svg/1f9d1-200d-1f9b3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_white_haired:" src="/twemoji/assets/svg/1f468-200d-1f9b3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_white_haired:" src="/twemoji/assets/svg/1f469-200d-1f9b3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_bald:" src="/twemoji/assets/svg/1f468-200d-1f9b2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_bald:" src="/twemoji/assets/svg/1f469-200d-1f9b2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_bald:" src="/twemoji/assets/svg/1f9d1-200d-1f9b2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bearded_person:" src="/twemoji/assets/svg/1f9d4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_beard:" src="/twemoji/assets/svg/1f9d4-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_beard:" src="/twemoji/assets/svg/1f9d4-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":older_adult:" src="/twemoji/assets/svg/1f9d3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":older_woman:" src="/twemoji/assets/svg/1f475.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":older_man:" src="/twemoji/assets/svg/1f474.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_with_chinese_cap:" src="/twemoji/assets/svg/1f472.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_frowning:" src="/twemoji/assets/svg/1f64d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_frowning:" src="/twemoji/assets/svg/1f64d-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_frowning:" src="/twemoji/assets/svg/1f64d-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_pouting:" src="/twemoji/assets/svg/1f64e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_pouting:" src="/twemoji/assets/svg/1f64e-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_pouting:" src="/twemoji/assets/svg/1f64e-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_gesture_no:" src="/twemoji/assets/svg/1f645.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_gesture_no:" src="/twemoji/assets/svg/1f645-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_gesture_no:" src="/twemoji/assets/svg/1f645-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_gesture_ok:" src="/twemoji/assets/svg/1f646.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_gesture_ok:" src="/twemoji/assets/svg/1f646-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_gesture_ok:" src="/twemoji/assets/svg/1f646-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_tipping_hand:" src="/twemoji/assets/svg/1f481.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_tipping_hand:" src="/twemoji/assets/svg/1f481-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_tipping_hand:" src="/twemoji/assets/svg/1f481-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_raising_hand:" src="/twemoji/assets/svg/1f64b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_raising_hand:" src="/twemoji/assets/svg/1f64b-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_raising_hand:" src="/twemoji/assets/svg/1f64b-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":deaf_person:" src="/twemoji/assets/svg/1f9cf.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":deaf_man:" src="/twemoji/assets/svg/1f9cf-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":deaf_woman:" src="/twemoji/assets/svg/1f9cf-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_bowing:" src="/twemoji/assets/svg/1f647.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_bowing:" src="/twemoji/assets/svg/1f647-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_bowing:" src="/twemoji/assets/svg/1f647-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_facepalming:" src="/twemoji/assets/svg/1f926.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_facepalming:" src="/twemoji/assets/svg/1f926-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_facepalming:" src="/twemoji/assets/svg/1f926-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_shrugging:" src="/twemoji/assets/svg/1f937.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_shrugging:" src="/twemoji/assets/svg/1f937-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_shrugging:" src="/twemoji/assets/svg/1f937-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":health_worker:" src="/twemoji/assets/svg/1f9d1-200d-2695-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_health_worker:" src="/twemoji/assets/svg/1f468-200d-2695-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_health_worker:" src="/twemoji/assets/svg/1f469-200d-2695-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":student:" src="/twemoji/assets/svg/1f9d1-200d-1f393.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_student:" src="/twemoji/assets/svg/1f468-200d-1f393.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_student:" src="/twemoji/assets/svg/1f469-200d-1f393.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":teacher:" src="/twemoji/assets/svg/1f9d1-200d-1f3eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_teacher:" src="/twemoji/assets/svg/1f468-200d-1f3eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_teacher:" src="/twemoji/assets/svg/1f469-200d-1f3eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":judge:" src="/twemoji/assets/svg/1f9d1-200d-2696-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_judge:" src="/twemoji/assets/svg/1f468-200d-2696-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_judge:" src="/twemoji/assets/svg/1f469-200d-2696-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":farmer:" src="/twemoji/assets/svg/1f9d1-200d-1f33e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_farmer:" src="/twemoji/assets/svg/1f468-200d-1f33e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_farmer:" src="/twemoji/assets/svg/1f469-200d-1f33e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cook:" src="/twemoji/assets/svg/1f9d1-200d-1f373.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_cook:" src="/twemoji/assets/svg/1f468-200d-1f373.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_cook:" src="/twemoji/assets/svg/1f469-200d-1f373.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mechanic:" src="/twemoji/assets/svg/1f9d1-200d-1f527.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_mechanic:" src="/twemoji/assets/svg/1f468-200d-1f527.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_mechanic:" src="/twemoji/assets/svg/1f469-200d-1f527.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":factory_worker:" src="/twemoji/assets/svg/1f9d1-200d-1f3ed.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_factory_worker:" src="/twemoji/assets/svg/1f468-200d-1f3ed.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_factory_worker:" src="/twemoji/assets/svg/1f469-200d-1f3ed.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":office_worker:" src="/twemoji/assets/svg/1f9d1-200d-1f4bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_office_worker:" src="/twemoji/assets/svg/1f468-200d-1f4bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_office_worker:" src="/twemoji/assets/svg/1f469-200d-1f4bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":scientist:" src="/twemoji/assets/svg/1f9d1-200d-1f52c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_scientist:" src="/twemoji/assets/svg/1f468-200d-1f52c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_scientist:" src="/twemoji/assets/svg/1f469-200d-1f52c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":technologist:" src="/twemoji/assets/svg/1f9d1-200d-1f4bb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_technologist:" src="/twemoji/assets/svg/1f468-200d-1f4bb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_technologist:" src="/twemoji/assets/svg/1f469-200d-1f4bb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":singer:" src="/twemoji/assets/svg/1f9d1-200d-1f3a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_singer:" src="/twemoji/assets/svg/1f468-200d-1f3a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_singer:" src="/twemoji/assets/svg/1f469-200d-1f3a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":artist:" src="/twemoji/assets/svg/1f9d1-200d-1f3a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_artist:" src="/twemoji/assets/svg/1f468-200d-1f3a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_artist:" src="/twemoji/assets/svg/1f469-200d-1f3a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pilot:" src="/twemoji/assets/svg/1f9d1-200d-2708-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_pilot:" src="/twemoji/assets/svg/1f468-200d-2708-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_pilot:" src="/twemoji/assets/svg/1f469-200d-2708-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":astronaut:" src="/twemoji/assets/svg/1f9d1-200d-1f680.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_astronaut:" src="/twemoji/assets/svg/1f468-200d-1f680.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_astronaut:" src="/twemoji/assets/svg/1f469-200d-1f680.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":firefighter:" src="/twemoji/assets/svg/1f9d1-200d-1f692.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_firefighter:" src="/twemoji/assets/svg/1f468-200d-1f692.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_firefighter:" src="/twemoji/assets/svg/1f469-200d-1f692.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":police_officer:" src="/twemoji/assets/svg/1f46e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_police_officer:" src="/twemoji/assets/svg/1f46e-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_police_officer:" src="/twemoji/assets/svg/1f46e-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":detective:" src="/twemoji/assets/svg/1f575.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_detective:" src="/twemoji/assets/svg/1f575-fe0f-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_detective:" src="/twemoji/assets/svg/1f575-fe0f-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":guard:" src="/twemoji/assets/svg/1f482.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_guard:" src="/twemoji/assets/svg/1f482-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_guard:" src="/twemoji/assets/svg/1f482-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ninja:" src="/twemoji/assets/svg/1f977.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":construction_worker:" src="/twemoji/assets/svg/1f477.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_construction_worker:" src="/twemoji/assets/svg/1f477-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_construction_worker:" src="/twemoji/assets/svg/1f477-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_wearing_turban:" src="/twemoji/assets/svg/1f473.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_wearing_turban:" src="/twemoji/assets/svg/1f473-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_wearing_turban:" src="/twemoji/assets/svg/1f473-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_with_headscarf:" src="/twemoji/assets/svg/1f9d5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_with_veil:" src="/twemoji/assets/svg/1f470.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_with_veil:" src="/twemoji/assets/svg/1f470-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_with_veil:" src="/twemoji/assets/svg/1f470-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_in_tuxedo:" src="/twemoji/assets/svg/1f935.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_in_tuxedo:" src="/twemoji/assets/svg/1f935-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_in_tuxedo:" src="/twemoji/assets/svg/1f935-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_with_crown:" src="/twemoji/assets/svg/1fac5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":prince:" src="/twemoji/assets/svg/1f934.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":princess:" src="/twemoji/assets/svg/1f478.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pregnant_woman:" src="/twemoji/assets/svg/1f930.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pregnant_man:" src="/twemoji/assets/svg/1fac3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pregnant_person:" src="/twemoji/assets/svg/1fac4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":breast_feeding:" src="/twemoji/assets/svg/1f931.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_feeding_baby:" src="/twemoji/assets/svg/1f9d1-200d-1f37c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_feeding_baby:" src="/twemoji/assets/svg/1f468-200d-1f37c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_feeding_baby:" src="/twemoji/assets/svg/1f469-200d-1f37c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":angel:" src="/twemoji/assets/svg/1f47c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mx_claus:" src="/twemoji/assets/svg/1f9d1-200d-1f384.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":santa:" src="/twemoji/assets/svg/1f385.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mrs_claus:" src="/twemoji/assets/svg/1f936.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":superhero:" src="/twemoji/assets/svg/1f9b8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_superhero:" src="/twemoji/assets/svg/1f9b8-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_superhero:" src="/twemoji/assets/svg/1f9b8-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":supervillain:" src="/twemoji/assets/svg/1f9b9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_supervillain:" src="/twemoji/assets/svg/1f9b9-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_supervillain:" src="/twemoji/assets/svg/1f9b9-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mage:" src="/twemoji/assets/svg/1f9d9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_mage:" src="/twemoji/assets/svg/1f9d9-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_mage:" src="/twemoji/assets/svg/1f9d9-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fairy:" src="/twemoji/assets/svg/1f9da.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_fairy:" src="/twemoji/assets/svg/1f9da-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_fairy:" src="/twemoji/assets/svg/1f9da-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":vampire:" src="/twemoji/assets/svg/1f9db.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_vampire:" src="/twemoji/assets/svg/1f9db-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_vampire:" src="/twemoji/assets/svg/1f9db-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":merperson:" src="/twemoji/assets/svg/1f9dc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":merman:" src="/twemoji/assets/svg/1f9dc-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mermaid:" src="/twemoji/assets/svg/1f9dc-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":elf:" src="/twemoji/assets/svg/1f9dd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_elf:" src="/twemoji/assets/svg/1f9dd-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_elf:" src="/twemoji/assets/svg/1f9dd-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":genie:" src="/twemoji/assets/svg/1f9de.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_genie:" src="/twemoji/assets/svg/1f9de-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_genie:" src="/twemoji/assets/svg/1f9de-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":zombie:" src="/twemoji/assets/svg/1f9df.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_zombie:" src="/twemoji/assets/svg/1f9df-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_zombie:" src="/twemoji/assets/svg/1f9df-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":troll:" src="/twemoji/assets/svg/1f9cc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_getting_massage:" src="/twemoji/assets/svg/1f486.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_getting_massage:" src="/twemoji/assets/svg/1f486-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_getting_massage:" src="/twemoji/assets/svg/1f486-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_getting_haircut:" src="/twemoji/assets/svg/1f487.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_getting_haircut:" src="/twemoji/assets/svg/1f487-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_getting_haircut:" src="/twemoji/assets/svg/1f487-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_walking:" src="/twemoji/assets/svg/1f6b6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_walking:" src="/twemoji/assets/svg/1f6b6-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_walking:" src="/twemoji/assets/svg/1f6b6-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_walking_with_probing_cane:" src="/twemoji/assets/svg/1f9d1-200d-1f9af.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_walking_with_probing_cane:" src="/twemoji/assets/svg/1f468-200d-1f9af.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_walking_with_probing_cane:" src="/twemoji/assets/svg/1f469-200d-1f9af.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_standing:" src="/twemoji/assets/svg/1f9cd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_standing:" src="/twemoji/assets/svg/1f9cd-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_standing:" src="/twemoji/assets/svg/1f9cd-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_kneeling:" src="/twemoji/assets/svg/1f9ce.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_kneeling:" src="/twemoji/assets/svg/1f9ce-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_kneeling:" src="/twemoji/assets/svg/1f9ce-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_in_motorized_wheelchair:" src="/twemoji/assets/svg/1f9d1-200d-1f9bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_in_motorized_wheelchair:" src="/twemoji/assets/svg/1f468-200d-1f9bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_in_motorized_wheelchair:" src="/twemoji/assets/svg/1f469-200d-1f9bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_in_manual_wheelchair:" src="/twemoji/assets/svg/1f9d1-200d-1f9bd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_in_manual_wheelchair:" src="/twemoji/assets/svg/1f468-200d-1f9bd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_in_manual_wheelchair:" src="/twemoji/assets/svg/1f469-200d-1f9bd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_running:" src="/twemoji/assets/svg/1f3c3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_running:" src="/twemoji/assets/svg/1f3c3-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_running:" src="/twemoji/assets/svg/1f3c3-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_dancing:" src="/twemoji/assets/svg/1f483.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_dancing:" src="/twemoji/assets/svg/1f57a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":levitate:" src="/twemoji/assets/svg/1f574.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":people_with_bunny_ears_partying:" src="/twemoji/assets/svg/1f46f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":men_with_bunny_ears_partying:" src="/twemoji/assets/svg/1f46f-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":women_with_bunny_ears_partying:" src="/twemoji/assets/svg/1f46f-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_in_steamy_room:" src="/twemoji/assets/svg/1f9d6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_in_steamy_room:" src="/twemoji/assets/svg/1f9d6-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_in_steamy_room:" src="/twemoji/assets/svg/1f9d6-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":people_holding_hands:" src="/twemoji/assets/svg/1f9d1-200d-1f91d-200d-1f9d1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":women_holding_hands:" src="/twemoji/assets/svg/1f46d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_and_man_holding_hands:" src="/twemoji/assets/svg/1f46b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":men_holding_hands:" src="/twemoji/assets/svg/1f46c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":couplekiss:" src="/twemoji/assets/svg/1f48f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kiss_woman_man:" src="/twemoji/assets/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f468.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kiss_mm:" src="/twemoji/assets/svg/1f468-200d-2764-fe0f-200d-1f48b-200d-1f468.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kiss_ww:" src="/twemoji/assets/svg/1f469-200d-2764-fe0f-200d-1f48b-200d-1f469.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":couple_with_heart:" src="/twemoji/assets/svg/1f491.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":couple_with_heart_woman_man:" src="/twemoji/assets/svg/1f469-200d-2764-fe0f-200d-1f468.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":couple_with_heart_mm:" src="/twemoji/assets/svg/1f468-200d-2764-fe0f-200d-1f468.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":couple_with_heart_ww:" src="/twemoji/assets/svg/1f469-200d-2764-fe0f-200d-1f469.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family:" src="/twemoji/assets/svg/1f46a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_man_woman_boy:" src="/twemoji/assets/svg/1f468-200d-1f469-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mwg:" src="/twemoji/assets/svg/1f468-200d-1f469-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mwgb:" src="/twemoji/assets/svg/1f468-200d-1f469-200d-1f467-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mwbb:" src="/twemoji/assets/svg/1f468-200d-1f469-200d-1f466-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mwgg:" src="/twemoji/assets/svg/1f468-200d-1f469-200d-1f467-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mmb:" src="/twemoji/assets/svg/1f468-200d-1f468-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mmg:" src="/twemoji/assets/svg/1f468-200d-1f468-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mmgb:" src="/twemoji/assets/svg/1f468-200d-1f468-200d-1f467-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mmbb:" src="/twemoji/assets/svg/1f468-200d-1f468-200d-1f466-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_mmgg:" src="/twemoji/assets/svg/1f468-200d-1f468-200d-1f467-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_wwb:" src="/twemoji/assets/svg/1f469-200d-1f469-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_wwg:" src="/twemoji/assets/svg/1f469-200d-1f469-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_wwgb:" src="/twemoji/assets/svg/1f469-200d-1f469-200d-1f467-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_wwbb:" src="/twemoji/assets/svg/1f469-200d-1f469-200d-1f466-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_wwgg:" src="/twemoji/assets/svg/1f469-200d-1f469-200d-1f467-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_man_boy:" src="/twemoji/assets/svg/1f468-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_man_boy_boy:" src="/twemoji/assets/svg/1f468-200d-1f466-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_man_girl:" src="/twemoji/assets/svg/1f468-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_man_girl_boy:" src="/twemoji/assets/svg/1f468-200d-1f467-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_man_girl_girl:" src="/twemoji/assets/svg/1f468-200d-1f467-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_woman_boy:" src="/twemoji/assets/svg/1f469-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_woman_boy_boy:" src="/twemoji/assets/svg/1f469-200d-1f466-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_woman_girl:" src="/twemoji/assets/svg/1f469-200d-1f467.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_woman_girl_boy:" src="/twemoji/assets/svg/1f469-200d-1f467-200d-1f466.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":family_woman_girl_girl:" src="/twemoji/assets/svg/1f469-200d-1f467-200d-1f467.svg" /></span>
                        </div>
                        <div id="emoji-natures" className="picker-emoji picker-emoji-content emoji-nature">
                            <span><img draggable="false" className="emoji" alt=":dog:" src="/twemoji/assets/svg/1f436.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cat:" src="/twemoji/assets/svg/1f431.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mouse:" src="/twemoji/assets/svg/1f42d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hamster:" src="/twemoji/assets/svg/1f439.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rabbit:" src="/twemoji/assets/svg/1f430.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fox_face:" src="/twemoji/assets/svg/1f98a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bear:" src="/twemoji/assets/svg/1f43b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":panda_face:" src="/twemoji/assets/svg/1f43c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":polar_bear:" src="/twemoji/assets/svg/1f43b-200d-2744-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":koala:" src="/twemoji/assets/svg/1f428.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tiger:" src="/twemoji/assets/svg/1f42f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lion_face:" src="/twemoji/assets/svg/1f981.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cow:" src="/twemoji/assets/svg/1f42e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pig:" src="/twemoji/assets/svg/1f437.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pig_nose:" src="/twemoji/assets/svg/1f43d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":frog:" src="/twemoji/assets/svg/1f438.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":monkey_face:" src="/twemoji/assets/svg/1f435.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":see_no_evil:" src="/twemoji/assets/svg/1f648.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hear_no_evil:" src="/twemoji/assets/svg/1f649.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":speak_no_evil:" src="/twemoji/assets/svg/1f64a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":monkey:" src="/twemoji/assets/svg/1f412.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":chicken:" src="/twemoji/assets/svg/1f414.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":penguin:" src="/twemoji/assets/svg/1f427.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bird:" src="/twemoji/assets/svg/1f426.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":baby_chick:" src="/twemoji/assets/svg/1f424.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hatching_chick:" src="/twemoji/assets/svg/1f423.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hatched_chick:" src="/twemoji/assets/svg/1f425.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":duck:" src="/twemoji/assets/svg/1f986.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eagle:" src="/twemoji/assets/svg/1f985.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":owl:" src="/twemoji/assets/svg/1f989.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bat:" src="/twemoji/assets/svg/1f987.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wolf:" src="/twemoji/assets/svg/1f43a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":boar:" src="/twemoji/assets/svg/1f417.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":horse:" src="/twemoji/assets/svg/1f434.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":unicorn_face:" src="/twemoji/assets/svg/1f984.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bee:" src="/twemoji/assets/svg/1f41d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bug:" src="/twemoji/assets/svg/1f41b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":butterfly:" src="/twemoji/assets/svg/1f98b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":snail:" src="/twemoji/assets/svg/1f40c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":worm:" src="/twemoji/assets/svg/1fab1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lady_beetle:" src="/twemoji/assets/svg/1f41e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ant:" src="/twemoji/assets/svg/1f41c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fly:" src="/twemoji/assets/svg/1fab0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mosquito:" src="/twemoji/assets/svg/1f99f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cockroach:" src="/twemoji/assets/svg/1fab3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beetle:" src="/twemoji/assets/svg/1fab2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cricket:" src="/twemoji/assets/svg/1f997.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":spider:" src="/twemoji/assets/svg/1f577.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":spider_web:" src="/twemoji/assets/svg/1f578.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":scorpion:" src="/twemoji/assets/svg/1f982.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":turtle:" src="/twemoji/assets/svg/1f422.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":snake:" src="/twemoji/assets/svg/1f40d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lizard:" src="/twemoji/assets/svg/1f98e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":t_rex:" src="/twemoji/assets/svg/1f996.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sauropod:" src="/twemoji/assets/svg/1f995.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":octopus:" src="/twemoji/assets/svg/1f419.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":squid:" src="/twemoji/assets/svg/1f991.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shrimp:" src="/twemoji/assets/svg/1f990.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lobster:" src="/twemoji/assets/svg/1f99e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":crab:" src="/twemoji/assets/svg/1f980.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blowfish:" src="/twemoji/assets/svg/1f421.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tropical_fish:" src="/twemoji/assets/svg/1f420.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fish:" src="/twemoji/assets/svg/1f41f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":seal:" src="/twemoji/assets/svg/1f9ad.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dolphin:" src="/twemoji/assets/svg/1f42c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":whale:" src="/twemoji/assets/svg/1f433.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":whale2:" src="/twemoji/assets/svg/1f40b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shark:" src="/twemoji/assets/svg/1f988.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":crocodile:" src="/twemoji/assets/svg/1f40a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tiger2:" src="/twemoji/assets/svg/1f405.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":leopard:" src="/twemoji/assets/svg/1f406.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":zebra:" src="/twemoji/assets/svg/1f993.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":gorilla:" src="/twemoji/assets/svg/1f98d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":orangutan:" src="/twemoji/assets/svg/1f9a7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":elephant:" src="/twemoji/assets/svg/1f418.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mammoth:" src="/twemoji/assets/svg/1f9a3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bison:" src="/twemoji/assets/svg/1f9ac.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hippopotamus:" src="/twemoji/assets/svg/1f99b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rhinoceros:" src="/twemoji/assets/svg/1f98f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dromedary_camel:" src="/twemoji/assets/svg/1f42a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":camel:" src="/twemoji/assets/svg/1f42b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":giraffe:" src="/twemoji/assets/svg/1f992.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kangaroo:" src="/twemoji/assets/svg/1f998.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":water_buffalo:" src="/twemoji/assets/svg/1f403.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ox:" src="/twemoji/assets/svg/1f402.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cow2:" src="/twemoji/assets/svg/1f404.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":racehorse:" src="/twemoji/assets/svg/1f40e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pig2:" src="/twemoji/assets/svg/1f416.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ram:" src="/twemoji/assets/svg/1f40f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sheep:" src="/twemoji/assets/svg/1f411.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":llama:" src="/twemoji/assets/svg/1f999.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":goat:" src="/twemoji/assets/svg/1f410.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":deer:" src="/twemoji/assets/svg/1f98c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dog2:" src="/twemoji/assets/svg/1f415.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ppodle:" src="/twemoji/assets/svg/1f429.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":guide_dog:" src="/twemoji/assets/svg/1f9ae.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":service_dog:" src="/twemoji/assets/svg/1f415-200d-1f9ba.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cat2:" src="/twemoji/assets/svg/1f408.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_cat:" src="/twemoji/assets/svg/1f408-200d-2b1b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":feather:" src="/twemoji/assets/svg/1fab6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rooster:" src="/twemoji/assets/svg/1f413.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":turkey:" src="/twemoji/assets/svg/1f983.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dodo:" src="/twemoji/assets/svg/1f9a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":peacock:" src="/twemoji/assets/svg/1f99a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":parrot:" src="/twemoji/assets/svg/1f99c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":swan:" src="/twemoji/assets/svg/1f9a2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":flamingo:" src="/twemoji/assets/svg/1f9a9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dove_of_peace:" src="/twemoji/assets/svg/1f54a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rabbit2:" src="/twemoji/assets/svg/1f407.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":raccoon:" src="/twemoji/assets/svg/1f99d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":skunk:" src="/twemoji/assets/svg/1f9a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":badger:" src="/twemoji/assets/svg/1f9a1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beaver:" src="/twemoji/assets/svg/1f9ab.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":otter:" src="/twemoji/assets/svg/1f9a6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sloth:" src="/twemoji/assets/svg/1f9a5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mouse2:" src="/twemoji/assets/svg/1f401.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rat:" src="/twemoji/assets/svg/1f400.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":chipmunk:" src="/twemoji/assets/svg/1f43f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hedgehog:" src="/twemoji/assets/svg/1f994.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":paw_prints:" src="/twemoji/assets/svg/1f43e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dragon:" src="/twemoji/assets/svg/1f409.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dragon_face:" src="/twemoji/assets/svg/1f432.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cactus:" src="/twemoji/assets/svg/1f335.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":christmas_tree:" src="/twemoji/assets/svg/1f384.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":evergreen_tree:" src="/twemoji/assets/svg/1f332.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":deciduous_tree:" src="/twemoji/assets/svg/1f333.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":palm_tree:" src="/twemoji/assets/svg/1f334.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":seedling:" src="/twemoji/assets/svg/1f331.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":herb:" src="/twemoji/assets/svg/1f33f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shamrock:" src="/twemoji/assets/svg/2618.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":four_leaf_clover:" src="/twemoji/assets/svg/1f340.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bamboo:" src="/twemoji/assets/svg/1f38d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tanabata_tree:" src="/twemoji/assets/svg/1f38b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":leaves:" src="/twemoji/assets/svg/1f343.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fallen_leaf:" src="/twemoji/assets/svg/1f342.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":maple_leaf:" src="/twemoji/assets/svg/1f341.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":empty_nest:" src="/twemoji/assets/svg/1fab9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":nest_with_eggs:" src="/twemoji/assets/svg/1faba.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mushroom:" src="/twemoji/assets/svg/1f344.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shell:" src="/twemoji/assets/svg/1f41a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":coral:" src="/twemoji/assets/svg/1fab8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rock:" src="/twemoji/assets/svg/1faa8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wood:" src="/twemoji/assets/svg/1fab5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ear_of_rice:" src="/twemoji/assets/svg/1f33e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":potted_plant:" src="/twemoji/assets/svg/1fab4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bouquet:" src="/twemoji/assets/svg/1f490.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tulip:" src="/twemoji/assets/svg/1f337.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rose:" src="/twemoji/assets/svg/1f339.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wilted_flower:" src="/twemoji/assets/svg/1f940.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lotus:" src="/twemoji/assets/svg/1f33a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hibiscus:" src="/twemoji/assets/svg/1f338.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blossom:" src="/twemoji/assets/svg/1f33c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sunflower:" src="/twemoji/assets/svg/1f33b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sun_with_face:" src="/twemoji/assets/svg/1f31e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":full_moon_with_face:" src="/twemoji/assets/svg/1f31d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":first_quarter_moon_with_face:" src="/twemoji/assets/svg/1f31b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":last_quarter_moon_with_face:" src="/twemoji/assets/svg/1f31c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":new_moon_with_face:" src="/twemoji/assets/svg/1f31a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":full_moon:" src="/twemoji/assets/svg/1f315.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":waning_gibbous_moon:" src="/twemoji/assets/svg/1f316.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":last_quarter_moon:" src="/twemoji/assets/svg/1f317.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":waning_crescent_moon:" src="/twemoji/assets/svg/1f318.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":new_moon:" src="/twemoji/assets/svg/1f311.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":waxing_crescent_moon:" src="/twemoji/assets/svg/1f312.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":first_quarter_moon:" src="/twemoji/assets/svg/1f313.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":waxing_gibbous_moon:" src="/twemoji/assets/svg/1f314.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":crescent_moon:" src="/twemoji/assets/svg/1f319.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":earth_americas:" src="/twemoji/assets/svg/1f30e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":earth_africa:" src="/twemoji/assets/svg/1f30d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":earth_asia:" src="/twemoji/assets/svg/1f30f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ringed_planet:" src="/twemoji/assets/svg/1fa90.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dizzy:" src="/twemoji/assets/svg/1f4ab.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":star:" src="/twemoji/assets/svg/2b50.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":star2:" src="/twemoji/assets/svg/1f31f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sparkles:" src="/twemoji/assets/svg/2728.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":zap:" src="/twemoji/assets/svg/26a1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":comet:" src="/twemoji/assets/svg/2604.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":boom:" src="/twemoji/assets/svg/1f4a5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fire:" src="/twemoji/assets/svg/1f525.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cloud_with_tornado:" src="/twemoji/assets/svg/1f32a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rainbow:" src="/twemoji/assets/svg/1f308.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sunny:" src="/twemoji/assets/svg/2600.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_sun_small_cloud:" src="/twemoji/assets/svg/1f324.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":partly_sunny:" src="/twemoji/assets/svg/26c5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_sun_cloud:" src="/twemoji/assets/svg/1f325.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cloud:" src="/twemoji/assets/svg/2601.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_sun_rain_cloud:" src="/twemoji/assets/svg/1f326.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cloud_rain:" src="/twemoji/assets/svg/1f327.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":thunder_cloud_rain:" src="/twemoji/assets/svg/26c8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cloud_lightning:" src="/twemoji/assets/svg/1f329.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cloud_snow:" src="/twemoji/assets/svg/1f328.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":snowflake:" src="/twemoji/assets/svg/2744.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":snowman2:" src="/twemoji/assets/svg/2603.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":snowman:" src="/twemoji/assets/svg/26c4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wind_blowing_face:" src="/twemoji/assets/svg/1f32c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dash:" src="/twemoji/assets/svg/1f4a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":droplet:" src="/twemoji/assets/svg/1f4a7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sweat_drops:" src="/twemoji/assets/svg/1f4a6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bubbles:" src="/twemoji/assets/svg/1fae7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":umbrella:" src="/twemoji/assets/svg/2614.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":umbrella2:" src="/twemoji/assets/svg/2602.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ocean:" src="/twemoji/assets/svg/1f30a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fog:" src="/twemoji/assets/svg/1f32b.svg" /></span>
                        </div>
                        <div id="emoji-foods" className="picker-emoji picker-emoji-content emoji-food">
                            <span><img draggable="false" className="emoji" alt=":green_apple:" src="/twemoji/assets/svg/1f34f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":apple:" src="/twemoji/assets/svg/1f34e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pear:" src="/twemoji/assets/svg/1f350.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tangerine:" src="/twemoji/assets/svg/1f34a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lemon:" src="/twemoji/assets/svg/1f34b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":banana:" src="/twemoji/assets/svg/1f34c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":watermelon:" src="/twemoji/assets/svg/1f349.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":grapes:" src="/twemoji/assets/svg/1f347.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blueberries:" src="/twemoji/assets/svg/1fad0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":strawberry:" src="/twemoji/assets/svg/1f353.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":melon:" src="/twemoji/assets/svg/1f348.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cherries:" src="/twemoji/assets/svg/1f352.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":peach:" src="/twemoji/assets/svg/1f351.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mango:" src="/twemoji/assets/svg/1f96d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pineapple:" src="/twemoji/assets/svg/1f34d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":coconut:" src="/twemoji/assets/svg/1f965.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kiwifruit:" src="/twemoji/assets/svg/1f95d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tomato:" src="/twemoji/assets/svg/1f345.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eggplant:" src="/twemoji/assets/svg/1f346.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":avocado:" src="/twemoji/assets/svg/1f951.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":olive:" src="/twemoji/assets/svg/1fad2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":broccoli:" src="/twemoji/assets/svg/1f966.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":leafy_green:" src="/twemoji/assets/svg/1f96c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bell_pepper:" src="/twemoji/assets/svg/1fad1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cucumber:" src="/twemoji/assets/svg/1f952.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hot_pepper:" src="/twemoji/assets/svg/1f336.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":corn:" src="/twemoji/assets/svg/1f33d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":carrot:" src="/twemoji/assets/svg/1f955.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":garlic:" src="/twemoji/assets/svg/1f9c4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":onion:" src="/twemoji/assets/svg/1f9c5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":potato:" src="/twemoji/assets/svg/1f954.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sweet_potato:" src="/twemoji/assets/svg/1f360.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":croissant:" src="/twemoji/assets/svg/1f950.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bagel:" src="/twemoji/assets/svg/1f96f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bread:" src="/twemoji/assets/svg/1f35e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":french_bread:" src="/twemoji/assets/svg/1f956.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":flatbread:" src="/twemoji/assets/svg/1fad3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pretzel:" src="/twemoji/assets/svg/1f968.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cheese:" src="/twemoji/assets/svg/1f9c0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":egg:" src="/twemoji/assets/svg/1f95a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cooking:" src="/twemoji/assets/svg/1f373.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":butter:" src="/twemoji/assets/svg/1f9c8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pancakes:" src="/twemoji/assets/svg/1f95e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":waffle:" src="/twemoji/assets/svg/1f9c7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bacon:" src="/twemoji/assets/svg/1f953.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cut_of_meat:" src="/twemoji/assets/svg/1f969.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":poultry_leg:" src="/twemoji/assets/svg/1f357.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":meat_on_bone:" src="/twemoji/assets/svg/1f356.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bone:" src="/twemoji/assets/svg/1f9b4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hotdog:" src="/twemoji/assets/svg/1f32d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hamburger:" src="/twemoji/assets/svg/1f354.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fries:" src="/twemoji/assets/svg/1f35f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pizza:" src="/twemoji/assets/svg/1f355.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sandwich:" src="/twemoji/assets/svg/1f96a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stuffed_flatbread:" src="/twemoji/assets/svg/1f959.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":falafel:" src="/twemoji/assets/svg/1f9c6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":taco:" src="/twemoji/assets/svg/1f32e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":burrito:" src="/twemoji/assets/svg/1f32f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tamale:" src="/twemoji/assets/svg/1fad4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":salad:" src="/twemoji/assets/svg/1f957.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shallow_pan_of_food:" src="/twemoji/assets/svg/1f958.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fondue:" src="/twemoji/assets/svg/1fad5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":canned_food:" src="/twemoji/assets/svg/1f96b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":jar:" src="/twemoji/assets/svg/1fad9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":spaghetti:" src="/twemoji/assets/svg/1f35d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ramen:" src="/twemoji/assets/svg/1f35c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stew:" src="/twemoji/assets/svg/1f372.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":curry:" src="/twemoji/assets/svg/1f35b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sushi:" src="/twemoji/assets/svg/1f363.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bento:" src="/twemoji/assets/svg/1f371.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dumpling:" src="/twemoji/assets/svg/1f95f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":oyster:" src="/twemoji/assets/svg/1f9aa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fried_shrimp:" src="/twemoji/assets/svg/1f364.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rice_ball:" src="/twemoji/assets/svg/1f359.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rice:" src="/twemoji/assets/svg/1f35a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rice_cracker:" src="/twemoji/assets/svg/1f358.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fish_cake:" src="/twemoji/assets/svg/1f365.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fortune_cookie:" src="/twemoji/assets/svg/1f960.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":moon_cake:" src="/twemoji/assets/svg/1f96e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":oden:" src="/twemoji/assets/svg/1f362.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dango:" src="/twemoji/assets/svg/1f361.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shaved_ice:" src="/twemoji/assets/svg/1f367.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ice_cream:" src="/twemoji/assets/svg/1f368.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":icecream:" src="/twemoji/assets/svg/1f366.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pie:" src="/twemoji/assets/svg/1f967.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cupcake:" src="/twemoji/assets/svg/1f9c1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cake:" src="/twemoji/assets/svg/1f370.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":birthday:" src="/twemoji/assets/svg/1f382.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":flan:" src="/twemoji/assets/svg/1f36e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lollipop:" src="/twemoji/assets/svg/1f36d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":candy:" src="/twemoji/assets/svg/1f36c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":chocolate_bar:" src="/twemoji/assets/svg/1f36b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":popcorn:" src="/twemoji/assets/svg/1f37f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":doughnut:" src="/twemoji/assets/svg/1f369.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cookie:" src="/twemoji/assets/svg/1f36a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":chestnut:" src="/twemoji/assets/svg/1f330.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":peanuts:" src="/twemoji/assets/svg/1f95c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beans:" src="/twemoji/assets/svg/1fad8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":honey_pot:" src="/twemoji/assets/svg/1f36f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":milk:" src="/twemoji/assets/svg/1f95b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pouring_liquid:" src="/twemoji/assets/svg/1fad7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":baby_bottle:" src="/twemoji/assets/svg/1f37c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":teapot:" src="/twemoji/assets/svg/1fad5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":coffee:" src="/twemoji/assets/svg/2615.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tea:" src="/twemoji/assets/svg/1f375.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mate:" src="/twemoji/assets/svg/1f9c9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beverage_box:" src="/twemoji/assets/svg/1f9c3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cup_with_straw:" src="/twemoji/assets/svg/1f964.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bubble_tea:" src="/twemoji/assets/svg/1f9cb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shake:" src="/twemoji/assets/svg/1f376.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beer:" src="/twemoji/assets/svg/1f37a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beers:" src="/twemoji/assets/svg/1f37b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":champagne_glass:" src="/twemoji/assets/svg/1f942.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wine_glass:" src="/twemoji/assets/svg/1f377.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tumbler_glass:" src="/twemoji/assets/svg/1f943.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cocktail:" src="/twemoji/assets/svg/1f378.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tropical_drink:" src="/twemoji/assets/svg/1f379.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":champagne:" src="/twemoji/assets/svg/1f37e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ice_cube:" src="/twemoji/assets/svg/1f9ca.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":spoon:" src="/twemoji/assets/svg/1f944.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fork_and_knife:" src="/twemoji/assets/svg/1f374.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fork_knife_plate:" src="/twemoji/assets/svg/1f37d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bowl_with_spoon:" src="/twemoji/assets/svg/1f963.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":takeout_box:" src="/twemoji/assets/svg/1f961.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":chopsticks:" src="/twemoji/assets/svg/1f962.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":salt:" src="/twemoji/assets/svg/1f9c2.svg" /></span>
                        </div>
                        <div id="emoji-activity" className="picker-emoji picker-emoji-content emoji-activity">
                            <span><img draggable="false" className="emoji" alt=":soccer:" src="/twemoji/assets/svg/26bd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":basketball:" src="/twemoji/assets/svg/1f3c0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":football:" src="/twemoji/assets/svg/1f3c8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":baseball:" src="/twemoji/assets/svg/26be.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":softball:" src="/twemoji/assets/svg/1f94e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tennis:" src="/twemoji/assets/svg/1f3be.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":volleyball:" src="/twemoji/assets/svg/1f3d0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rugby_football:" src="/twemoji/assets/svg/1f3c9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":flying_disc:" src="/twemoji/assets/svg/1f94f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":8ball:" src="/twemoji/assets/svg/1f3b1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":yo_yo:" src="/twemoji/assets/svg/1fa80.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ping_pong:" src="/twemoji/assets/svg/1f3d3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":badminton:" src="/twemoji/assets/svg/1f3f8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hockey:" src="/twemoji/assets/svg/1f3d2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":field_hockey:" src="/twemoji/assets/svg/1f3d1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":lacrosse:" src="/twemoji/assets/svg/1f94d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cricket_game:" src="/twemoji/assets/svg/1f3cf.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":boomerang:" src="/twemoji/assets/svg/1fa83.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":goal:" src="/twemoji/assets/svg/1f945.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":golf:" src="/twemoji/assets/svg/26f3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kite:" src="/twemoji/assets/svg/1fa81.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":playground_slide:" src="/twemoji/assets/svg/1f6dd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bow_and_arrow:" src="/twemoji/assets/svg/1f3f9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fishing_pole_and_fish:" src="/twemoji/assets/svg/1f3a3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":diving_mask:" src="/twemoji/assets/svg/1f93f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":boxing_glove:" src="/twemoji/assets/svg/1f94a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":martial_arts_uniform:" src="/twemoji/assets/svg/1f94b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":running_shirt_with_sash:" src="/twemoji/assets/svg/1f3bd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":skateboard:" src="/twemoji/assets/svg/1f6f9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":roller_skate:" src="/twemoji/assets/svg/1f6fc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sled:" src="/twemoji/assets/svg/1f6f7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ice_skate:" src="/twemoji/assets/svg/26f8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":curling_stone:" src="/twemoji/assets/svg/1f94c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ski:" src="/twemoji/assets/svg/1f3bf.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":skier:" src="/twemoji/assets/svg/26f7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":snowboarder:" src="/twemoji/assets/svg/1f3c2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":parachute:" src="/twemoji/assets/svg/1fa82.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_lifting_weights:" src="/twemoji/assets/svg/1f3cb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_lifting_weights:" src="/twemoji/assets/svg/1f3cb-fe0f-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_lifting_weights:" src="/twemoji/assets/svg/1f3cb-fe0f-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":people_wrestling:" src="/twemoji/assets/svg/1f93c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":women_werstling:" src="/twemoji/assets/svg/1f93c-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":men_wrestling:" src="/twemoji/assets/svg/1f93c-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_doing_cartwheel:" src="/twemoji/assets/svg/1f938.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_cartwheeling:" src="/twemoji/assets/svg/1f938-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_cartwheeling:" src="/twemoji/assets/svg/1f938-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_bouncing_ball:" src="/twemoji/assets/svg/26f9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_bouncing_ball:" src="/twemoji/assets/svg/26f9-fe0f-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_bouncing_ball:" src="/twemoji/assets/svg/26f9-fe0f-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_fencing:" src="/twemoji/assets/svg/1f93a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_playing_handball:" src="/twemoji/assets/svg/1f93e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_playing_handball:" src="/twemoji/assets/svg/1f93e-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_playing_handball:" src="/twemoji/assets/svg/1f93e-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_golfing:" src="/twemoji/assets/svg/1f3cc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_golfing:" src="/twemoji/assets/svg/1f3cc-fe0f-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_golfing:" src="/twemoji/assets/svg/1f3cc-fe0f-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":horse_racing:" src="/twemoji/assets/svg/1f3c7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_in_lotus_position:" src="/twemoji/assets/svg/1f9d8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_in_lotus_position:" src="/twemoji/assets/svg/1f9d8-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_in_lotus_position:" src="/twemoji/assets/svg/1f9d8-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_surfing:" src="/twemoji/assets/svg/1f3c4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_surfing:" src="/twemoji/assets/svg/1f3c4-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_surfing:" src="/twemoji/assets/svg/1f3c4-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_swimming:" src="/twemoji/assets/svg/1f3ca.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_swimming:" src="/twemoji/assets/svg/1f3ca-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_swimming:" src="/twemoji/assets/svg/1f3ca-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_playing_waterpolo:" src="/twemoji/assets/svg/1f93d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_playing_waterpolo:" src="/twemoji/assets/svg/1f93d-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_playing_waterpolo:" src="/twemoji/assets/svg/1f93d-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_rowing_boat:" src="/twemoji/assets/svg/1f6a3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_rowing_boat:" src="/twemoji/assets/svg/1f6a3-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_rowing_boat:" src="/twemoji/assets/svg/1f6a3-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_climbing:" src="/twemoji/assets/svg/1f9d7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_climbing:" src="/twemoji/assets/svg/1f9d7-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_climbing:" src="/twemoji/assets/svg/1f9d7-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_mountain_biking:" src="/twemoji/assets/svg/1f6b5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_mountain_biking:" src="/twemoji/assets/svg/1f6b5-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_mountain_biking:" src="/twemoji/assets/svg/1f6b5-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_biking:" src="/twemoji/assets/svg/1f6b4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_biking:" src="/twemoji/assets/svg/1f6b4-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_biking:" src="/twemoji/assets/svg/1f6b4-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":trophy:" src="/twemoji/assets/svg/1f3c6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":first_place_medal:" src="/twemoji/assets/svg/1f947.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":second_place_medal:" src="/twemoji/assets/svg/1f948.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":third_place_medal:" src="/twemoji/assets/svg/1f949.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sports_medal:" src="/twemoji/assets/svg/1f3c5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":military_medal:" src="/twemoji/assets/svg/1f396.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rosette:" src="/twemoji/assets/svg/1f3f5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":reminder_ribbon:" src="/twemoji/assets/svg/1f397.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ticket:" src="/twemoji/assets/svg/1f3ab.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":admission_tickets:" src="/twemoji/assets/svg/1f39f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":circus_tent:" src="/twemoji/assets/svg/1f3aa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":person_juggling:" src="/twemoji/assets/svg/1f939.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":man_juggling:" src="/twemoji/assets/svg/1f939-200d-2642-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":woman_juggling:" src="/twemoji/assets/svg/1f939-200d-2640-fe0f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":performing_arts:" src="/twemoji/assets/svg/1f3ad.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ballet_shoes:" src="/twemoji/assets/svg/1fa70.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":art:" src="/twemoji/assets/svg/1f3a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clapper:" src="/twemoji/assets/svg/1f3ac.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":microphone:" src="/twemoji/assets/svg/1f3a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":headphones:" src="/twemoji/assets/svg/1f3a7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":musical_score:" src="/twemoji/assets/svg/1f3bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":musical_keyboard:" src="/twemoji/assets/svg/1f3b9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":drum:" src="/twemoji/assets/svg/1f941.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":long_drum:" src="/twemoji/assets/svg/1fa98.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":saxophone:" src="/twemoji/assets/svg/1f3b7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":trumpet:" src="/twemoji/assets/svg/1f3ba.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":accordion:" src="/twemoji/assets/svg/1fa97.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":guitar:" src="/twemoji/assets/svg/1f3b8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":banjo:" src="/twemoji/assets/svg/1fa95.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":violin:" src="/twemoji/assets/svg/1f3bb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":game_die:" src="/twemoji/assets/svg/1f3b2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":chess_pawn:" src="/twemoji/assets/svg/265f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":dart:" src="/twemoji/assets/svg/1f3af.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bowling:" src="/twemoji/assets/svg/1f3b3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":video_game:" src="/twemoji/assets/svg/1f3ae.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":slot_machine:" src="/twemoji/assets/svg/1f3b0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":jigsaw:" src="/twemoji/assets/svg/1f9e9.svg" /></span>
                        </div>
                        <div id="emoji-travel" className="picker-emoji picker-emoji-content emoji-travel">
                            <span><img draggable="false" className="emoji" alt=":red_car:" src="/twemoji/assets/svg/1f697.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":taxi:" src="/twemoji/assets/svg/1f695.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blue_car:" src="/twemoji/assets/svg/1f699.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bus:" src="/twemoji/assets/svg/1f68c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":trolleybus:" src="/twemoji/assets/svg/1f68e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":race_car:" src="/twemoji/assets/svg/1f3ce.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":police_car:" src="/twemoji/assets/svg/1f693.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ambulance:" src="/twemoji/assets/svg/1f691.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fire_engine:" src="/twemoji/assets/svg/1f692.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":minibus:" src="/twemoji/assets/svg/1f690.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":truck:" src="/twemoji/assets/svg/1f69a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":articulated_lorry:" src="/twemoji/assets/svg/1f69b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tractor:" src="/twemoji/assets/svg/1f69c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":probing_cane:" src="/twemoji/assets/svg/1f9af.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":crutch:" src="/twemoji/assets/svg/1fa7c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":manual_wheelchair:" src="/twemoji/assets/svg/1f9bd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":motorized_wheelchair:" src="/twemoji/assets/svg/1f9bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":scooter:" src="/twemoji/assets/svg/1f6f4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bike:" src="/twemoji/assets/svg/1f6b2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":motor_scooter:" src="/twemoji/assets/svg/1f6f5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":motorcycle:" src="/twemoji/assets/svg/1f3cd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":auto_rickshaw:" src="/twemoji/assets/svg/1f6fa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wheel:" src="/twemoji/assets/svg/1f6de.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rotating_light:" src="/twemoji/assets/svg/1f6a8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":oncoming_police_car:" src="/twemoji/assets/svg/1f694.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":oncoming_bus:" src="/twemoji/assets/svg/1f68d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":oncoming_automobile:" src="/twemoji/assets/svg/1f698.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":oncoming_taxi:" src="/twemoji/assets/svg/1f696.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":aerial_tramway:" src="/twemoji/assets/svg/1f6a1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mountain_cableway:" src="/twemoji/assets/svg/1f6a0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":suspension_railway:" src="/twemoji/assets/svg/1f69f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":railway_car:" src="/twemoji/assets/svg/1f683.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":train:" src="/twemoji/assets/svg/1f68b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mountain_railway:" src="/twemoji/assets/svg/1f69e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":monorail:" src="/twemoji/assets/svg/1f69d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bullettrain_side:" src="/twemoji/assets/svg/1f684.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bullettrain_front:" src="/twemoji/assets/svg/1f685.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":light_rail:" src="/twemoji/assets/svg/1f688.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":steam_locomotive:" src="/twemoji/assets/svg/1f682.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":train2:" src="/twemoji/assets/svg/1f686.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":metro:" src="/twemoji/assets/svg/1f687.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tram:" src="/twemoji/assets/svg/1f68a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":station:" src="/twemoji/assets/svg/1f689.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":airplane:" src="/twemoji/assets/svg/2708.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":airplane_departure:" src="/twemoji/assets/svg/1f6eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":airplane_arriving:" src="/twemoji/assets/svg/1f6ec.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":airplane_small:" src="/twemoji/assets/svg/1f6e9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":seat:" src="/twemoji/assets/svg/1f4ba.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":satellite_orbital:" src="/twemoji/assets/svg/1f6f0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rocket:" src="/twemoji/assets/svg/1f680.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":flying_saucer:" src="/twemoji/assets/svg/1f6f8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":helicopter:" src="/twemoji/assets/svg/1f681.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":canoe:" src="/twemoji/assets/svg/1f6f6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sailboat:" src="/twemoji/assets/svg/26f5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":speedboat:" src="/twemoji/assets/svg/1f6a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":motorboat:" src="/twemoji/assets/svg/1f6e5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cruise_ship:" src="/twemoji/assets/svg/1f6f3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ferry:" src="/twemoji/assets/svg/26f4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ship:" src="/twemoji/assets/svg/1f6a2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ring_buoy:" src="/twemoji/assets/svg/1f6df.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":anchor:" src="/twemoji/assets/svg/2693.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hook:" src="/twemoji/assets/svg/1fa9d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fuelpump:" src="/twemoji/assets/svg/26fd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":construction:" src="/twemoji/assets/svg/1f6a7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":vertical_traffic_light:" src="/twemoji/assets/svg/1f6a6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":traffic_light:" src="/twemoji/assets/svg/1f6a5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":busstop:" src="/twemoji/assets/svg/1f68f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":map:" src="/twemoji/assets/svg/1f5fa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":moyai:" src="/twemoji/assets/svg/1f5ff.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":statue_of_liberty:" src="/twemoji/assets/svg/1f5fd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tokyo_tower:" src="/twemoji/assets/svg/1f5fc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":european_castle:" src="/twemoji/assets/svg/1f3f0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":japanese_castle:" src="/twemoji/assets/svg/1f3ef.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stadium:" src="/twemoji/assets/svg/1f3df.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ferris_wheel:" src="/twemoji/assets/svg/1f3a1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":roller_coaster:" src="/twemoji/assets/svg/1f3a2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":carousel_horse:" src="/twemoji/assets/svg/1f3a0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fountain:" src="/twemoji/assets/svg/26f2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beach_umbrella:" src="/twemoji/assets/svg/26f1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beach:" src="/twemoji/assets/svg/1f3d6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":island:" src="/twemoji/assets/svg/1f3dd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":desert:" src="/twemoji/assets/svg/1f3dc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":volcano:" src="/twemoji/assets/svg/1f30b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mountain:" src="/twemoji/assets/svg/26f0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mountain_snow:" src="/twemoji/assets/svg/1f3d4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mount_fuji:" src="/twemoji/assets/svg/1f5fb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":camping:" src="/twemoji/assets/svg/1f3d5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tent:" src="/twemoji/assets/svg/26fa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":house:" src="/twemoji/assets/svg/1f3e0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":house_with_garden:" src="/twemoji/assets/svg/1f3e1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":homes:" src="/twemoji/assets/svg/1f3d8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":house_abandoned:" src="/twemoji/assets/svg/1f3da.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hut:" src="/twemoji/assets/svg/1f6d6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":construction_site:" src="/twemoji/assets/svg/1f3d7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":factory:" src="/twemoji/assets/svg/1f3ed.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":office:" src="/twemoji/assets/svg/1f3e2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":department_store:" src="/twemoji/assets/svg/1f3ec.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":post_office:" src="/twemoji/assets/svg/1f3e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":european_post_office:" src="/twemoji/assets/svg/1f3e4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hospital:" src="/twemoji/assets/svg/1f3e5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bank:" src="/twemoji/assets/svg/1f3e6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hotel:" src="/twemoji/assets/svg/1f3e8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":convenience_store:" src="/twemoji/assets/svg/1f3ea.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":school:" src="/twemoji/assets/svg/1f3eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":love_hotel:" src="/twemoji/assets/svg/1f3e9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wedding:" src="/twemoji/assets/svg/1f492.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":classical_building:" src="/twemoji/assets/svg/1f3db.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":church:" src="/twemoji/assets/svg/26ea.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mosque:" src="/twemoji/assets/svg/1f54c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":synagogue:" src="/twemoji/assets/svg/1f54d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hindu_temple:" src="/twemoji/assets/svg/1f6d5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":kaaba:" src="/twemoji/assets/svg/1f54b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":shinto_shrine:" src="/twemoji/assets/svg/26e9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":railway_track:" src="/twemoji/assets/svg/1f6e4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":motorway:" src="/twemoji/assets/svg/1f6e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":japan:" src="/twemoji/assets/svg/1f5fe.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rice_scene:" src="/twemoji/assets/svg/1f391.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":park:" src="/twemoji/assets/svg/1f3de.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sunrise:" src="/twemoji/assets/svg/1f305.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sunrise_over_mountains:" src="/twemoji/assets/svg/1f304.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stars:" src="/twemoji/assets/svg/1f320.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sparkler:" src="/twemoji/assets/svg/1f387.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fireworks:" src="/twemoji/assets/svg/1f386.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":city_sunset:" src="/twemoji/assets/svg/1f307.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":city_dusk:" src="/twemoji/assets/svg/1f306.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cityscape:" src="/twemoji/assets/svg/1f3d9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":night_with_stars:" src="/twemoji/assets/svg/1f303.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":milky_way:" src="/twemoji/assets/svg/1f30c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bridge_at_night:" src="/twemoji/assets/svg/1f309.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":foggy:" src="/twemoji/assets/svg/1f301.svg" /></span>
                        </div>
                        <div id="emoji-symbos" className="picker-emoji picker-emoji-content emoji-symbos">
                            <span><img draggable="false" className="emoji" alt=":heart:" src="/twemoji/assets/svg/2764.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":orange_heart:" src="/twemoji/assets/svg/1f9e1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":yellow_heart:" src="/twemoji/assets/svg/1f49b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":green_heart:" src="/twemoji/assets/svg/1f49a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blue_heart:" src="/twemoji/assets/svg/1f499.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":light_blue_heart:" src="/twemoji/assets/svg/1f49c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":brown_heart:" src="/twemoji/assets/svg/1f90e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_heart:" src="/twemoji/assets/svg/1f5a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_heart:" src="/twemoji/assets/svg/1f90d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":broken_heart:" src="/twemoji/assets/svg/1f494.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heart_exclamation:" src="/twemoji/assets/svg/2763.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":two_hearts:" src="/twemoji/assets/svg/1f495.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":revolving_hearts:" src="/twemoji/assets/svg/1f49e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heartbeat:" src="/twemoji/assets/svg/1f493.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heartpulse:" src="/twemoji/assets/svg/1f497.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sparkling_heart:" src="/twemoji/assets/svg/1f496.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cupid:" src="/twemoji/assets/svg/1f498.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":gift_heart:" src="/twemoji/assets/svg/1f49d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mending_heart:" src="/twemoji/assets/svg/2764-fe0f-200d-1fa79.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heart_on_fire:" src="/twemoji/assets/svg/2764-fe0f-200d-1f525.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heart_decoration:" src="/twemoji/assets/svg/1f49f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":peace_symbol:" src="/twemoji/assets/svg/262e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":latin_cross:" src="/twemoji/assets/svg/271d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":star_and_crescent:" src="/twemoji/assets/svg/262a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":om_symbol:" src="/twemoji/assets/svg/1f549.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wheel_of_dharma:" src="/twemoji/assets/svg/2638.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":star_of_david:" src="/twemoji/assets/svg/2721.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":six_pointed_star:" src="/twemoji/assets/svg/1f52f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":menorah:" src="/twemoji/assets/svg/1f54e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":yin_yang:" src="/twemoji/assets/svg/262f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":orthodox_cross:" src="/twemoji/assets/svg/2626.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":place_off_worship:" src="/twemoji/assets/svg/1f6d0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ophiuchus:" src="/twemoji/assets/svg/26ce.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":aries:" src="/twemoji/assets/svg/2648.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":taurus:" src="/twemoji/assets/svg/2649.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":gemini:" src="/twemoji/assets/svg/264a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cancer:" src="/twemoji/assets/svg/264b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":leo:" src="/twemoji/assets/svg/264c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":virgo:" src="/twemoji/assets/svg/264d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":libra:" src="/twemoji/assets/svg/264e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":scorpius:" src="/twemoji/assets/svg/264f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sagittarius:" src="/twemoji/assets/svg/2650.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":capricorn:" src="/twemoji/assets/svg/2651.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":aquarius:" src="/twemoji/assets/svg/2652.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pisces:" src="/twemoji/assets/svg/2653.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":id:" src="/twemoji/assets/svg/1f194.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":atom_symbol:" src="/twemoji/assets/svg/269b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":accept:" src="/twemoji/assets/svg/1f251.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":radioactive_sign:" src="/twemoji/assets/svg/2622.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":biohazard_sign:" src="/twemoji/assets/svg/2623.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mobile_phone_off:" src="/twemoji/assets/svg/1f4f4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":vibration_off:" src="/twemoji/assets/svg/1f4f3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u6709:" src="/twemoji/assets/svg/1f236.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u7121:" src="/twemoji/assets/svg/1f21a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u7533:" src="/twemoji/assets/svg/1f238.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u55b6:" src="/twemoji/assets/svg/1f23a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u6708:" src="/twemoji/assets/svg/1f237.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eight_pointed_black_stars:" src="/twemoji/assets/svg/2734.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":vs:" src="/twemoji/assets/svg/1f19a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_flower:" src="/twemoji/assets/svg/1f4ae.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ideograph_advantage:" src="/twemoji/assets/svg/1f250.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":secret:" src="/twemoji/assets/svg/3299.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":congratulations:" src="/twemoji/assets/svg/3297.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u5408:" src="/twemoji/assets/svg/1f234.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u6e80:" src="/twemoji/assets/svg/1f235.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u5272:" src="/twemoji/assets/svg/1f239.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u7981:" src="/twemoji/assets/svg/1f232.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":a:" src="/twemoji/assets/svg/1f170.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":b:" src="/twemoji/assets/svg/1f171.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ab:" src="/twemoji/assets/svg/1f18e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cl:" src="/twemoji/assets/svg/1f191.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":o2:" src="/twemoji/assets/svg/1f17e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sos:" src="/twemoji/assets/svg/1f198.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":x:" src="/twemoji/assets/svg/274c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":o:" src="/twemoji/assets/svg/2b55.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":octagonal_sign:" src="/twemoji/assets/svg/1f6d1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_entry:" src="/twemoji/assets/svg/26d4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":name_badge:" src="/twemoji/assets/svg/1f4db.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_entry_sign:" src="/twemoji/assets/svg/1f6ab.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":100:" src="/twemoji/assets/svg/1f4af.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":anger:" src="/twemoji/assets/svg/1f4a2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hot_springs:" src="/twemoji/assets/svg/2668.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_pedestrians:" src="/twemoji/assets/svg/1f6b7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":do_not_litter:" src="/twemoji/assets/svg/1f6af.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_bicycles:" src="/twemoji/assets/svg/1f6b3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":non_potable_water:" src="/twemoji/assets/svg/1f6b1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":underage:" src="/twemoji/assets/svg/1f51e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_mobile_phones:" src="/twemoji/assets/svg/1f4f5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_smoking:" src="/twemoji/assets/svg/1f6ad.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":exclamation:" src="/twemoji/assets/svg/2757.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":grey_exclamation:" src="/twemoji/assets/svg/2755.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":question:" src="/twemoji/assets/svg/2753.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":grey_question:" src="/twemoji/assets/svg/2754.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bangbang:" src="/twemoji/assets/svg/203c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":interrobang:" src="/twemoji/assets/svg/2049.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":low_brightness:" src="/twemoji/assets/svg/1f505.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":high_brightness:" src="/twemoji/assets/svg/1f506.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":part_alternation_mark:" src="/twemoji/assets/svg/303d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":warning:" src="/twemoji/assets/svg/26a0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":children_crossing:" src="/twemoji/assets/svg/1f6b8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":trident:" src="/twemoji/assets/svg/1f531.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fleur_de_lis:" src="/twemoji/assets/svg/269c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":beginner:" src="/twemoji/assets/svg/1f530.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":recycle:" src="/twemoji/assets/svg/267b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_check_mark:" src="/twemoji/assets/svg/2705.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u6307:" src="/twemoji/assets/svg/1f22f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":chart:" src="/twemoji/assets/svg/1f4b9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sparkle:" src="/twemoji/assets/svg/2747.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eight_spoked_asterisk:" src="/twemoji/assets/svg/2733.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":negative_squared_cross_mark:" src="/twemoji/assets/svg/274e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":globe_with_meridians:" src="/twemoji/assets/svg/1f310.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":diamond_shape_with_a_dot_inside:" src="/twemoji/assets/svg/1f4a0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":m:" src="/twemoji/assets/svg/24c2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cyclone:" src="/twemoji/assets/svg/1f300.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":zzz:" src="/twemoji/assets/svg/1f4a4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":atm:" src="/twemoji/assets/svg/1f3e7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wc:" src="/twemoji/assets/svg/1f6be.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wheelchair:" src="/twemoji/assets/svg/267f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":parking:" src="/twemoji/assets/svg/1f17f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":u7a7a:" src="/twemoji/assets/svg/1f233.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sa:" src="/twemoji/assets/svg/1f202.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":passport_control:" src="/twemoji/assets/svg/1f6c2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":customs:" src="/twemoji/assets/svg/1f6c3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":baggage_claim:" src="/twemoji/assets/svg/1f6c4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":left_luggage:" src="/twemoji/assets/svg/1f6c5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":elevator:" src="/twemoji/assets/svg/1f6d7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mens:" src="/twemoji/assets/svg/1f6b9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":womens:" src="/twemoji/assets/svg/1f6ba.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":baby_symbol:" src="/twemoji/assets/svg/1f6bc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":restroom:" src="/twemoji/assets/svg/1f6bb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":put_litter_in_its_place:" src="/twemoji/assets/svg/1f6ae.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cinema:" src="/twemoji/assets/svg/1f3a6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":signal_strength:" src="/twemoji/assets/svg/1f4f6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":koko:" src="/twemoji/assets/svg/1f201.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":symbols:" src="/twemoji/assets/svg/1f523.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":information_source:" src="/twemoji/assets/svg/2139.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":abc:" src="/twemoji/assets/svg/1f524.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":abcd:" src="/twemoji/assets/svg/1f521.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":capital_abcd:" src="/twemoji/assets/svg/1f520.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ng:" src="/twemoji/assets/svg/1f196.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ok:" src="/twemoji/assets/svg/1f197.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":up:" src="/twemoji/assets/svg/1f199.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":cool:" src="/twemoji/assets/svg/1f192.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":new:" src="/twemoji/assets/svg/1f195.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":free:" src="/twemoji/assets/svg/1f193.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":zero:" src="/twemoji/assets/svg/30-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":one:" src="/twemoji/assets/svg/31-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":two:" src="/twemoji/assets/svg/32-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":three:" src="/twemoji/assets/svg/33-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":four:" src="/twemoji/assets/svg/34-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":five:" src="/twemoji/assets/svg/35-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":six:" src="/twemoji/assets/svg/36-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":seven:" src="/twemoji/assets/svg/37-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eight:" src="/twemoji/assets/svg/38-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":nine:" src="/twemoji/assets/svg/39-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":keycap_ten:" src="/twemoji/assets/svg/1f51f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":1234:" src="/twemoji/assets/svg/1f522.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hash:" src="/twemoji/assets/svg/23-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":asterisk:" src="/twemoji/assets/svg/2a-20e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eject_symbol:" src="/twemoji/assets/svg/23cf.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_forward:" src="/twemoji/assets/svg/25b6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":pause_button:" src="/twemoji/assets/svg/23f8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":play_pause:" src="/twemoji/assets/svg/23ef.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":stop_button:" src="/twemoji/assets/svg/23f9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":record_button:" src="/twemoji/assets/svg/23fa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":next_track:" src="/twemoji/assets/svg/23ed.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":previous_track:" src="/twemoji/assets/svg/23ee.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":fast_forward:" src="/twemoji/assets/svg/23e9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":rewind:" src="/twemoji/assets/svg/23ea.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_double_up:" src="/twemoji/assets/svg/23eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_double_down:" src="/twemoji/assets/svg/23ec.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_backward:" src="/twemoji/assets/svg/25c0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_up_small:" src="/twemoji/assets/svg/1f53c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_down_small:" src="/twemoji/assets/svg/1f53d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_right:" src="/twemoji/assets/svg/27a1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_left:" src="/twemoji/assets/svg/2b05.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_up:" src="/twemoji/assets/svg/2b06.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_down:" src="/twemoji/assets/svg/2b07.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_upper_right:" src="/twemoji/assets/svg/2197.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_lower_right:" src="/twemoji/assets/svg/2198.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_lower_left:" src="/twemoji/assets/svg/2199.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_upper_left:" src="/twemoji/assets/svg/2196.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_up_down:" src="/twemoji/assets/svg/2195.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":left_right_arrow:" src="/twemoji/assets/svg/2194.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_right_hook:" src="/twemoji/assets/svg/21aa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":leftwards_arrow_with_hook:" src="/twemoji/assets/svg/21a9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_heading_up:" src="/twemoji/assets/svg/2934.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrow_heading_down:" src="/twemoji/assets/svg/2935.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":twisted_rightwards_arrows:" src="/twemoji/assets/svg/1f500.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":repeat:" src="/twemoji/assets/svg/1f501.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":repeat_one:" src="/twemoji/assets/svg/1f502.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrows_counterclockwise:" src="/twemoji/assets/svg/1f504.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":arrows_clockwise:" src="/twemoji/assets/svg/1f503.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":musical_note:" src="/twemoji/assets/svg/1f3b5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":notes:" src="/twemoji/assets/svg/1f3b6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heavy_plus_sign:" src="/twemoji/assets/svg/2795.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heavy_minus_sign:" src="/twemoji/assets/svg/2796.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heavy_division_sign:" src="/twemoji/assets/svg/2797.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heavy_multiplication_x:" src="/twemoji/assets/svg/2716.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heavy_equals_sign:" src="/twemoji/assets/svg/1f7f0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":infinity:" src="/twemoji/assets/svg/267e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heavy_dollar_sign:" src="/twemoji/assets/svg/1f4b2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":currency_exchange:" src="/twemoji/assets/svg/1f4b1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tm:" src="/twemoji/assets/svg/2122.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":copyright:" src="/twemoji/assets/svg/a9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":registered:" src="/twemoji/assets/svg/ae.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":wavy_dash:" src="/twemoji/assets/svg/3030.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":curly_loop:" src="/twemoji/assets/svg/27b0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":loop:" src="/twemoji/assets/svg/27bf.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":end:" src="/twemoji/assets/svg/1f51a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":back:" src="/twemoji/assets/svg/1f519.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":on:" src="/twemoji/assets/svg/1f51b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":top:" src="/twemoji/assets/svg/1f51d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":soon:" src="/twemoji/assets/svg/1f51c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":heavy_check_mark:" src="/twemoji/assets/svg/2714.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":ballot_box_with_check:" src="/twemoji/assets/svg/2611.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":radio_button:" src="/twemoji/assets/svg/1f518.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_circle:" src="/twemoji/assets/svg/26aa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_circle:" src="/twemoji/assets/svg/26ab.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":brown_circle:" src="/twemoji/assets/svg/1f7e4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":red_circle:" src="/twemoji/assets/svg/1f534.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":orange_circle:" src="/twemoji/assets/svg/1f7e0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":yellow_circle:" src="/twemoji/assets/svg/1f7e1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":green_circle:" src="/twemoji/assets/svg/1f7e2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blue_circle:" src="/twemoji/assets/svg/1f535.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":purple_circle:" src="/twemoji/assets/svg/1f7e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":small_red_triangle:" src="/twemoji/assets/svg/1f53a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":small_red_triangle_down:" src="/twemoji/assets/svg/1f53b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":small_orange_diamond:" src="/twemoji/assets/svg/1f538.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":small_blue_diamond:" src="/twemoji/assets/svg/1f539.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":large_orange_diamond:" src="/twemoji/assets/svg/1f536.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":large_blue_diamond:" src="/twemoji/assets/svg/1f537.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_square_button:" src="/twemoji/assets/svg/1f533.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_square_button:" src="/twemoji/assets/svg/1f532.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_small_square:" src="/twemoji/assets/svg/25aa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_small_square:" src="/twemoji/assets/svg/25ab.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_medium_small_square:" src="/twemoji/assets/svg/25fe.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_medium_small_square:" src="/twemoji/assets/svg/25fd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_medium_square:" src="/twemoji/assets/svg/25fc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_medium_square:" src="/twemoji/assets/svg/25fb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_large_square:" src="/twemoji/assets/svg/2b1b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":white_large_square:" src="/twemoji/assets/svg/2b1c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":brown_square:" src="/twemoji/assets/svg/1f7eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":red_square:" src="/twemoji/assets/svg/1f7e5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":orange_square:" src="/twemoji/assets/svg/1f7e7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":yellow_square:" src="/twemoji/assets/svg/1f7e8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":green_square:" src="/twemoji/assets/svg/1f7e9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":blue_square:" src="/twemoji/assets/svg/1f7e6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":purple_square:" src="/twemoji/assets/svg/1f7ea.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":speaker:" src="/twemoji/assets/svg/1f508.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mute:" src="/twemoji/assets/svg/1f507.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":sound:" src="/twemoji/assets/svg/1f509.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":loud_sound:" src="/twemoji/assets/svg/1f50a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":bell:" src="/twemoji/assets/svg/1f514.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":no_bell:" src="/twemoji/assets/svg/1f515.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mega:" src="/twemoji/assets/svg/1f4e3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":loud_speaker:" src="/twemoji/assets/svg/1f4e2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":left_speech_bubble:" src="/twemoji/assets/svg/1f5e8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":eye_in_speech_bubble:" src="/twemoji/assets/svg/1f441-200d-1f5e8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":speech_balloon:" src="/twemoji/assets/svg/1f4ac.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":tought_balloon:" src="/twemoji/assets/svg/1f4ad.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":right_anger_bubble:" src="/twemoji/assets/svg/1f5ef.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":spads:" src="/twemoji/assets/svg/2660.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clubs:" src="/twemoji/assets/svg/2663.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":hearts:" src="/twemoji/assets/svg/2665.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":diamonds:" src="/twemoji/assets/svg/2666.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":black_joker:" src="/twemoji/assets/svg/1f0cf.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":flower_playing_cards:" src="/twemoji/assets/svg/1f3b4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":mahjong:" src="/twemoji/assets/svg/1f004.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock1:" src="/twemoji/assets/svg/1f550.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock2:" src="/twemoji/assets/svg/1f551.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock3:" src="/twemoji/assets/svg/1f552.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock4:" src="/twemoji/assets/svg/1f553.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock5:" src="/twemoji/assets/svg/1f554.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock6:" src="/twemoji/assets/svg/1f555.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock7:" src="/twemoji/assets/svg/1f556.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock8:" src="/twemoji/assets/svg/1f557.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock9:" src="/twemoji/assets/svg/1f558.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock10:" src="/twemoji/assets/svg/1f559.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock11:" src="/twemoji/assets/svg/1f55a.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock12:" src="/twemoji/assets/svg/1f55b.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock130:" src="/twemoji/assets/svg/1f55c.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock230:" src="/twemoji/assets/svg/1f55d.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock330:" src="/twemoji/assets/svg/1f55e.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock430:" src="/twemoji/assets/svg/1f55f.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock530:" src="/twemoji/assets/svg/1f560.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock630:" src="/twemoji/assets/svg/1f561.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock730:" src="/twemoji/assets/svg/1f562.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock830:" src="/twemoji/assets/svg/1f563.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock930:" src="/twemoji/assets/svg/1f564.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock1030:" src="/twemoji/assets/svg/1f565.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock1130:" src="/twemoji/assets/svg/1f566.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":clock1230:" src="/twemoji/assets/svg/1f567.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":female_sign:" src="/twemoji/assets/svg/2640.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":male_sign:" src="/twemoji/assets/svg/2642.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":transgender_symbol:" src="/twemoji/assets/svg/26a7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":medical_symbol:" src="/twemoji/assets/svg/2695.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_z:" src="/twemoji/assets/svg/1f1ff.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_y:" src="/twemoji/assets/svg/1f1fe.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_x:" src="/twemoji/assets/svg/1f1fd.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_w:" src="/twemoji/assets/svg/1f1fc.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_v:" src="/twemoji/assets/svg/1f1fb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_u:" src="/twemoji/assets/svg/1f1fa.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_t:" src="/twemoji/assets/svg/1f1f9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_s:" src="/twemoji/assets/svg/1f1f8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_r:" src="/twemoji/assets/svg/1f1f7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_q:" src="/twemoji/assets/svg/1f1f6.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_p:" src="/twemoji/assets/svg/1f1f5.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_o:" src="/twemoji/assets/svg/1f1f4.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_n:" src="/twemoji/assets/svg/1f1f3.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_m:" src="/twemoji/assets/svg/1f1f2.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_l:" src="/twemoji/assets/svg/1f1f1.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_k:" src="/twemoji/assets/svg/1f1f0.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_j:" src="/twemoji/assets/svg/1f1ef.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_i:" src="/twemoji/assets/svg/1f1ee.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_h:" src="/twemoji/assets/svg/1f1ed.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_g:" src="/twemoji/assets/svg/1f1ec.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_f:" src="/twemoji/assets/svg/1f1eb.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_e:" src="/twemoji/assets/svg/1f1ea.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_d:" src="/twemoji/assets/svg/1f1e9.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_c:" src="/twemoji/assets/svg/1f1e8.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_b:" src="/twemoji/assets/svg/1f1e7.svg" /></span>
                            <span><img draggable="false" className="emoji" alt=":regional_indicator_a:" src="/twemoji/assets/svg/1f1e6.svg" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        </>
    )
}*/

const EmojiPicker = ({claimed}) => {
    const isCaseClaimed = (caseId) => claimed.includes(caseId);

    // FUNCTIONS
    const insertAtCursor = (element) => {
        const selection = window.getSelection();
        const range = document.createRange();

        const editableDiv = document.getElementById('content');
        if (editableDiv.lastChild) {
            range.setStart(editableDiv.lastChild, editableDiv.lastChild.length);
            range.setEnd(editableDiv.lastChild, editableDiv.lastChild.length);
        } else {
            range.setStart(editableDiv, 0);
            range.setEnd(editableDiv, 0);
        }
        selection.removeAllRanges();
        selection.addRange(range);
    
        range.deleteContents();
        range.insertNode(element);

        if (editableDiv.lastChild && editableDiv.lastChild.tagName === 'BR') {
            editableDiv.removeChild(editableDiv.lastChild);
        }

        const newRange = document.createRange();
        newRange.setStartAfter(element);
        newRange.setEndAfter(element);
    
        selection.removeAllRanges();
        selection.addRange(newRange);
    }

    // EMOJI HANDLING
    useEffect(() => {
        $('.selector_lNavPicker').off('click').on('click', (e) => {
            document.querySelector('.elements_pickerPcVB1').scrollTo(0, document.getElementById('emoji-'+e.currentTarget.getAttribute('data-emoji-category')).offsetTop - 10);
        });

        const emoji = $('.elements > img');
        emoji.each((index, el) => {
            $(el).off('click').on('click', (e) => {
                const editableDiv = document.getElementById('content');
                editableDiv.focus();

                const img = document.createElement('img');
                img.src = e.currentTarget.getAttribute('src');
                img.alt = e.currentTarget.getAttribute('alt');
                img.className = "emojiText"
                
                insertAtCursor(img);
                insertAtCursor(document.createTextNode(' '));
            });
        });
    }, [claimed])

    return (
        <aside id="emojiPicker" className="menu" aria-hidden={true} aria-modal={false} style={{display: 'none'}}>
            <div className="menu-wrapper js-menu-stop" style={{maxWidth: 'none', height: 400, width: 410}}>
                <div className="topNav_pickerPcVB1">
                    <div className="selector_topNavPicker">
                        <span>EMOJI</span>
                    </div>
                </div>
                <div className="lateralNav_pickerPcVB1">
                    {(isCaseClaimed(0) || isCaseClaimed(3)) && (
                        <div className="selector_lNavPicker" data-emoji-category="xmas-bears">
                            <img src={bearGift} alt="XMAS'24 Bears" title="XMAS'24 Bears" />
                        </div>
                    )}
                    {(isCaseClaimed(2) || isCaseClaimed(4)) && (
                        <div className="selector_lNavPicker" data-emoji-category="xmas-dogs">
                            <img src={dogDisguised} alt="XMAS'24 Dogs" title="XMAS'24 Dogs" />
                        </div>
                    )}
                    {isCaseClaimed(5) && (
                        <div className="selector_lNavPicker" data-emoji-category="cute-bears">
                            <img src={pBear} alt="Cute Bears" title="Cute Bears" />
                        </div>
                    )}
                </div>
                <div className="elements_pickerPcVB1">
                    {(isCaseClaimed(0) || isCaseClaimed(3)) && (
                        <div id="emoji-xmas-bears" style={{display:'flex',flexDirection:'column',marginBottom: 20}}>
                            <p className="category">XMAS'24 Bears</p>
                            <div className="elements">
                                {isCaseClaimed(0) && (
                                    <img src={bearGift} alt=':bear_gift:' title='Bear with a gift' />
                                )}
                                {isCaseClaimed(3) && (<>
                                    <img src={bear1} alt=':bear_1:' title='Bear with something?' />
                                    <img src={bearSurprise} alt=':bear_surprise:' title='Surprise bear' />
                                    <img src={bearRun} alt=':bear_run:' title='Running bear' />
                                    <img src={bearBauble} alt=':bear_bauble:' title='Bears in xmas bauble' />
                                    <img src={bearHeart} alt=':bear_heart:' title='Bear heart' />
                               </>)}
                            </div>
                            <a href="https://emoji.gg/user/notjour" target='_blank' rel="noreferrer" className="copyright">© Jour - emoji.gg</a>
                        </div>
                    )}
                    {(isCaseClaimed(2) || isCaseClaimed(4)) && (
                        <div id="emoji-xmas-dogs" style={{display:'flex',flexDirection:'column',marginBottom: 20}}>
                            <p className="category">XMAS'24 Dogs</p>
                            <div className="elements">
                                {isCaseClaimed(2) && (
                                    <img src={dogDisguised} alt=':dog_disguised:' title='Disguised dog' />
                                )}
                                {isCaseClaimed(4) && (<>
                                    <img src={dogSnowman} alt=':dog_snowman:' title='Snowman dog' />
                                    <img src={dogSanta} alt=':dog_santa:' title='Santa dog' />
                                    <img src={dogGift} alt=':dog_gift:' title='Surprise gift puppy' />
                                    <img src={dogElf} alt=':dog_elf:' title='Elf dog' />
                                    <img src={dogReindeer} alt=':dog_reindeer:' title='Reindeer dog' />
                                </>)}
                            </div>
                            <a href="https://emoji.gg/user/notjour" target='_blank' rel="noreferrer" className="copyright">© Jour - emoji.gg</a>
                        </div>
                    )}
                    {isCaseClaimed(5) && (
                        <div id="emoji-cute-bears" style={{display:'flex',flexDirection:'column',marginBottom: 20}}>
                            <p className="category">Cute Bears</p>
                            <div className="elements">
                                <img src={pBear} alt=':polar_bear:' title='Polar bear' />
                                <img src={pBearTired} alt=':pbear_tired:' title='Tired polar bear' />
                                <img src={pBearSolo} alt=':pbear_solo:' title='Lonely polar bear' />
                                <img src={pBearPlaying} alt=':pbear_playing:' title='A polar bear playing' />
                                <img src={pBearSweating} alt=':pbear_sweating:' title='Sweating polar bear' />
                                <img src={pBearPinguin} alt=':pbear_pinguin:' title='Pinguin & polar bear <3' />
                            </div>
                            <a href="https://www.flaticon.com/authors/paulalee" title="Polar bear icons" target='_blank' rel="noreferrer" className="copyright">© paulalee - Flaticon</a>
                        </div>
                    )}
                </div>
            </div>
        </aside>
    )
}

export default EmojiPicker;