import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import Cookies from "js-cookie";
import copy from 'copy-to-clipboard';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";
import { retreiveInfos } from "../../Functions/Auth";

export const ShareMenu = ({user, postUser, uid, logged, theme, session}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    // Set data
    const [actionShare, setShareEvent] = useState('share');

    // Know if [user] already shared [post]
    const verifyShare = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/actions/${uid}/verify/share`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.count);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    /* ----------------
     * ACTIONS FUNCTION
     * ----------------
     */
    const action = (e, postUid, actionUser, type, shareStatus=undefined) => {
        if (logged === 1) {
            if(type === 'share'){
                const shareCount = document.querySelector('.share_'+uid+' span');
                let shares = shareCount.textContent;
                shares++;

                shareCount.innerHTML = shares;
                $('.share_'+uid).addClass('shared');
                $('.share_'+uid).removeClass('unshare');
                // Set shared for click event
                setShareEvent('shared');

                // Add share
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${actionUser}/actions/${postUid}/add/${type}`,
                    { post_user: postUser },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            token: Cookies.get('loginToken'),
                            app: 'web'
                        }
                    }
                );
            }
        }
    }

    useEffect(() => {
        // Retreive my user infos
        if(logged === 1){
            retreiveInfos().then(res => {
                // Check if [user] already shared
                verifyShare(res.uid).then(r => {
                    if(r >= 1) setShareEvent("shared");      
                })
            })
        }
    }, [logged])

    useEffect(() => {
        // EVENT LISTENERS
        // -- FOR INTEGRATE
        $('#integrate').off('click')
        $('#integrate').on('click', () => navigate('/integrate?url='+uid))
        // -- FOR COPY
        $('.shareMenu_'+uid).off('click')
        $('.shareMenu_'+uid).on('click', (e) => {
            copy('https://snot.fr'+location.pathname);
            // UI
            $(".shareMenu_"+uid+" span").text(t('general.copied'));
            setTimeout(() => {
                if($(".shareMenu_"+uid)){
                    $(".shareMenu_"+uid+' span').text(t('comment.share'))
                }
            }, 2000)
            // ...
            action(e, uid, user, actionShare);
        })
    }, [logged, user, actionShare])

    return (
        <>
            {/* SHARE MENU */}
            <aside id="shareBM" className={"menu "+theme} aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className="menu-wrapper js-menu-stop">
                    <div className="actionsPart_spostMMVB1">
                        <div id="copy" className={"shareMenu_"+uid} data-modal="loginModal">
                            <span data-modal="loginModal">{t('home.general.share.copy')}</span>
                            <i className="fi fi-rr-copy-alt" data-modal="loginModal"></i>
                        </div>
                        <div id="integrate"><span>{t('home.general.share.integrate')}</span><i className="fi fi-rr-code-simple"></i></div>
                    </div>
                </div>
            </aside>
        </>
    )
}