import { Helmet } from "react-helmet";
// import Posts loading
import MessageLoading from "../LoadingMessages";
import { LoadingAppBar, LoadingNavBar } from "../Common/Loading";

export const LoadingChat = (key) => {
    document.body.className = "darkTheme";

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://snot.fr/chat/"+key} />
                <meta name="robots" content="noindex,nofollow"/>
                <meta property="og:title" content='SNOT - Chat @user.'/>
                <meta name="twitter:title" content='SNOT - Chat @user.'/>
                <title>SNOT - Chat @user.</title>
            </Helmet>
            <LoadingAppBar />
            <LoadingNavBar />
            <section className="topMenu_pcverSB1 darkTheme">
                <div className="others_fBmVeB1 darkTheme">
                    <div id="latest" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="subs" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="trending" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
                <div className="separator_fBmVeB1 darkTheme"></div>
                <div className="filter_fBmVeB1 darkTheme">
                    <h1 className="skeleton darkTheme" style={{ height: 25, borderRadius: 5, width: "calc(100% - 50px)" }}></h1>
                    <div className="subs_fMaMVB1 current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
            </section>
            <main id="friendsPart" style={{ overflow: "hidden", position: "absolute" }}>
                <div id="friendsMain">
                    <div className="topPart_friendsMMVB1 darkTheme" style={{ height: 60 }}>
                        <h1 className="skeleton darkTheme" style={{ height: 40, width: 200, borderRadius: 5, margin: "20px 0" }}></h1>
                    </div>
                    <div className="mainPart_friendsMMVB1 darkTheme">
                        <MessageLoading widthUser={150} widthUserTag={200} widthText={150} />
                        <MessageLoading widthUser={70} widthUserTag={90} widthText={290} />
                        <MessageLoading widthUser={150} widthUserTag={200} widthText={200} />
                        <MessageLoading widthUser={70} widthUserTag={90} widthText={75} />
                    </div>
                    <div className="typingIndicator_chatMMVB1 darkTheme" style={{display:"flex", alignItems: "center"}}>
                        <span className="skeleton darkTheme" style={{ height: 20, width: 300, borderRadius: 5 }}></span>
                    </div>
                    <div className="bottomBarActions_chatFMVB1 darkTheme">
                        <div className="fileButton_bBAChatMVB1 skeleton darkTheme"></div>
                        <div className="textArea_bBAChatMVB1 skeleton darkTheme"></div>
                        <div className="moreActions_bBAChatMVB1">
                            <div id="emoji" className="skeleton darkTheme"></div>
                            <div id="send" className="skeleton darkTheme"></div>
                        </div>
                    </div>
                </div>
            </main>
        </>
      )
}