export const LoadingSuggestions = ({theme}) => {
    return (<>
    <div className="accountPre_sRlBPcVB1" id='no-hover'>
        <div id="profile">
            <div className={"avatar skeleton "+theme} style={{width: 45, height: 45}}></div>
            <div id="user" style={{ justifyContent: 'center' }}>
                <span id="username" className={'user skeleton '+theme} style={{ width: 75, height: 18, marginBottom: 5 }}></span>
                <span id="usertag" className={'user skeleton '+theme} style={{ width: 100, height: 15 }}></span>
            </div>
        </div>
        <div id="follow"></div>
    </div>
    </>)
}