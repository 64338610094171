import { useEffect, useState } from 'react';
import $ from 'jquery';
import { FadeLoader } from 'react-spinners';
// import Images
import errorMediaDark from '../../../img/error/media_dark.png';
import errorMediaLight from '../../../img/error/media_light.png';

const Video = ({videoRef, videoSrc, params: { stopPropagation, comment=false, nsfw=false }}) => {
    // Set data
    const [playing, setPlay] = useState(false);
    const [playPauseIcon, setPlayPauseIcon] = useState('fi-sr-play');
    const [volumeIcon, setVolumeIcon] = useState('fi-ss-volume');
    const [duration, setDuration] = useState('0:00');
    const [timeCode, setTimeCode] = useState('0:00');
    // Set error
    const [error, setError] = useState(false);
    // Set loading state
    const [loading, setLoading] = useState(true);
    const [loadingTimeCode, setLoadingTimeCode] = useState(false);

    // FOR OVERLAY
    const [chrono, setChrono]= useState(null);
    
    const overlaySettings = (e) => {
        if(stopPropagation === true){
            e.stopPropagation();
        }
    }
    const mouseStop = () => {
        setChrono(null);
        $('.overlay.ref_'+videoRef).css('opacity', 0);
    }
    const mouseMove = () => {
        $('.overlay.ref_'+videoRef).css('opacity', 1);
        if (chrono != null){
            clearTimeout(chrono);
            setChrono(setTimeout(mouseStop, 5000));
        }else{
            setChrono(setTimeout(mouseStop, 5000));
        }
    }
    const mouseOut = () => {
        $('.overlay.ref_'+videoRef).css('opacity', 0);
        if (chrono!=null){
            clearTimeout(chrono);
            setChrono(null);
        }
    }

    // FOR FULLSCREEN MODE
    const fullScreen = () => {
        if (document.fullscreenElement !== null) {
            // The document is in fullscreen mode
            document.exitFullscreen();
            setFullscreenData(false);
        } else {
            // The document is not in fullscreen mode
            $('#attachMedia_divMSdB1.ref_'+videoRef).get(0).requestFullscreen();
            setFullscreenData(true);
            // CSS
            $('.videoContainer_videoAMB1.ref_'+videoRef).css({alignItems: "center",height: "100%"})
            $('.videoContainer_videoAMB1.ref_'+videoRef+' video').css({maxWidth:"none"});
        }
    }
    const setFullscreenData = (state) => {
        $('#attachMedia_divMSdB1.ref_'+videoRef).attr("data-fullscreen", !!state);
    }

    // FOR PLAY/PAUSE
    const togglePlayPause = () => {
        var playPromise = document.querySelector('video[class="ref_'+videoRef+'"]').play();
        const videoPlayer = document.querySelector('video[class="ref_'+videoRef+'"]');

        if(!playing){
            setPlay(true)
            setPlayPauseIcon('fi-sr-pause');
            videoPlayer.play();
        }else{
            setPlay(false)
            setPlayPauseIcon('fi-sr-play');
            if (playPromise !== undefined) {
                playPromise.then(_ => videoPlayer.pause())
                .catch(error => console.log(error));
            }            
        }
        // DISPLAY OVERLAY
        $('.overlay.ref_'+videoRef).css('opacity', 1);
        if (chrono !== null){
            clearTimeout(chrono);
            setChrono(setTimeout(mouseStop, 2500));
        }else{
            setChrono(setTimeout(mouseStop, 2500));
        }
    }

    // TOOGLE MUTE
    const toogleMute = () =>{
        const volumeSlider = document.querySelector('.ref_'+videoRef+' .volume-slider');
        const videoPlayer = document.querySelector('video[class="ref_'+videoRef+'"]');

        if (videoPlayer.muted) {
            videoPlayer.muted = false;
            volumeSlider.value = videoPlayer.volume;
        }else {
            videoPlayer.muted = true;
            volumeSlider.value = 0;
        }
    }

    // UPDATE PROGRESS BAR
    const updateProgressBar = (e) => {
        const progressBar = document.querySelector('.ref_'+videoRef+' .progressBar_bBarB1');
        const videoPlayer = document.querySelector('video[class="ref_'+videoRef+'"]');
        const progress = videoPlayer.currentTime / videoPlayer.duration;

        progressBar.value = duration * progress;
        if (progress === 1) {
            setPlayPauseIcon('fi-sr-play');
            setPlay(false);
        }
        // Update time code span
        setTimeCode(calculateTime(videoPlayer.currentTime));
    }
    // UPDATE VOLUME ICON
    const updateVolumeIcon = () => {
        const videoPlayer = document.querySelector('video[class="ref_'+videoRef+'"]');

        if (videoPlayer.muted || videoPlayer.volume === 0) {
            setVolumeIcon("fi-ss-volume-mute");
        }else if (videoPlayer.volume < 0.5) {
            setVolumeIcon("fi-ss-volume-down");
        } else {
            setVolumeIcon("fi-ss-volume");
        }
    }

    // GO TO SELECTED TIME CODE
    const awaitState = (video) => {
        return new Promise((resolve) => {
            setInterval(() => {
                if(video.readyState === 4){
                    resolve(video.readyState)
                }
            }, 500)
        });
    }

    const goTimecode = async (input) => {
        const videoPlayer = document.querySelector('video[class="ref_' + videoRef + '"]');
        // LOADING
        videoPlayer.pause();
        setPlay(false);
        setPlayPauseIcon('fi-sr-play');
        setLoadingTimeCode(true);
        // SET TIME CODE
        videoPlayer.currentTime = input.value;

        var loadedVideo = await awaitState(videoPlayer);

        if(loadedVideo === 4){
            setLoadingTimeCode(false);
            setPlay(true);
            setPlayPauseIcon('fi-sr-pause');
            videoPlayer.play();
        }
    }

    // CALCULATE TIME OF VIDEO
    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', function () {
            if(document.fullscreenElement === null){
                // CSS FOR VIDEO OVERLAY
                $('.ref_'+videoRef+' .bottom_bar').css({ height: 30 });
                $('.ref_'+videoRef+' .play-pause-button').css({ width: 50, height: 50 });
                $('.ref_'+videoRef+' .play-pause-button i').css({ fontSize: 16 });
                $('.ref_'+videoRef+' .bottom_bar i').css({fontSize: 16});
                $('.ref_'+videoRef+' .progressBar_bBarB1').css({ height: 5 });
                $('.ref_'+videoRef+' .timeCode_container').css({ height: 5, width: "calc(100% - 90px)" });
                $('.ref_'+videoRef+' .timeCode_duration').css({ fontSize: 12 });
                $('.ref_'+videoRef+' .volume-icon').css({ width: 15, height: 15 });
                $('.ref_'+videoRef+' .volume-slider').css({ height: 7 });
                $('.ref_'+videoRef+' .volume-back').css({ height: 20, marginLeft: -32, marginTop: -145 });
            }else{
                // CSS FOR VIDEO OVERLAY
                $('.ref_'+videoRef+' .bottom_bar').css({ height: 50 });
                $('.ref_'+videoRef+' .play-pause-button').css({ width: 80, height: 80 });
                $('.ref_'+videoRef+' .play-pause-button i').css({ fontSize: 24 });
                $('.ref_'+videoRef+' .bottom_bar i').css({fontSize: 22});
                $('.ref_'+videoRef+' .progressBar_bBarB1').css({ height: 6 });
                $('.ref_'+videoRef+' .timeCode_container').css({ height: 6, width: "calc(100% - 185px)" });
                $('.ref_'+videoRef+' .timeCode_duration').css({ fontSize: 14 });
                $('.ref_'+videoRef+' .volume-icon').css({ width: "auto", height: "auto" });
                $('.ref_'+videoRef+' .volume-slider').css({ height: 10 });
                $('.ref_'+videoRef+' .volume-back').css({ height: 25, marginLeft: -30, marginTop: -160 });
            }
        });
        // SET VOLUME
        const videoPlayer = document.querySelector('video[class="ref_'+videoRef+'"]');
        videoPlayer.volume = 0.5;
    }, [])

    useEffect(() => {
        // EVENT LISTENERS FOR COMMENT AND REPLY
        if(comment === true && nsfw === false){
            if(loading === false && error === false){
                // -- OVERLAY
                document.querySelector('.overlay.ref_'+videoRef).addEventListener('click', (e) => {
                    overlaySettings(e);
                    if(loadingTimeCode === false) togglePlayPause();
                })
                // -- PLAY PAUSE
                if(loadingTimeCode === false) {
                    document.querySelector('.play-pause-button').addEventListener('click', togglePlayPause);
                    document.querySelector('.playpauseBtn_bBarB1').addEventListener('click', togglePlayPause)
                }
                // -- VOLUME
                document.querySelector('.volume-icon').addEventListener('click', (e) => {
                    e.stopPropagation();
                    toogleMute();
                });
                document.querySelector('.volume-slider').addEventListener('click', (e) => e.stopPropagation());
                // -- FULLSCREEN
                document.querySelector('.fullscreenBtn_bBarB1').addEventListener('click', (e) => {
                    e.stopPropagation();
                    if(loadingTimeCode === false) fullScreen();
                })
            }
        }
    }, [comment, nsfw, loadingTimeCode, error, loading])

    return (
        <div id="attachMedia_divMSdB1" className={"ref_"+videoRef} {...(comment === true ? { style: { marginTop: -46 } }: {})}>
            <div className={"videoContainer_videoAMB1 ref_"+videoRef}>
                <video id="videoPlayer" className={"ref_"+videoRef} src={videoSrc} preload="metadata" {...(nsfw === true ? {style: {filter: 'blur(16px)'}} : {})} onTimeUpdate={updateProgressBar} onVolumeChange={updateVolumeIcon} onError={({ currentTarget }) => { currentTarget.poster = errorMediaDark;setError(true); }} onLoadedData={({currentTarget}) => { if(currentTarget.readyState >= 3){setLoading(false);setDuration(currentTarget.duration)} }} playsInline={true} />
                {
                    (error === true) || (nsfw === true) ? '':
                        <div className={"overlay ref_"+videoRef} onClick={(e) => {overlaySettings(e);if(loadingTimeCode === false){togglePlayPause()}}} onMouseMove={loading === false ? mouseMove: null} onMouseOut={loading === false ? mouseOut: null} style={ loading === true ? { opacity: 1 }: { opacity: 0 }}>
                        {
                            loading === false ?
                            <>
                            {
                                loadingTimeCode === true ?
                                <div className="play-pause-button" style={{ width: 65, height: 65 }}>
                                    <FadeLoader
                                        color="#ffffff"
                                        cssOverride={{
                                            top: 20,
                                            left: 24.5
                                        }}
                                        height={12}
                                        margin={-2}
                                        speedMultiplier={0.8}
                                        width={4}
                                    />
                                </div>
                                :
                                <div className="play-pause-button" onClick={togglePlayPause}>
                                    <i className={"fi " + playPauseIcon}></i>
                                </div>
                            }
                            <div className="bottom_bar">
                                {
                                    loadingTimeCode === true ?
                                    <div className="playpauseBtn_bBarB1"><i className={"fi fi-sr-loading"}></i></div>
                                    :
                                    <div className="playpauseBtn_bBarB1" onClick={togglePlayPause}><i className={"fi " + playPauseIcon}></i></div>
                                }
                                <span className='timeCode_duration' style={{ fontSize: 12,marginLeft: 10}}>{timeCode}/{calculateTime(duration)}</span>
                                <div className='timeCode_container'>
                                    <input className="progressBar_bBarB1" type='range' min={0} max={duration} step={0.1} defaultValue={0} onInput={({currentTarget}) => goTimecode(currentTarget)} onClick={(e) => e.stopPropagation()} />
                                </div>
                                <div className="volumeControl_bBarB1">
                                    <div className="volume-icon" onClick={(e) => {e.stopPropagation();toogleMute();}}>
                                        <i className={"fi " + volumeIcon}></i>
                                    </div>
                                    <div className="volume-back">
                                        <input type="range" className="volume-slider" min={0} max={1} step={0.05} defaultValue={0.5}
                                            onInput={({ currentTarget }) => {
                                                const videoPlayer = document.querySelector('video[class="ref_' + videoRef + '"]');
                                                videoPlayer.volume = currentTarget.value;
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </div>
                                </div>
                                <div className="fullscreenBtn_bBarB1" onClick={(e) => { e.stopPropagation();if(loadingTimeCode === false){fullScreen();} }}>
                                    <i className="fi fi-ss-expand"></i>
                                </div>
                            </div>
                            </>
                        :
                            <div className="play-pause-button" style={{ width: 65, height: 65 }}>
                                <FadeLoader
                                    color="#ffffff"
                                    cssOverride={{
                                        top: 20,
                                        left: 24.5
                                    }}
                                    height={12}
                                    margin={-2}
                                    speedMultiplier={0.8}
                                    width={4}
                                />
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Video;