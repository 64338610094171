let container = null;

export const openNewMenu = function(e) {
    // Default
    e.preventDefault();
    container = document.getElementById(e.target.getAttribute('data-container'));
    container.style.display = null;
    container.removeAttribute('aria-hidden');
    container.setAttribute('aria-modal', 'true');
    container.addEventListener('click', closeNewMenu);
    if(container.querySelector('.js-menu-close')) {
        container.querySelector('.js-menu-close').addEventListener('click', closeNewMenu);
    }
    if(container.querySelector('.js-menu-close-other')) {
        container.querySelectorAll('.js-menu-close-other').forEach((e) => {
            e.addEventListener('click', closeNewMenu);
        })
    }
    container.querySelector('.js-menu-stop').addEventListener('click', stopPropagation);

    // POSITION
    var button = e.target;
    var rect = button.getBoundingClientRect();
    var menuWrapper = container.querySelector('.menu-wrapper');

    var menuLeft = rect.left - 5;
    var menuTop = rect.bottom + 10;
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;

    if (menuLeft + menuWrapper.offsetWidth > windowWidth) menuLeft = windowWidth - menuWrapper.offsetWidth - 10;
    if (menuTop + menuWrapper.offsetHeight > windowHeight) menuTop = windowHeight - menuWrapper.offsetHeight - 10;

    menuWrapper.style.left = `${menuLeft}px`;
    menuWrapper.style.top = `${menuTop}px`;

    // ...
    document.body.scrollTo(0,0);
}

export const closeNewMenu = function(e) {
    if (container === null) return;
    e.preventDefault();
    container.setAttribute('aria-hidden', 'true');
    container.removeAttribute('aria-modal');
    container.removeEventListener('click', closeNewMenu);
    if(container.querySelector('.js-menu-close')) {
        container.querySelector('.js-menu-close').removeEventListener('click', closeNewMenu);
    }
    if(container.querySelector('.js-menu-close-other')) {
        container.querySelectorAll('.js-menu-close-other').forEach((e) => {
            e.removeEventListener('click', closeNewMenu);
        })
    }
    container.querySelector('.js-menu-stop').removeEventListener('click', stopPropagation);
    const hideMenu = function () {
        container.style.display = "none";
        container.removeEventListener('animationend', hideMenu);
        container = null;
    }
    container.addEventListener('animationend', hideMenu);
}

const stopPropagation = function(e) {
    e.stopPropagation();
}

window.addEventListener('keydown', function (e) {
    if (e.key === "Escape" || e.key === "Esc") {
        closeNewMenu(e);
    }
})