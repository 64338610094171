import {useState,useRef} from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropper = ({imageToCrop, userUid, onImageCropped}) => {
    const imageRef = useRef(null);
    const [cropConfig, setCropConfig] = useState({
        unit: '%',
        width: 50,
        height: 50,
        x: 25,
        y: 25,
    });

    const cropImage = async (crop) => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef.current,
                crop,
                userUid+'-avatar.webp'
            );

            onImageCropped(croppedImage);
        }
    }

    function getCroppedImage(sourceImage, cropConfig, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = cropConfig.width;
        canvas.height = cropConfig.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            sourceImage,
            cropConfig.x * scaleX,
            cropConfig.y * scaleY,
            cropConfig.width * scaleX,
            cropConfig.height * scaleY,
            0,
            0,
            cropConfig.width,
            cropConfig.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }

                resolve(blob);
            }, 'image/webp');
        });
    }

    return (
        <ReactCrop
            src={imageToCrop}
            crop={cropConfig}
            circularCrop
            keepSelection
            minHeight={217.5}
            minWidth={217.5}
            aspect={1}
            onComplete={(cropConfig) => cropImage(cropConfig)}
            onChange={(cropConfig) => setCropConfig(cropConfig)}
            crossorigin="anonymous"
        >
            <img ref={imageRef} src={imageToCrop} />
        </ReactCrop>
    );
}

export default ImageCropper;