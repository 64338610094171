import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
import {UAParser} from "ua-parser-js";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
import { Session, Sessions, VerticalMenu } from "../../../Components/Pages/Settings";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import * as Settings from '../../../Components/Functions/Settings';
import { API_VERSION } from "../../../Components/Functions";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";

const SettingsSessions = () => {
    const { t } = useTranslation();

    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set data
    const [infos, setInfos] = useState([]);
    const [data, setData] = useState([]);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])

    // BUILD CURRENT SESSION CARD
    const CurrentSession = () => {
        if(data !== null) {
            return (<>
                {data.map(session => {
                    if(session.token === Cookies.get('loginToken')){
                        return (
                            <Session
                                data={{
                                    device: session.device,
                                    browser: session.browser,
                                    location: JSON.parse(session.geolocation)
                                }}
                                theme={theme}
                            />
                        )
                    }
                })}
            </>)
        }
    }

    // BUILD OTHER SESSION CARD
    const OthersSessions = () => {
        if(data !== null) {
            return (<>
                {data.map(session => {
                    if(session.token !== Cookies.get('loginToken')){
                        return (
                            <Sessions
                                data={{
                                    token: session.token,
                                    device: session.device,
                                    browser: session.browser,
                                    location: JSON.parse(session.geolocation),
                                    date: session.session_date
                                }}
                                uid={infos.uid}
                                theme={theme}
                            />
                        )
                    }else if(session.token === Cookies.get('loginToken') && data.length === 1){
                        return(
                            <div className={"sessionContainer_currentSetPcVB1 "+theme} style={{padding: 25, height: 'auto'}}>
                                <p className={"text_frFPB1 "+theme} style={{marginTop: 0}}>
                                    <span className="texton_frPFPB1">{t('settings.sessions.error.title')}</span><br/>
                                    {t('settings.sessions.error.subtitle')}
                                </p>
                            </div>
                        )
                    }
                })}
            </>)
        }
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }

        // Retreive settings
        retreiveInfos().then(res => {
            setInfos(res);
            Settings.retreiveSessions(res.uid).then(sessions => setData(sessions))
        })
    }, [localStorage.getItem("theme")])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/sessions" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.sessions')}/>
                <meta property="og:url" content={"https://snot.fr/settings/sessions"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.sessions')}/>
                <title>{t('title.settings.sessions')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <h1 id="title">{t('settings.sessions.titleStrong')}</h1>
                        <h2 id="subtitle">{t('settings.sessions.subtitle')}</h2>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="AccountSettings" className="privacy accessiblity" style={{marginTop:-20}}>
                            <div className="currentSessions_accessSetMVB1">
                                <h1 className="title_satSet-accessSetPcVB1" style={{marginBottom: 0}}>{t('settings.sessions.current.title')}</h1>
                                <CurrentSession />
                            </div>
                            <div className="otherSessions_accessSetMVB1" style={{borderBottom: 0}}>
                                <h1 className="title_linksSet-accessSetPcVB1" style={{marginBottom: 0}}>{t('settings.sessions.others.title')}</h1>
                                <OthersSessions />
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsSessions;