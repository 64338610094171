import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
import { VerticalMenu } from "../../../Components/Pages/Settings";
// import i18next
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";
// import Functions
import { API_VERSION } from "../../../Components/Functions";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";
// import Images (flags)
import franceFlag from "../../../img/flags/france.png";
import britishFlag from "../../../img/flags/english-uk.png";
import japanFlag from "../../../img/flags/japan.png";

const SettingsLanguage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    const [language, setLanguage] = useState({
        english: false,
        french: false,
        japanese: false
    })
    const [infos, setInfos] = useState([]);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])

    // GLOBAL FUNCTIONS
    const save = (lang) => {
        var french = undefined;
        var english = undefined;
        var japanese = undefined;
        var langChanged = undefined;
        var langChangedSetting = undefined;
        if(lang === "french"){
            english = false;
            french = true;
            japanese = false;
            langChanged = "fr";
            langChangedSetting = "french";
        }else if(lang === "english"){
            english = true;
            french = false;
            japanese = false;
            langChanged = "en";
            langChangedSetting = "english";
        }else{
            japanese = true;
            english = false;
            french = false;
            langChanged = "jp";
            langChangedSetting = "japanese";
        }
        // Set language
        setLanguage({
            french: french,
            english: english,
            japanese: japanese,
        });
        // Change language
        i18n.changeLanguage(langChanged);
        // Edit in DB
        axios.put(
            `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit/language?token=${Cookies.get('loginToken')}&app=web`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                data: { lang: langChangedSetting }
            }
        )
    }

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }

        // Retreive settings
        retreiveInfos().then(res => {
            setInfos(res);
            // ...
            var french = false;
            var english = false;
            var japanese = false;
            if(res.language !== null){
                if(res.language === "french"){ french = true; }
                else if(res.language === "english"){ english = true; }
                else { japanese = true; }
            }else{
                if(i18n.resolvedLanguage === "fr"){
                    french = true;
                }else if(i18n.resolvedLanguage === "en"){
                    english = true;
                }else{
                    japanese = true;
                }
            }
            // ...
            setLanguage({
                french: french,
                english: english,
                japanese: japanese,
            })
        })
    }, [localStorage.getItem("theme"), ])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/language" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.language')}/>
                <meta property="og:url" content={"https://snot.fr/settings/language"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.language')}/>
                <title>{t('title.settings.language')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <h1 id="title">{t('settings.language.titleStrong')}</h1>
                        <div className={"saveBtnContainer_setMain-pcVB1 "+theme}>
                            <span className="text_saveBtnC-sMpcVB1">{t('settings.general.autosave')}</span>
                        </div>
                    </section>
                    <section className={"settingsPart_setMainMVB1 noButton "+theme} style={{marginTop: -20}}>
                        <div id="LanguageSettings">
                            <div className="frenchSetting_accSetMVB1">
                                <div id="languageChoice" onClick={() => save('french')}>
                                    <input id="france" name="language" type="radio" checked={language.french} />
                                    <label id="changeLanguageLabel" className="france" htmlFor="france" onClick={() => save('french')}></label>
                                    <img id="imgLabel" alt="France" title="Français" src={franceFlag} />
                                    <span id="textLabel" onClick={() => save('french')}>Français (France)</span>
                                </div>
                            </div>
                            <div className="englishSetting_accSetMVB1">
                                <div id="languageChoice" onClick={() => save('english')}>
                                    <input id="english" name="language" type="radio" checked={language.english} />
                                    <label id="changeLanguageLabel" className="english" htmlFor="english" onClick={() => save('english')}></label>
                                    <img id="imgLabel" alt="France" title="Français" src={britishFlag} />
                                    <span id="textLabel" onClick={() => save('english')}>English (UK)</span>
                                </div>
                            </div>
                            <div className="japaneseSetting_accSetMVB1" style={{borderBottom: 0}}>
                                <div id="languageChoice" onClick={() => save('japanese')}>
                                    <input id="japanese" name="language" type="radio" checked={language.japanese} />
                                    <label id="changeLanguageLabel" className="japanese" htmlFor="japanese" onClick={() => save('japanese')}></label>
                                    <img id="imgLabel" alt="日本語" title="日本語" src={japanFlag} />
                                    <span id="textLabel" onClick={() => save('japanese')}>日本語</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsLanguage;