// import CSS
import '../../css/embed.min.css';
// import Modules
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import axios from 'axios';
// import i18next
import { useTranslation } from 'react-i18next';
// import Components
import { PostIntegrate } from '../../Components/Pages/Home';
// import Functions
import { API_VERSION } from '../../Components/Functions';

const Embed = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // Set data
    const [data, setData] = useState([]);
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set logged
    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem('theme')])

    /* -----------
     * BUILD SPOST
     * -----------
     */
    // -- Fetch post
    const getPost = () => {
        return new Promise((resolve, reject) => {
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/posts/${searchParams.get('url')}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }
    // -- Build post
    const IntPost = () => {
        if(data.success === false){
            return(
                <>
                    <p id="titleError">We couldn't find this spost.</p>
                </>
            );
        }else if(data.success === true){
            return(
                <PostIntegrate
                    data={{
                        uid: data.post[0].uid,
                        sender: data.post[0].sender,
                        title: data.post[0].title,
                        content: data.post[0].content,
                        content_clear: data.post[0].textonly,
                        media: data.post[0].media,
                        tags: data.post[0].tags,
                        date: data.post[0].send_date,
                        likes: data.post[0].likesCount,
                        shares: data.post[0].sharesCount
                    }}
                    theme={searchParams.get('theme')+"Theme" || theme}
                    lang={searchParams.get('lang') || "en"}
                />
            );
        }
    }

    useEffect(() => {
        getPost().then(res => setData(res));
        // UI
        document.documentElement.style.background = "none";
        document.body.style.background = "none";
        document.getElementById('root').style.overflow = "hidden";
    }, [])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/embed" />
                <meta name="robots" content="noindex,indexifembedded,follow"/>
                <meta property="og:title" content={t('title.integrate')}/>
                <meta property="og:url" content={"https://snot.fr/embed"+location.search}/>
                <meta name="twitter:title" content={t('title.integrate')}/>
                <title>{t('title.integrate')}</title>
            </Helmet>
            <IntPost />
        </>
    );
}
 
export default Embed;