// import Skeleton
import Skeleton from "./Animation/Skeleton"

export default function MessageLoading({widthUser, widthUserTag, widthText, file}) {
    return (
        <>
            <div className="message_pChatFMMVB1 darkTheme" style={{ background: "transparent" }}>
                <div className="profilePart_msgPCMMVB1" style={{maxWidth: "none"}}>
                    <Skeleton className={"avatar"} theme={"darkTheme"} />
                    <div className="user skeleton darkTheme" style={{ width: widthUser }}></div>
                    <div className="usertag skeleton darkTheme" style={{ width: widthUserTag }}></div>
                </div>
                <div className="datePart_msgPCMMVB1"></div>
                <div className="contentPart_msgPCMMVB1 skeleton darkTheme" style={{ width: widthText, height: 20 , borderRadius: 5, marginTop: -18}}></div>
                {
                    file === true ?
                    <div className="attachFiles_mainContainer" style={{display: "flex"}}></div>
                    : ''
                }
            </div>
        </>
    )
}