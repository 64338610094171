// import Posts loading
import PostLoading, {PostLoadingWithMedia} from "../LoadingPost";
import { LoadingAppBar, LoadingNavBar } from "../Common/Loading";

export const LoadingProfile = () => {
    document.body.className = "darkTheme";
    
    return (
        <>
            <LoadingAppBar />
            <LoadingNavBar />
            <section className="topMenu_pcverSB1 darkTheme">
                <div className="others_fBmVeB1 darkTheme">
                    <div id="myspost" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="respost"  className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="liked"  className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
            </section>
            <main id="profilePartMain" style={{ overflow: "hidden" }}>
                <section id="profilePart_Profile" className="darkTheme" style={{ borderBottom: "2px solid #26262C", paddingBottom: 48 }}>
                    <div className="topPart_profileMVB1">
                        <div className="banner_tPProfileMVB1 darkTheme"></div>
                        <div className="profileInfo_tPProfileMVB1 darkTheme">
                            <div className="avatar skeleton darkTheme"></div>
                            <div className="user skeleton darkTheme"></div>
                            <div className="usertag skeleton darkTheme"></div>
                        </div>
                        <div className="aboutMe_tPProfileMVB1 about skeleton darkTheme"></div>
                        <div className="createDate_tPProfileMVB1 datecreate skeleton darkTheme"></div>
                    </div>
                </section>
                <section id="spostPart">
                    <PostLoading />
                    <PostLoadingWithMedia />
                    <PostLoading />
                    <main id="trendMainContainer">
                        <section className="trendMenu_pcverSB1 darkTheme">
                            <h1 className="skeleton darkTheme" style={{ height: 35, width: 175, borderRadius: 5 }}></h1>
                            <div className="separator_fBmVeB1 darkTheme"></div>
                            <div className="trends_fBmVeB1 darkTheme">
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                            </div>
                            <footer className="legalMentions_tMpVB1 darkTheme">
                                <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5, marginBottom: 10 }}></div>
                                <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5, marginBottom: 25 }}></div>
                                <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5 }}></div>
                            </footer>
                        </section>
                    </main>
                </section>
            </main>
        </>
      )
}