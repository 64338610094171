// import CSS
import '../../css/embed.min.css';
// import Modules
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import $ from 'jquery';
import copy from 'copy-to-clipboard';
// import i18next
import { useTranslation } from 'react-i18next';
// import Components
import { Appbar, Navbar } from '../../Components/Common';

const Integrate = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set logged
    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem('theme')])

    // COPY CODE FUNCTION
    const copyCode = (code) => {
        copy(code);
        // UI
        $('#buttonCopyCode_cCB1').css({ width: $('#buttonCopyCode_cCB1').width() + 20, padding: 0 })
        $('#buttonCopyCode_cCB1 .copyCode > span').text(t('general.copied'));
        setTimeout(() => {
            if($('#buttonCopyCode_cCB1 .copyCode')){
                $('#buttonCopyCode_cCB1').css({ width: "auto", padding: "0 10px" })
                $('#buttonCopyCode_cCB1 .copyCode > span').text(t('integrate.copy'))
            }
        }, 2000)
    }

    // RESIZE IFRAME FUNCTION
    const resizeIframe = (obj) => {
        var resizeInterval = setInterval(() => {
            if(obj.contentWindow.document.getElementById('mainSpost_sUB1Integrate')){
                obj.style.height = obj.contentWindow.document.getElementById('mainSpost_sUB1Integrate').scrollHeight + 'px';
                clearResize();
            }
        }, 500)

        const clearResize = () => {
            clearInterval(resizeInterval);
        }
    }

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/integrate" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.integrate')}/>
                <meta property="og:url" content={"https://snot.fr/integrate"+location.search}/>
                <meta name="twitter:title" content={t('title.integrate')}/>
                <title>{t('title.integrate')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <main className={"mainAppIntegrate_mVeSB1 "+theme}>
                <div className={"spostMain "+theme} style={{position: "relative",margin: "50px 0",top: 60}}>
                    <iframe id="frameIntegrate" width={500} frameBorder={0} scrolling="no" onLoad={(e) => resizeIframe(e.currentTarget)} style={{border:"none",width:"100%",borderRadius:10,display:"block",margin:"0px auto"}} src={"/embed?url="+searchParams.get('url')+"&lang="+searchParams.get('lang')+"&theme="+searchParams.get('theme')}><div id="spostUnique"/></iframe>
                    <div id="copyCode_copyCDB1" className={theme}>
                        <pre className="code" autoCapitalize="none">
                            &lt;script async="" src="https://snot.fr/js/embed.js"&gt;&lt;/script&gt;&lt;iframe width="500px" frameborder="0" scrolling="no" onload="resizeIframe(this)" style="border:none;width:100%;border-radius:10px;display:block;margin:0px auto;" src="https://snot.fr/embed/?url={searchParams.get('url')}&lang={searchParams.get('lang') || "en"}&theme={searchParams.get('theme') || "light"}"&gt;&lt;/iframe&gt;
                        </pre>
                        <div id="buttonCopyCode_cCB1" onClick={() => copyCode(document.querySelector('.code').textContent)} className={theme}>
                            <p className="copyCode"><span>{t('integrate.copy')}</span><i className="fi fi-rr-copy-alt"></i></p>
                        </div>
                    </div>
                    <div id="separator_IntsUB1" className={theme}></div>
                    <div id="settings_IntsUB1">
                        <div id="ftSet_IntSeB1" className={theme}>
                            <p className="title_themeChB1">{t('integrate.switch_theme.clear')} <span className="themeChange">{t('integrate.switch_theme.darken')}</span></p>
                            <form method="post">
                                <input type="checkbox" className="theme" id="changeTheme" name="theme" title="Change theme" {...(searchParams.get('theme') === "dark" ? { defaultChecked: true }: { defaultChecked: false })} onClick={() => {
                                    if(searchParams.get('theme') === "dark"){
                                        navigate('/integrate?url='+searchParams.get('url')+"&lang="+searchParams.get('lang')+"&theme=light");
                                    }else{
                                        navigate('/integrate?url='+searchParams.get('url')+"&lang="+searchParams.get('lang')+"&theme=dark");
                                    }
                                }} />
                                <label for="changeTheme" style={{cursor: "pointer"}}></label>
                            </form>
                        </div>
                        <div id="sdSet_IntSeB1" className={theme}>
                            <p className="title_themeChB1">{t('integrate.change_language')}</p>
                            <form method="post">
                                <div id="select-language-integrate">
                                    <select id="select-language-integrate-drop" name="Language" onChange={() => {
                                        var selectElement = document.getElementById('select-language-integrate-drop');
                                        selectElement.options[selectElement.selectedIndex].value && (navigate("/integrate?url=" + searchParams.get('url') + "&lang=" + selectElement.options[selectElement.selectedIndex].lang+"&theme="+searchParams.get('theme')));
                                    }}>
                                        <option lang="en" title="Change language to English" {...(searchParams.get('lang') === "en" ? {selected: true}: {})}>English</option>
                                        <option lang="fr" title="Modifier la langue en français" {...(searchParams.get('lang') === "fr" ? {selected: true}: {})}>Français</option>
                                    </select>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
 
export default Integrate;