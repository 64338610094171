import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import $ from 'jquery';
import axios from "axios";
import DOMPurify from "dompurify";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../Components/Common';
import { VerticalMenu } from "../../Components/Pages/Settings";
// import i18next
import { useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
// import Functions
import { openModal } from "../../Components/Functions/Modal";
import { openMenu } from "../../Components/Functions/Menu";
import { API_VERSION } from "../../Components/Functions";
import * as SettingsFunc from '../../Components/Functions/Settings';
// import Auth
import { retreiveInfos, retreivePassword } from "../../Components/Functions/Auth";

const Settings = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    // Set data
    const [error, setError] = useState('');
    const [errorEmail, setErrorEmail] = useState('');
    const [infoConfirm, setInfoConfirm] = useState('Si vous avez modifié votre mot de passe, veuillez saisir votre ancien mot de passe.');
    const [accountPassword, setAccountPassword] = useState('');
    const [warning, setWarning] = useState(<></>);
    const [twofa, set2FA] = useState({
        button: "",
        status: "",
        save: false,
        term: ""
    });
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set infos
    const [infos, setInfos] = useState({ accountType: 'in_app', email: 'example@snot.fr', uid: '' });
    // Set loading
    const [loading, setLoading] = useState(true);
    // For password change
    const [can_save, setCanSave] = useState(false);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])

    // Capitalize first letter
    function ucFirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
    /* ----------------
     * GLOBAL FUNCTIONS
     * ----------------
     */
    const closeNotifMB = () => {
        document.querySelectorAll('#mBLogged').forEach(a => {
            a.style.display = "none";
        })
    }

    // SAVE SETTINGS
    const save = (passwordSave) => {
        const data = {
            modifiedPassword: CryptoJS.SHA256(DOMPurify.sanitize($('.changePass_inputESetMVB1').val()).trim()).toString(),
        }
        const confirmSave = CryptoJS.SHA256(DOMPurify.sanitize($('#confirm.confirm_confPassMVB1').val().trim())).toString();
        const email = DOMPurify.sanitize($('#email.changeEmail_inputESetMVB1').val().trim());

        if (confirmSave !== accountPassword) {
            var errorModal = $('#mBLogged.error');
            var errorText = $('#mBLogged.error #textN_nMAPPb1 p');
            errorText.text(t('settings.account.error.incorrect'));
            if(confirmSave === ""){
                errorText.text(t('settings.account.error.enterPassword'));
            }

            if (errorModal.css('display') === "none") {
                errorModal.css({ display: 'flex', top: 280, zIndex: 9999, paddingLeft: 20, width: 460 });
            }

            setTimeout(() => {
                if(errorModal){
                    errorModal.css('display', 'none');
                }
            }, 5000);
        }else {
            // FOR PASSWORD
            if(passwordSave === true){
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit/account-main?token=${Cookies.get('loginToken')}&app=web`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: { type: 'password', string: data.modifiedPassword }
                    }
                )
            }
            // FOR EMAIL
            if(email !== ""){
                // UX
                setErrorEmail('Waiting for a response...');
                if(i18n.resolvedLanguage === "fr") setErrorEmail('En attente d\'une réponse...');
                $('.emailSetting_accSetMVB1 .error').css('color', '#707070');
                if(theme === "darkTheme") $('.emailSetting_accSetMVB1 .error').css('color', '#9A9A9A');
                // ...
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit/account-main?token=${Cookies.get('loginToken')}&app=web`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: { type: 'email', string: email, params: { lang: i18n.resolvedLanguage } }
                    }
                ).then(res => {
                    if(res.data.error && res.data.error === "emailNotSend"){
                        setErrorEmail('We couldn\'t send the email, please try again.');
                        if(i18n.resolvedLanguage === "fr"){
                            setErrorEmail('Nous n\'avons pas pu envoyer l\'email, veuillez réessayer.');
                        }
                        $('.emailSetting_accSetMVB1 .error').css('color', '#db4e4e');
                    }else{
                        setErrorEmail('A confirmation email has been sent to your email address.');
                        if(i18n.resolvedLanguage === "fr"){
                            setErrorEmail('Un email de confirmation a été envoyé à votre adresse email.');
                        }
                        $('.emailSetting_accSetMVB1 .error').css('color', '#1D9BF0');
                    }
                })
            }
            // FOR 2FA
            if(twofa.save === true){
                axios.put(
                    `https://api.snot.fr/v${API_VERSION}/${infos.uid}/settings/edit/account-main?token=${Cookies.get('loginToken')}&app=web`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: { type: '2fa', string: twofa.term }
                    }
                )
            }
            // UI
            $('#confirmPassword').trigger('click');
            $('#infoSave').css({ display: "block" });
            $('.updateBtn_tPSAMVB1').css({ marginLeft: "0" });
        }
    }

    // DELETE ACCOUNT
    const deleteAccount = (e) => {
        const confirmSave = CryptoJS.SHA256(DOMPurify.sanitize($('#confirm.confirm_confPassMVB1').val().trim())).toString();

        if (confirmSave !== accountPassword) {
            var errorModal = $('#mBLogged.error');
            var errorText = $('#mBLogged.error #textN_nMAPPb1 p');
            errorText.text(t('settings.account.error.incorrect'));
            if(confirmSave === ""){
                errorText.text(t('settings.account.error.enterPassword'));
            }

            if (errorModal.css('display') === "none") {
                errorModal.css({ display: 'flex', top: 280, zIndex: 9999, paddingLeft: 20, width: 460 });
            }

            setTimeout(() => {
                if(errorModal){
                    errorModal.css('display', 'none');
                }
            }, 5000);
        }else {
            axios.delete(
                `https://api.snot.fr/v${API_VERSION}/account/delete`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: { user: infos.uid, password: confirmSave, token: Cookies.get('loginToken') }
                }
            ).then(res => {
                $('#confirmPassword').trigger('click');
                setTimeout(() => {
                    openModal(e);
                }, 300);
            })
        }
    }
    
    // ...
    const change2FA = () => {
        if (twofa.status === t('settings.account.2fa.status.disabled')) {
            set2FA({ button: t('settings.account.2fa.button.disable'), status: t('settings.account.2fa.status.enabled'), save: true, term: "Yes" })
            setWarning(<></>);
        }else {
            set2FA({ button: t('settings.account.2fa.button.enable'), status: t('settings.account.2fa.status.disabled'), save: true, term: "No" })
            setWarning(<>
            <div className="warning_2faSetMVB1">
                <i className="fi fi-ss-shield-exclamation"></i>
                <span id="text" style={{fontSize: 14}}>{t('settings.account.2fa.warning')}</span>
            </div>
            </>);
        }
    }

    const revealInputs = () => {
        const formPassToReveal = $("#formSetPass");
        const buttonToHide = $("#changePass");
        const buttonToShow = $("#cancelEditing");

        if (formPassToReveal.css('display') === "none") { 
            buttonToHide.css({display: "none"});
            buttonToShow.css({display: 'flex'})
            formPassToReveal.css({display: "block"});
            $('.error_pswdSet-accSetPcVB1').css({ display: "block" });
            if(Cookies.get('valid-password-setting')) setCanSave(true);
        }
    }

    const hideInputs = () => {
        const formPassToReveal = $("#formSetPass");
        const buttonToShow = $("#changePass");
        const buttonToHide = $("#cancelEditing");
        if (formPassToReveal.css('display') === "block") { 
            buttonToHide.css({display: "none"});
            formPassToReveal.css({display: "none"});
            buttonToShow.css({display: "flex"});
            $('.error_pswdSet-accSetPcVB1').css({ display: "none" });
            setCanSave(false);
        }
    }

    // WHEN PASSWORD INPUTS ARE CHANGED
    const liveErrorPassword = () => {
        const password = {
            current: DOMPurify.sanitize($('.actualPass_inputESetMVB1').val()).trim(),
            modified: DOMPurify.sanitize($('.changePass_inputESetMVB1').val()).trim(),
            confirm: DOMPurify.sanitize($('.confPass_inputESetMVB1').val()).trim()
        }

        if(password.current !== "" && password.modified !== "" && password.confirm !== ""){
            if(CryptoJS.SHA256(password.current).toString() !== accountPassword){
                setCanSave(false);
                setError(t('settings.account.error.incorrect'));
                $('.actualPass_inputESetMVB1').css('border', '2px solid #db4e4e');
                $('.confPass_inputESetMVB1').css('border', 'none');
                $('.changePass_inputESetMVB1').css('border', 'none');
                // Remove a cookie
                if(Cookies.get('valid-password-setting')) Cookies.remove('valid-password-setting');
            }else if(CryptoJS.SHA256(password.modified).toString() !== CryptoJS.SHA256(password.confirm).toString()){
                setCanSave(false);
                setError(t('settings.account.error.changePassword.confirmation'));
                $('.confPass_inputESetMVB1').css('border', '2px solid #db4e4e');
                $('.changePass_inputESetMVB1').css('border', '2px solid #db4e4e');
                $('.actualPass_inputESetMVB1').css('border', 'none');
                // Remove a cookie
                if(Cookies.get('valid-password-setting')) Cookies.remove('valid-password-setting');
            }else if(password.modified.length < 8){
                setCanSave(false);
                setError(t('settings.account.error.changePassword.length'));
                $('.changePass_inputESetMVB1').css('border', '2px solid #db4e4e');
                $('.confPass_inputESetMVB1').css('border', 'none');
                $('.actualPass_inputESetMVB1').css('border', 'none');
                // Remove a cookie
                if(Cookies.get('valid-password-setting')) Cookies.remove('valid-password-setting');
            }else{
                setCanSave(true);
                setError('');
                $('.actualPass_inputESetMVB1').css('border', 'none');
                $('.confPass_inputESetMVB1').css('border', 'none');
                $('.changePass_inputESetMVB1').css('border', 'none');
                // Set a cookie
                if(!Cookies.get('valid-password-setting')) Cookies.set('valid-password-setting', true, { secure: true, expires: 1 });
            }
        }else{
            if($('.updatePswd_accSetPcVB1').css('display') === "none"){
                if(password.current === "" && password.modified !== "" && password.confirm !== ""){
                    setError(t('settings.account.error.changePassword.enterCurrent'));
                    $('.confPass_inputESetMVB1').css('border', 'none');
                    $('.changePass_inputESetMVB1').css('border', 'none');
                }else if(password.current !== "" && password.modified === "" && password.confirm !== ""){
                    setError(t('settings.account.error.changePassword.enterNew'));
                    $('.actualPass_inputESetMVB1').css('border', 'none');
                    $('.confPass_inputESetMVB1').css('border', 'none');
                }else if(password.current !== "" && password.modified !== "" && password.confirm === ""){
                    setError(t('settings.account.error.changePassword.enterConfirmNew'));
                    $('.actualPass_inputESetMVB1').css('border', 'none');
                    $('.changePass_inputESetMVB1').css('border', 'none');
                }else{
                    setError(t('settings.account.error.changePassword.enterAll'));
                    $('.actualPass_inputESetMVB1').css('border', '2px solid #db4e4e');
                    $('.changePass_inputESetMVB1').css('border', '2px solid #db4e4e');
                    $('.confPass_inputESetMVB1').css('border', '2px solid #db4e4e');
                }
                setCanSave(false);
                // Remove a cookie
                if(Cookies.get('valid-password-setting')) Cookies.remove('valid-password-setting');
            }
        }
    }

    useEffect(() => {   
        setLoading(true);     
        // Get [user] infos
        retreiveInfos().then(async (res) => {
            setInfos({ accountType: res.account_type, email: res.email, uid: res.uid });
            // Retreive 2FA settings
            await SettingsFunc.retreive2FASettings(res.uid).then(setting => {
                if(setting !== null){
                    if (setting[0]['2fa'] === "Yes") {
                        set2FA({ button: t('settings.account.2fa.button.disable'), status: t('settings.account.2fa.status.enabled') })
                    }else {
                        set2FA({ button: t('settings.account.2fa.button.enable'), status: t('settings.account.2fa.status.disabled') })
                        setWarning(<>
                        <div className="warning_2faSetMVB1">
                            <i className="fi fi-ss-shield-exclamation"></i>
                            <span id="text" style={{fontSize: 14}}>{t('settings.account.2fa.warning')}</span>
                        </div>
                        </>)
                    }
                }
            })
            // set loading
            setLoading(false);
        })

        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem("theme"), location])

    useEffect(() => {
        // EVENT LISTENERS
        // -- FOR CONFIRM PASSWORD BUTTON (MODAL)
        $('.button_confPassMVB1.confirmPassword').off('click');
        $('.button_confPassMVB1.confirmPassword').on('click', () => save(can_save));
        // -- FOR MENU DELETE ACCOUNT
        $('.deleteAccount_menuBtn').off('click');
        $('.deleteAccount_menuBtn').on('click', (e) => {
            $('#deleteAccMenu').trigger('click');
            openModal(e);
            setInfoConfirm('En appuyant sur "Confirmer", votre compte sera supprimé.');
            // RESET INPUT CONTENT
            $('#confirm.confirm_confPassMVB1').val('');
            // CHANGE CLICK EVENT FOR CONFIRM BUTTON
            $('.button_confPassMVB1.confirmPassword').off('click');
            $('.button_confPassMVB1.confirmPassword').on('click', (ev) => {
                deleteAccount(ev);
            });
        })
        // -- FOR DELETE ACCOUNT MODAL
        if($('.button_confPassMVB1.deletedAccount')){
            $('.button_confPassMVB1.deletedAccount').off('click');
            $('.button_confPassMVB1.deletedAccount').on('click', () => {
                Cookies.remove('loginToken');
                Cookies.remove('_theme');
                Cookies.remove('_genSet');
                Cookies.remove('streak-seen');
                navigate('/');
            })
        }
    }, [accountPassword, can_save, twofa])

    useEffect(() => {
        // -- RETREIVE PASSWORD
        if(infos.uid !== ''){
            retreivePassword(infos.uid).then(res => {
                setAccountPassword(res);
            })
        }
    }, [infos])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.main')}/>
                <meta property="og:url" content={"https://snot.fr/settings"+location.search}/>
                <meta name="twitter:title" content={t('title.settings.main')}/>
                <title>{t('title.settings.main')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <div id="mBLogged" className={theme+" error"} style={{display: 'none'}}>
                <div id="textN_nMAPPb1">
                    <p></p>
                </div>
                <div className="iconClose" onClick={closeNotifMB}><i className="fi fi-sr-cross"></i></div>
            </div>
            {
                loading === false &&
                <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                    <div id="settingsMain">
                        <section className={"topPart_setAccMVB1 "+theme}>
                            <h1 id="title">{t('settings.account.titleStrong')}</h1>
                            <div className={"saveBtnContainer_setMain-pcVB1 "+theme}>
                                <span className="text_saveBtnC-sMpcVB1">{t('settings.general.save')}</span>
                                <span id="infoSave" className="info_saveBtnC-sMpcVB1">{t('settings.general.saved')}</span>
                                <div className={"updateBtn_tPSAMVB1 "+theme+" fr"} tabIndex={2} data-modal="confirmPassword" onClick={(e) => {openModal(e);setInfoConfirm('Si vous avez modifié votre mot de passe, veuillez saisir votre ancien mot de passe.');}}>{t('settings.general.saveButton')}</div>
                            </div>
                        </section>
                        <section className={"settingsPart_setMainMVB1 "+theme} style={{marginTop: -20}}>
                            <div id="AccountSettings">
                                <div className="emailSetting_accSetMVB1">
                                    <h1 className="title_emailSet-accSetPcVB1" style={{marginBottom:0}}>{t('settings.account.email.title')}</h1>
                                    {
                                        infos.accountType === "in_app" ?
                                        <>
                                        <h2 className="info_emailSet-accSetPcVB1">{t('settings.account.email.description')}</h2>
                                        <p className="error">{errorEmail}</p>
                                        <form method="post" id="formSetEmail">
                                            <label id="changeEmailLabel" htmlFor="email">{t('settings.account.email.form.snot')}</label>
                                            <input className="changeEmail_inputESetMVB1" id="email" name="email" type="email" title={t('settings.account.email.form.snot')} tabIndex={3} placeholder="example@snot.fr" />
                                        </form>
                                        </>
                                        :
                                        <>
                                        <h2 className="info_emailSet-accSetPcVB1">{t('settings.account.email.notSNOT.part1')} {ucFirst(infos.accountType.replace('_', ' '))}, {t('settings.account.email.notSNOT.part2')}.</h2>
                                        <form method="post" id="formSetEmail">
                                            <label id="changeEmailLabel" htmlFor="email">{t('settings.account.email.form.not')}</label>
                                            <input className="changeEmail_inputESetMVB1" id="email_other" name="email_other" type="email" title={t('settings.account.email.form.not')} tabIndex={3} placeholder={infos.email} disabled style={{ cursor: "not-allowed" }} />
                                        </form>
                                        </>
                                    }
                                </div>
                                <div className="passwordSetting_accSetMVB1">
                                    <div className="topSection_pswdSet-accSetPcVB1">
                                        <h1 className="title_pswdSet-accSetPcVB1" style={{width: "fit-content",margin: 0}}>{t('settings.account.password.title')}</h1>
                                        <div id="cancelEditing" className={"cancelEditPswd_accSetPcVB1 "+theme} role="button" tabIndex={4} lang={i18n.resolvedLanguage} onClick={hideInputs}>
                                            Annuler
                                            <i style={{display: "flex",marginLeft: 10,fontSize: 10}} className="fi fi-rr-minus"></i>
                                        </div>
                                    </div>
                                    {
                                        infos.accountType === "in_app" ?
                                        <>
                                        <p className="error_pswdSet-accSetPcVB1" style={{marginTop: -10,marginBottom: 10,color: "#db4e4e",fontWeight: 400}}>{error}</p>
                                        <div id="changePass" className="updatePswd_accSetPcVB1" role="button" tabIndex={4} lang={i18n.resolvedLanguage} style={{borderBottom: 0,padding: "0px 20px",marginTop: 0}} onClick={revealInputs}>{t('settings.account.password.button')}</div>
                                        <form method="post" id="formSetPass" style={{display: "none"}}>
                                            <label id="actualPassLabel" htmlFor="password">{t('settings.account.password.form.current')}</label>
                                            <input className="actualPass_inputESetMVB1" id="password" name="password" type="password" title={t('settings.account.password.form.current')}
                                                onChange={(e) => {
                                                    liveErrorPassword();
                                                    if(e.target.value.trim() === ""){
                                                        $('.actualPass_inputESetMVB1').css('border', '2px solid #db4e4e');
                                                    }else{
                                                        $('.actualPass_inputESetMVB1').css('border', 'none');
                                                    }
                                                }} />
                                            <label id="changePassLabel" htmlFor="newpassword">{t('settings.account.password.form.modified')}</label>
                                            <input className="changePass_inputESetMVB1" id="newpassword" name="newpassword" type="password" title={t('settings.account.password.form.modified')}
                                                onChange={(e) => {
                                                    liveErrorPassword();
                                                    if(e.target.value.trim() === ""){
                                                        $('.changePass_inputESetMVB1').css('border', '2px solid #db4e4e');
                                                    }else{
                                                        $('.changePass_inputESetMVB1').css('border', 'none');
                                                    }
                                                }} />
                                            <label id="confPassLabel" htmlFor="confpass">{t('settings.account.password.form.confirm')}</label>
                                            <input className="confPass_inputESetMVB1" id="confpass" name="confpass" type="password" title={t('settings.account.password.form.confirm')}
                                                onChange={(e) => {
                                                    liveErrorPassword();
                                                    if(e.target.value.trim() === ""){
                                                        $('.confPass_inputESetMVB1').css('border', '2px solid #db4e4e');
                                                    }else{
                                                        $('.confPass_inputESetMVB1').css('border', 'none');
                                                    }
                                                }} />
                                        </form>
                                        </>
                                        :
                                        <h2 className="info_emailSet-accSetPcVB1" style={{ margin: 0, marginTop: -10 }}>{t('settings.account.email.notSNOT.password.part1')} {ucFirst(infos.accountType.replace('_', ' '))}, {t('settings.account.email.notSNOT.password.part2')}.</h2>
                                    }
                                </div>
                                <div className="2faSetting_accSetMVB1">
                                    <h1 className="title_2faSet-accSetPcVB1" style={{marginBottom:0}}>{t('settings.account.2fa.title')}</h1>
                                    <h2 className="info_2faSet-accSetPcVB1">{t('settings.account.2fa.description')}</h2>
                                    {warning}
                                    <div className="twofaBtn_2faSetMVB1" id="2FAEnable" role="button" title="<?= $enable ?>" tabIndex={5} onClick={change2FA}><span id="twofaBtnText">{twofa.button}</span></div>
                                    <span id="status">{t('settings.account.2fa.status.main')} <span id="twofaStatus">{twofa.status}</span></span>
                                </div>
                                <div className="delAccSetting_accSetMVB1" style={{borderBottom: 0}}>
                                    <h1 className="title_delAccSet-accSetPcVB1">{t('settings.account.delete.title')}</h1>
                                    <div className="deleteBtn_delAccSetMVB1" id="DeleteAccount" role="button" title="Supprimer" tabIndex={6} data-container="deleteAccMenu" onClick={(e) => openMenu(e)}>
                                        <span data-container="deleteAccMenu">{t('settings.account.delete.button')}</span>
                                        <i className="fi fi-sr-trash" data-container="deleteAccMenu"></i>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            }
            {/* Deleted Pop up */}
            <aside id="accountDeleted" className="modal" aria-hidden="true" aria-modal="false" style={{ display: 'none' }}>
                <div className="modal-wrapper js-modal-stop">
                    <div className={"confirmationPassword "+theme}>
                        <div id="logoTop">
                            <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                        </div>
                        <button className="js-modal-close" style={{ padding:"10px", position: "absolute", top: 10, right: 20 }}>
                            <i className="fi fi-rr-cross"></i>
                        </button>
                        <h1 id="title" style={{ width: "100%", color: '#fff' }}>Compte supprimé.</h1>
                        <p id="info" style={{ color: '#fff', fontWeight: 400, fontSize: 14 }}>Seuls vos messages privés n'ont pas été supprimées.</p>
                        <p id="info">Contactez le support si vous souhaitez que ces données soient supprimées.</p>
                        <div className="button_confPassMVB1 deletedAccount" style={{ width: "calc(100% - 30px)" }}><span>Retourner à l'accueil</span></div>
                    </div>
                </div>
            </aside>
            {/* Confirm Password Pop up */}
            <aside id="confirmPassword" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className="modal-wrapper js-modal-stop">
                    <div className={"confirmationPassword "+theme}>
                        <div id="logoTop">
                            <img alt="Logo" title="SNOT" src={"https://api.snot.fr/v"+API_VERSION+"/content/logo_vbanner?ext=png&folder=logo"} />
                        </div>
                        <button className="js-modal-close" style={{ padding:"10px", position: "absolute", top: 10, right: 20 }}>
                            <i className="fi fi-rr-cross"></i>
                        </button>
                        <h1 id="title" style={{ width: "100%" }}>Veuillez entrer votre mot de passe.</h1>
                        <form method="post" id="formConfirm">
                            <div className="confirm_confPassMVB1">
                                <label id="confirmPasswordLabel" htmlFor="confirm">Mot de passe</label>
                                <input className="confirm_confPassMVB1" title="Mot de passe" id="confirm" name="confirm" type="password" style={{ width: "calc(100% - 30px)" }} />
                            </div>
                        </form>
                        <div className="button_confPassMVB1 confirmPassword" data-modal="accountDeleted" style={{ width: "calc(100% - 30px)" }}><span data-modal="accountDeleted">Confirmer</span></div>
                        <p id="info">{infoConfirm}</p>
                    </div>
                </div>
            </aside>
            {/* Delete Account Menu */}
            <aside id="deleteAccMenu" className={"menu "+theme} aria-hidden="true" aria-modal="false" data-o-container="deleteAccMenu" style={{display:"none"}}>
                <div className="menu-wrapper js-menu-stop" style={{width: "auto",maxWidth: 400}}>
                    <div className="confirmationLogout_menuMVB1">
                        <h1 id="title">{t('settings.account.delete.confirm.title.part1')} <span style={{fontWeight: 700}}>{t('settings.account.delete.confirm.title.medium')}</span> {t('settings.account.delete.confirm.title.part2')}.</h1>
                        <p id="warn">{t('settings.account.delete.confirm.description')}<br/><br/><span className="ask">{t('settings.account.delete.confirm.question')}</span></p>
                        <div className="actionButtons_menuMVB1">
                            <div id="goBack" className="js-menu-close-other">
                                <span>{t('settings.account.delete.confirm.action.cancel')}</span>
                            </div>
                            <div id="delete" className="deleteAccount_menuBtn" data-modal="confirmPassword">
                                <span data-modal="confirmPassword">{t('settings.account.delete.confirm.action.delete')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
       </>
    )
}

export default Settings;