import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $, { param } from 'jquery';
import Cookies from "js-cookie";
import axios from "axios";
import DOMPurify from 'dompurify';
import * as marked from 'marked';
import { mangle } from "marked-mangle";
// import i18next
import { useTranslation } from "react-i18next";
// import Components
import Video from "../../../Common/Media/VideoPlayer";
import Audio from "../../../Common/Media/AudioPlayer";
// import Functions
import { API_VERSION } from "../../../Functions";
import { retreiveInfos } from "../../../Functions/Auth";
// import Images
import errorMediaDark from '../../../../img/error/media_dark.png';
import errorMediaLight from '../../../../img/error/media_light.png';

export const EditCommentPopUp = ({user, post, uid, theme}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [media, setMedia] = useState(<></>);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>
    });
    const [mediaObject, setMediaObject] = useState([]);
    // Set lenght
    const [lenghtContent, setLenght] = useState(0);
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    // -- Only images, videos and audio
    const acceptedImage = [
        "apng",
        "bmp",
        "gif",
        "jpg",
        "jpeg",
        "pjpeg",
        "png",
        "tiff",
        "tif",
        "ico",
        "avif",
        "svg",
        "webp",
        "heif",
        "heifs",
        "heic",
        "heics",
        "jp2",
        "j2k",
        "jpx",
        "jpm",
        "avci",
        "aces",
    ]
    const acceptedVideo = [
        "webm",
        "mj2",
        "mjp2",
        "mp4",
        "m4v",
        "ts",
        "avi",
        "mpeg",
        "ogv",
        "3gp",
        "3g2",
        "mov",
        "mkv",
        "mk3d",
        "flv",
        "wmf",
        "vob",
    ]
    const acceptedAudio = [
        '3gp',
        '3g2',
        'aac',
        'ac3',
        'aa',
        'aax',
        "aiff",
        "alac",
        'mka',
        'midi',
        'mid',
        'm4a',
        "m4b",
        "m4p",
        "mpc",
        'mp3',
        'opus',
        'oga',
        'wav',
        'weba'
    ]

    // Publish functions
    const publish = () => {
        $('.infoComment_mPACPcVB1.editCom.ref_'+post.uid).css('color', '#707070');
        $('.infoComment_mPACPcVB1.editCom.ref_'+post.uid).text(t('comment.add.loading'));
        // ...
        // FOR MARKED
        // -- Override function
        const renderer = {
            link(href) {
                return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replaceAll('mailto:', '')}">${href.replaceAll('mailto:', '')}</a>`;
            }
        };
        // -- Set options
        marked.use({
            breaks: true,
            renderer,
        }, mangle());

        if((DOMPurify.sanitize($('.textArea_aComPcVB1.editCom.ref_'+post.uid+' #contentEdit').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}) !== '') || ($('.ref_'+post.uid+' .mediaComment_aComPcVB1.editCom') && media !== <></>)){            
            axios.put(
                `https://api.snot.fr/v${API_VERSION}/comment/${post.uid}`,
                { content: marked.parseInline(DOMPurify.sanitize($('.textArea_aComPcVB1.editCom.ref_'+post.uid+' #contentEdit').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']})), textonly: DOMPurify.sanitize($('.textArea_aComPcVB1.editCom.ref_'+post.uid+' #contentEdit').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}), media: JSON.stringify(mediaObject) },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: user.uid,
                        app: 'web'
                    }
                }
            ).then(response => {
                $('.infoComment_mPACPcVB1.editCom.ref_'+post.uid).css('color', 'rgb(29, 155, 240)');
                $('.infoComment_mPACPcVB1.editCom.ref_'+post.uid).text(t('home.general.edit.success.comment'));
                // UI / UX
                $('.com_'+post.uid+' .spostContent_spostMAppMVB1 .content').html(response.data.content);
                // ...
                setTimeout(() => {
                    $('#editModalCom_'+post.uid).trigger('click');
                    $('.infoComment_mPACPcVB1.editCom.ref_'+post.uid).text('');
                }, 750)
            })
        }else {
            $('.infoComment_mPACPcVB1.editCom.ref_'+post.uid).css('color', '#db4e4e');
            $('.infoComment_mPACPcVB1.editCom.ref_'+post.uid).text(t('home.general.edit.error'));
            $('.textArea_aComPcVB1.editCom.ref_'+post.uid).css('border-color', '#db4e4e');
        }
    }

    useEffect(() => {
        retreiveInfos().then((res) => {
            if(res !== "This user doesn't exist.") {
                // Set badges
                if (res.certified === "Yes") {
                    var colorBadge = "#000";
                    if(theme === "darkTheme"){
                        colorBadge = "#fff"
                    }
                    setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
                }
                if (res.staff === "Yes") {
                    setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }
            }
        })
        
        // -- To focus input
        document.querySelector('.textArea_aComPcVB1.editCom.ref_'+post.uid).addEventListener('click', (e) => {e.preventDefault();$('.ref_'+post.uid+' .comContent_tAPcVB1.editCom #contentEdit').trigger('focus')});
        if(document.querySelector('.ref_'+post.uid+' .mediaComment_aComPcVB1.editCom')){
            document.querySelector('.ref_'+post.uid+' .mediaComment_aComPcVB1.editCom').addEventListener('click', (e) => e.preventDefault());
        }
    }, [theme, user, post])

    useEffect(() => {
        // -- FOR SUBMIT BUTTON
        $('.commentBtn_aComPcVB2.comment_'+post.uid).off('click')
        $('.commentBtn_aComPcVB2.comment_'+post.uid).on('click', () => publish());
        // -- FOR MEDIA
        if(JSON.parse(post.media).length !== 0) {
            var arrayMedia = JSON.parse(post.media).toString();
            const params = new URL(arrayMedia).searchParams;

            if(acceptedVideo.includes(params.get('ext'))) {
                setMedia(
                    <div className={"mediaComment_aComPcVB1 editCom "+theme}>
                        <div id="delete" className="comment" role="button">
                            <i className="fi fi-rr-cross"></i>
                        </div>
                        <Video videoRef={0} videoSrc={arrayMedia} params={{stopPropagation: true, comment: true}}></Video>
                    </div>
                );
            }else if(acceptedImage.includes(params.get('ext'))) {
                setMedia(
                    <>
                    <div className={"mediaComment_aComPcVB1 editCom "+theme}>
                        <div id="delete" className="comment" role="button">
                            <i className="fi fi-rr-cross"></i>
                        </div>
                        <img className="img_mComPcVB1"
                            alt={arrayMedia}
                            title={arrayMedia}
                            src={arrayMedia}
                            onError={({ currentTarget }) => {
                                if(theme === "darkTheme"){
                                    currentTarget.src = errorMediaDark;
                                }else{
                                    currentTarget.src = errorMediaLight;
                                }
                            }}
                            referrerPolicy="no-referrer"
                        />
                    </div>
                    </>
                )
            }else if(acceptedAudio.includes(params.get('ext'))) {
                setMedia(
                    <>
                    <div className={"mediaComment_aComPcVB1 editCom "+theme} style={{width: "100%"}}>
                        <div id="delete" className="comment" role="button">
                            <i className="fi fi-rr-cross"></i>
                        </div>
                        <Audio audioRef={0} audioSrc={arrayMedia} params={{stopPropagation: true, comment: true}}></Audio>
                    </div>
                    </>
                )
            }
            // APPEND TO ARRAY
            if(mediaObject.length >= 1){
                mediaObject.splice(0, 1);
            }
            mediaObject.push(arrayMedia)
        }
    }, [])

    useEffect(() => {
        // EVENT
        if(document.querySelector('.ref_'+post.uid+' #delete.comment')){
            document.querySelector('.ref_'+post.uid+' #delete.comment').addEventListener('click', () => {
                setMedia(<></>);
                if(mediaObject.length >= 1){
                    mediaObject.splice(0, 1);
                }
            });
        }
    }, [media])
    
    return (
        <>
            {/* EDIT POP UP */}
            <aside id={"editModalCom_"+post.uid} className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className={"modal-wrapper js-modal-stop "+theme} {...(theme === "darkTheme" ? { style: {backgroundColor: "#101010",padding: 0,minHeight: 500,width: 600,overflow: "hidden"} }: { style: {backgroundColor: "#EEE",color:"#000",padding: 0,minHeight: 500,width: 600,overflow: "hidden"}})}>
                    <div className={"topPart_fSLMPcB1 "+theme}>
                        <h1 {...(theme === "darkTheme" ? { style: {color: "#fff"}}: { style: {color:"#000"}})}>
                            {t('home.general.edit.title')+t('home.general.edit.type.comment')}
                        </h1>
                        <button className={"js-modal-close "+theme}>
                            <i className="fi fi-rr-cross"></i>
                        </button>
                    </div>
                    <div className="mainPart_aComPcVB1">
                        <p className={"infoComment_mPACPcVB1 editCom ref_"+post.uid} style={{margin: "-10px 0 10px 0",color:"#1D9BF0",fontSize: 14}}></p>
                        <div className="profilePart_mPACPcVB1">
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme } style={{ width: 50, height: 50 }}>
                                <img alt={"@"+user.usertag.replace('.', '_').toLowerCase()} title={user.username} src={user.avatar} className={"avatar_addComPcVB1 uid_"+post.uid}
                                    onError={() => {
                                        if(!$('.avatar_addComPcVB1.uid_'+post.uid).attr('src').includes('.googleusercontent.com')){
                                            $('.avatar_addComPcVB1.uid_'+post.uid).attr('src', 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp')
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <span id="username">{user.username}</span>
                            <span id="usertag">@{user.usertag.toLowerCase().replace('.', '_')} {badges.certified}{badges.staff}</span>
                        </div>
                        <div className={"textArea_aComPcVB1 editCom ref_"+post.uid+" "+theme}>
                            <div className="comContent_tAPcVB1 editCom">
                                <textarea tabIndex={1} required={true} placeholder={t('home.general.edit.placeholder')} title={t('home.general.edit.placeholder')} defaultValue={post.textonly} name="contentEdit" id="contentEdit" style={{ height: "100%" }} maxLength={500}
                                    onChange={(e) => {
                                        if(e.currentTarget.value.length > 400){
                                            setLenght(e.currentTarget.value.length);
                                            $('.ref_'+post.uid+' #charactersCount.editCom').css('display', 'block')
                                        }else{
                                            setLenght(e.currentTarget.value.length);
                                            $('.ref_'+post.uid+' #charactersCount.editCom').css('display', 'none')
                                        }
                                        // Error UX
                                        if(e.currentTarget.value.trim() !== "") {
                                            if(e.currentTarget.value.length > 500){
                                                $('.textArea_aComPcVB1.editCom.ref_'+post.uid).css({ borderColor: "#db4e4e" })
                                            }else{
                                                $('.textArea_aComPcVB1.editCom.ref_'+post.uid).css({ borderColor: "#CFCFCF" });
                                                if(theme === "darkTheme") $('.textArea_aComPcVB1.editCom.ref_'+post.uid).css({ borderColor: "#202020" });
                                            }
                                        }else{
                                            if($('.ref_'+post.uid+' .mediaComment_aComPcVB1.editCom')){}
                                            else{
                                                $('.textArea_aComPcVB1.editCom.ref_'+post.uid).css({ borderColor: "#db4e4e" })
                                            }
                                        }
                                    }}
                                    onInput={(e) => {
                                        e.currentTarget.style.height = "";
                                        e.currentTarget.style.height = e.currentTarget.scrollHeight + "px"
                                    }}
                                ></textarea>
                                {media}
                            </div>
                            <span id="charactersCount" className="editCom" style={{ fontSize: 14, marginLeft: 5, marginTop: 5, display: "none" }}>{lenghtContent}/500</span>
                        </div>
                        <div className={"commentBtn_aComPcVB2 comment_"+post.uid+" "+theme}>
                            <span>{t('general.buttons.edit')}</span>
                        </div>
                        <div className="infos_aComPcVB1">
                            <i className="fi fi-rr-info" style={{ display: "flex",marginRight: 5 }}></i>
                            <span>{t('home.general.edit.footer.comment')} <a href={"https://about.snot.fr/"+i18n.resolvedLanguage+"/content"} hrefLang={i18n.resolvedLanguage} title={t('footer.content')} target="_blank" rel="noreferrer">{t('footer.content')}</a>.</span>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}