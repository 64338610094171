import {useEffect, useState} from 'react';
import { Link, useLocation } from "react-router-dom";
// import i18next
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";

const NotLoggedBanner = () => {
    const { t } = useTranslation();
    const location = useLocation();
    // Set pathname
    const [pathname, setPathname] = useState("");
    // Set Theme Data
    const [theme, setTheme] = useState('darkTheme');
    
    useEffect(() =>{
        if(location.pathname !== "/"){
            setPathname("?rel="+location.pathname);
        }

        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [location])

    return (
        <>
        <div className={"notLogged_bannerBottom-pcVB1 "+theme}>
            <div className="textPart_notLogged-bB-pcVB1">
                <p id="text" lang={i18n.resolvedLanguage} title={t('home.bannerNotLogged.textAccessibility')}>{t('home.bannerNotLogged.text')}</p>
            </div>
            <div className="buttonsPart_notLogged-bB-pcVB1">
                <Link to={"/login"+pathname} hrefLang={i18n.resolvedLanguage} lang={i18n.resolvedLanguage} title={t('home.bannerNotLogged.buttons.loginAccessibility')}>
                    <div id="login" role="button" tabIndex={1}>
                        <span>{t('home.bannerNotLogged.buttons.login')}</span>
                    </div>
                </Link>
                <Link to={"/register"+pathname} hrefLang={i18n.resolvedLanguage} lang={i18n.resolvedLanguage} title={t('home.bannerNotLogged.buttons.signupAccessibility')}>
                    <div id="signup" role="button" tabIndex={2}>
                        <span>{t('home.bannerNotLogged.buttons.signup')}</span>
                    </div>
                </Link>
            </div>
        </div>
        </>
    )
}

export default NotLoggedBanner;