// import Posts loading
import PostLoading, {PostLoadingWithMedia,SpostLoading} from "../LoadingPost";
import { LoadingAppBar, LoadingNavBar } from "../Common/Loading";

export const LoadingHome = () => {
    document.body.className = "darkTheme";
    
    return (
        <>
            <LoadingAppBar />
            <LoadingNavBar />
            <section className="topMenu_pcverSB1 darkTheme">
                <div className="others_fBmVeB1 darkTheme">
                    <div id="latest" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="subs" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="trending" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
                <div className="separator_fBmVeB1 darkTheme"></div>
                <div className="filter_fBmVeB1 darkTheme">
                    <div className="subs_fMaMVB1 current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div className="certif_fMaMVB1 current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
            </section>
            <main id="mainAppHome" style={{ overflow: "hidden", position: "absolute" }}>
                <div className="mainApp_mVeSB1 darkTheme">
                    <div className="topPart_mAppMVB1 skeleton darkTheme" style={{ height: 40, width: 200, borderRadius: 5, margin: "20px 0" }}></div>
                    <div className="mainPart_mAppMVB1">
                    </div>
                    <PostLoading />
                    <PostLoadingWithMedia />
                    <PostLoading />
                    <PostLoading />
                    <PostLoading />
                    <main id="trendMainContainer">
                        <section className="trendMenu_pcverSB1 darkTheme">
                            <h1 className="skeleton darkTheme" style={{ height: 35, width: 175, borderRadius: 5 }}></h1>
                            <div className="separator_fBmVeB1 darkTheme"></div>
                            <div className="trends_fBmVeB1 darkTheme">
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                            </div>
                            <footer className="legalMentions_tMpVB1 darkTheme">
                                <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5, marginBottom: 10 }}></div>
                                <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5, marginBottom: 25 }}></div>
                                <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5 }}></div>
                            </footer>
                        </section>
                    </main>
                </div>
            </main>
        </>
      )
}

export const LoadingSpost = () => {
    document.body.className = "darkTheme";
    
    return (
        <>
            <LoadingAppBar />
            <LoadingNavBar />
            <section className="topMenu_pcverSB1 darkTheme">
                <div className="others_fBmVeB1 darkTheme">
                    <div id="latest" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="subs" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                    <div id="trending" className="current skeleton darkTheme" style={{ height: 50, cursor: "default" }}></div>
                </div>
            </section>
            <main id="mainAppHome" style={{ overflow: "hidden", position: "absolute" }}>
                <div className="mainApp_mVeSB1 darkTheme">
                    <SpostLoading />
                    <main id="trendMainContainer">
                        <section className="trendMenu_pcverSB1 darkTheme">
                            <h1 className="skeleton darkTheme" style={{ height: 35, width: 175, borderRadius: 5 }}></h1>
                            <div className="separator_fBmVeB1 darkTheme"></div>
                            <div className="trends_fBmVeB1 darkTheme">
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                                <div className="trendContainer_mDrawerMVB1 skeleton darkTheme"></div>
                            </div>
                            <footer className="legalMentions_tMpVB1 darkTheme">
                                <p>
                                    <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5, marginBottom: 10 }}></div>
                                    <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5, marginBottom: 25 }}></div>
                                    <div className="skeleton darkTheme" style={{ height: 25, width: 120, borderRadius: 5 }}></div>
                                </p>
                            </footer>
                        </section>
                    </main>
                </div>
            </main>
        </>
      )
}