import axios from "axios";
import Cookies from "js-cookie";
// API VERSION
import API_VERSION from "./ApiVersion";

const token = Cookies.get('loginToken');

const  retreiveInfos = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `https://api.snot.fr/v${API_VERSION}/auth`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { auth: "rinfo", token: token }
        }).then((response) => {
            if(response.data.code){
                if(response.data.code === 401){
                    resolve(false);
                }
            }else{
                resolve(response.data.infos);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

const retreivePassword = (uid) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `https://api.snot.fr/v${API_VERSION}/auth/get/password`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                uid: uid,
                app: 'web'
            },
            data: { token: token }
        }).then((response) => {
            if(response.data.code){
                if(response.data.code === 401){
                    resolve(false);
                }
            }else{
                resolve(response.data.password);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

const  retreiveAbout = (user) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `https://api.snot.fr/v${API_VERSION}/${user}/about`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            resolve(response.data.about);
        }).catch((error) => {
            reject(error);
        })
    })
}

const verifyAuth = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `https://api.snot.fr/v${API_VERSION}/auth`,
            headers: {
                "Content-Type": "application/json",
            },
            data: { token: token }
        }).then((response) => {
            if(response.data.code){
                if(response.data.code === 401){
                    resolve(false);
                }
            }else{
                resolve(response.data.logged);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}

export {
    retreiveInfos,
    retreivePassword,
    retreiveAbout,
    verifyAuth,
}