import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from "../../Functions";

export default function NotValid() {
    const { t } = useTranslation();
    const location = useLocation();
    // Set data
    const [data, setData] = useState([]);
    // Get token
    const token = Cookies.get('loginToken') || "not_logged";

    // SEND AN EMAIL TO SUPPORT@SNOT.FR
    const sendEmail = (userInfo) => {
        $('.separator_nVPCvB1').remove();
        $('.notReceive_nVPCvB1').remove();
        Cookies.set('contact-nv-'+token, true)
        // Set email
        var plainTextContent = '[ERROR]\n\nWe\'ve received a new error from user @'+userInfo.username.toLowerCase().replace('.', '_')+'.\n\nSend a new verification link at his email address : '+userInfo.email+'\n\nThis is an automatic message, do not reply to it.';
        var htmlContent = '[ERROR]<br><br>We\'ve received a new error <b>(type: email not sent)</b> from user <a href="https://snot.fr/@'+userInfo.username.toLowerCase().replace('.', '_')+'" style="font-family:Consolas, sans-serif;">@'+userInfo.username.toLowerCase().replace(' ', '_')+'</a>.<br><br>Send a new verification link at his email address : <span style="font-family:Consolas, sans-serif;">'+userInfo.email+'</span><br><br>This is an automatic message, do not reply to it.';
        var subject = '[Error] - @'+userInfo.username.toLowerCase().replace('.', '_')+' can\'t confirm his account.';
        // Call API in order to send email
        axios({
            method: 'POST',
            url: `https://api.snot.fr/v${API_VERSION}/send/mail`,
            params: {
                token: token,
                app: 'web',
                uid: userInfo.uid
            },
            data: {
                sender: userInfo.username+" <"+userInfo.email+">",
                receiver: "SNOT Support <support@snot.fr>",
                subject: subject,
                messageHtml: htmlContent,
                textContent: plainTextContent,
            }
        })
        .then((response) => {
            // Sent
            if (response.data.msg === 'success') {
                $('#mBLogged').css({ display: "flex" })
                setTimeout(() => {
                    if ($('#mBLogged').css("display") === "block") { $('#mBLogged').css({display: "none"}) }
                }, 5000);
            }
        });
    }

    // CALL API TO VERIFY IF [USER] IS VERIFIED
    const userVerify = async () => {
        if (token !== "not_logged") {
            try {
                const response = await axios.get(
                    `https://api.snot.fr/v${API_VERSION}/${token}/verify`,
                    {
                        headers: { "Content-Type": "application/json" },
                        params: { app: 'web' }
                    }
                );
                setData(response.data);
            } catch (error) {
                console.error('Error fetching verification status:', error);
            }
        } else {
            setData({ verified: "not_logged" });
        }
    };

    // BUILD THE MODAL
    const ModalVerified = () => {
        if (data.length !== 0 && !data.verified) {
            return (<>
                <div id="mBLogged" className="darkTheme" style={{ display: "none", zIndex: 9999, width: "fit-content", padding: "0 15px" }}>
                    <div id="textN_nMAPPb1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className="fi fi-ss-check-circle" style={{ color: "#4eca26", fontSize: 26,  display: "flex", height: "fit-content", marginRight: 10 }}></i>
                        <p>{t("error.account.unverified.notifResponse")}</p>
                    </div>
                </div>
                <aside id="notValide" className="modal" aria-hidden="false" aria-modal="true">
                    <div className="modal-wrapper notValideContainer js-modal-stop">
                        <div className="logo_notValidPopUp">
                            <img src={`https://api.snot.fr/v${API_VERSION}/content/logo_vbanner?ext=png&folder=logo`} alt="Logo" title="SNOT" />
                        </div>
                        <div className="content_notValidPopup">
                            <h1 id="title" style={{ fontSize: 24 }}>{t("error.account.unverified.title")}</h1>
                            <p id="description">
                                {t("error.account.unverified.fDescription")}<br />
                                {t("error.account.unverified.sDescription")} <span style={{ fontWeight: 500 }}>"{t("general.buttons.finish")}"</span>.
                            </p>
                            {!Cookies.get('contact-nv-' + token) && (
                                <>
                                    <div className="separator_nVPCvB1"></div>
                                    <span className="notReceive_nVPCvB1" onClick={() => sendEmail(data.infos)} title="Contact the support." tabIndex={1}>
                                        {t("error.account.unverified.link")}
                                    </span>
                                </>
                            )}
                            <div className="buttonFinish_cNVPMVB1" onClick={() => window.location.reload()} tabIndex={2}>
                                <span id="button_content">{t("general.buttons.finish")}</span>
                            </div>
                        </div>
                    </div>
                </aside>
            </>);
        } else {
            return null;
        }
    };

    useEffect(() => {
        // Fetch if user is verified
        userVerify();
    }, [location]);

    return (
        <div id="8d5nJ1ds1Q5mc">
            <ModalVerified />
        </div>
    );
}