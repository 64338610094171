import {useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
// import i18next
import { useTranslation } from 'react-i18next';
// import Emojis
// -- Nature emojis
import { emojis_nature } from "../Functions/Emojis/Nature";
// -- Symbole emojis
import { emojis_symbole } from "../Functions/Emojis/Symbole";

export const StreakBar = ({logs}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    // Set Theme Data
    const [theme, setTheme] = useState('darkTheme');

    // Compare today and last day streak was done
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // -- TO KNOW IF [USER] VALIDATE TODAY'S STREAK
    const match_date = new Date(logs.day);
          match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);

    var lastStreak = '';
    switch (diffDays) {
        case 0:
            lastStreak = "done";
            break;
        case 1:
            lastStreak = "not_done";
            break;
        default:
            lastStreak = "not_done_finished";
    }

    // -- TO KNOW HOW MUCH DAYS SINCE STREAK ACTIVATION
    const oneDay = 24 * 60 * 60 * 1000;
    const validatedDay = new Date(logs.validated);
          validatedDay.setHours(0, 0, 0, 0);
          validatedDay.setDate(validatedDay.getDate() - 1);

    const streakCount = Math.round(Math.abs((validatedDay - match_date) / oneDay));

    // Select random string for streak
    const randTextStreak = (type) => {
        var streakStrings = { medium: t('streak.activate.medium.1'), normal: t('streak.activate.normal.1') }
        if(!logs.message) {
            if((streakCount === 1 || streakCount === 0) && lastStreak === "done") {
                streakStrings = { medium: t('streak.activated.medium.1'), normal: t('streak.activated.normal.1'), }
            }else if(streakCount > 1 && lastStreak === "done"){
                streakStrings = { medium: t('streak.continue.medium.1'), normal: t('streak.continue.normal.1'), }
            }else if(streakCount > 1 && lastStreak === "not_done"){
                streakStrings = { medium: t('streak.warn.medium.2'), normal: t('streak.warn.normal.2'), }
            }else if (lastStreak === "not_done"){
                streakStrings = { medium: t('streak.warn.medium.1'), normal: t('streak.warn.normal.1'), }
            }else if (lastStreak === "not_done_finished"){
                streakStrings = { medium: t('streak.activate.medium.3'), normal: t('streak.activate.normal.3'), }
            }
        }

        if(type === "medium") return streakStrings.medium;
        if(type === "normal") return streakStrings.normal;
    }
    
    useEffect(() =>{
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [location])

    return (
        <>
        <div className="streakInfo_PcVB1" style={{ transform: "translateY(1000px)", opacity: 0 }}>
            <div className={"streakInfo_containerMVB1 "+theme} style={{ width: 710 }}>
                <img src={emojis_nature.fire} alt="Streak icon" title="Streak - It's SNOT moment!" />
                <p style={{ fontSize: 14 }}>
                    <span className="medium">{randTextStreak('medium')}</span> {randTextStreak('normal')}
                </p>
                {
                    (streakCount !== 0 && lastStreak !== "not_done_finished") ?
                        lastStreak !== "not_done" ?
                        <div className="dayNumber_streakIPCVB1">
                            <span>{t('streak.day')} {streakCount}</span>
                        </div>
                        :
                        <div className="dayNumber_streakIPCVB1" style={{ backgroundColor: "#F40C0C" }}>
                            <img src={emojis_symbole.warning} alt="Warning icon" title="Streak - Post to keep it!" style={{ width: 16, height: 16, margin: "0 5px 0 10px" }} />
                            <span style={{ marginRight: 10 }}>{t('streak.day')} {streakCount}</span>
                        </div>
                    :
                    <div className="dayNumber_streakIPCVB1" style={{ cursor: "pointer" }} onClick={() => {
                        if(location.pathname !== "/"){
                            navigate("/add?rel="+location.pathname);
                        }else{
                            navigate("/add");
                        }
                    }}>
                        <span>{t('streak.post')}</span>
                    </div>
                }
            </div>
        </div>
        </>
    )
}

export const StreakProfile = ({logs, theme}) => {
    // Compare today and last day streak was done
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // -- TO KNOW IF [USER] VALIDATE TODAY'S STREAK
    const match_date = new Date(logs.day);
          match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
    let diffDays = parseInt(diff);

    var lastStreak = '';
    switch (diffDays) {
        case 0:
            lastStreak = "done";
            break;
        case 1:
            lastStreak = "not_done";
            break;
        default:
            lastStreak = "not_done_finished";
    }

    // -- TO KNOW HOW MUCH DAYS SINCE STREAK ACTIVATION
    const oneDay = 24 * 60 * 60 * 1000;
    const validatedDay = new Date(logs.validated);
          validatedDay.setHours(0, 0, 0, 0);
          validatedDay.setDate(validatedDay.getDate() - 1)

    var streakCount = Math.round(Math.abs((validatedDay - match_date) / oneDay));

    // If streak is not active
    if(lastStreak === "not_done_finished") streakCount = 0;
    
    return (
        <>
        <div className={"streakCount_profilePcB1 "+theme}>
            <img src={emojis_nature.fire} alt='Streak icon' title='Streak duration' />
            <span>{streakCount}</span>
        </div>
        </>
    )
}