import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import Cookies from "js-cookie";
import axios from "axios";
import DOMPurify from 'dompurify';
import * as marked from 'marked';
import { mangle } from "marked-mangle";
// import i18next
import { useTranslation } from "react-i18next";
// import Components
import Video from "../../Common/Media/VideoPlayer";
import Audio from "../../Common/Media/AudioPlayer";
// import Functions
import { API_VERSION } from "../../Functions";
import { retreiveInfos } from "../../Functions/Auth";
// import Images
import errorMediaDark from '../../../img/error/media_dark.png';
import errorMediaLight from '../../../img/error/media_light.png';

export const ReplyPopUp = ({user, post, comment, uid, theme}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [media, setMedia] = useState(<></>);
    const [nsfwMark, setNSFWMark] = useState(false);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>
    });
    const [mediaObject, setMediaObject] = useState([]);
    const [nsfw, setNSFW] = useState('No');
    // Set lenght
    const [lenghtContent, setLenght] = useState(0);
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    // Set file format (accepted)
    const acceptedFF = [
        // IMAGE
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
        // VIDEO
        "video/mj2",
        "video/mp4",
        "video/mp2t",
        "video/x-msvideo",
        "video/mpeg",
        "video/ogg",
        "video/webm",
        "video/3gpp",
        "video/3gpp2",
        "video/3gpp-tt",
        "video/AV1",
        "video/H264",
        "video/matroska",
        "video/raw",
        // AUDIO
        'audio/3gpp',
        'audio/3gpp2',
        'audio/aac',
        'audio/ac3',
        'audio/matroska',
        'audio/mhas',
        'audio/midi-clip',
        'audio/MPA',
        'audio/mp4',
        'audio/MP4A-LATM',
        'audio/mpa-robust',
        'audio/mpeg',
        'audio/mpeg4-generic',
        'audio/ogg',
    ]
    // -- Only images, videos and audio
    const acceptedImage = [
        // IMAGE
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
    ]
    const acceptedVideo = [
        "video/mj2",
        "video/mp4",
        "video/mp2t",
        "video/x-msvideo",
        "video/mpeg",
        "video/ogg",
        "video/webm",
        "video/3gpp",
        "video/3gpp2",
        "video/3gpp-tt",
        "video/AV1",
        "video/H264",
        "video/matroska",
        "video/raw",
    ]
    const acceptedAudio = [
        'audio/3gpp',
        'audio/3gpp2',
        'audio/aac',
        'audio/ac3',
        'audio/matroska',
        'audio/mhas',
        'audio/midi-clip',
        'audio/MPA',
        'audio/mp4',
        'audio/MP4A-LATM',
        'audio/mpa-robust',
        'audio/mpeg',
        'audio/mpeg4-generic',
        'audio/ogg',
    ]
    // -- Others formats
    const acceptedOther = [
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain'
    ]

    // Publish functions
    const publish = () => {
        $('.infoReply_mPACPcVB1.reply_'+comment.uid).css('color', '#707070');
        $('.infoReply_mPACPcVB1.reply_'+comment.uid).text(t('comment.add.loading'));
        // ...
        // FOR MARKED
        // -- Override function
        const renderer = {
            link(href) {
                return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replaceAll('mailto:', '')}">${href.replaceAll('mailto:', '')}</a>`;
            }
        };
        // -- Set options
        marked.use({
            breaks: true,
            renderer,
        }, mangle());

        if((DOMPurify.sanitize($('.textArea_aReplyPcVB1.add_'+comment.uid+' #content').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}) !== '') || ($('.add_'+comment.uid+' .mediaComment_aComPcVB1') && localStorage.getItem('media-reply'))){
            axios.post(
                `https://api.snot.fr/v${API_VERSION}/${post}/${comment.uid}/reply`,
                { uid: uid, user: user.uid, reply: marked.parseInline(DOMPurify.sanitize($('.textArea_aReplyPcVB1.add_'+comment.uid+' #content').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']})), textonly: DOMPurify.sanitize($('.textArea_aReplyPcVB1.add_'+comment.uid+' #content').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}), media: JSON.stringify(mediaObject), nsfw: nsfw },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(response => {
                if(response.data.message === "post_created"){
                    $('.infoReply_mPACPcVB1.reply_'+comment.uid).css('color', 'rgb(29, 155, 240)');
                    $('.infoReply_mPACPcVB1.reply_'+comment.uid).text(t('comment.reply.success'));
                    // ...
                    setTimeout(() => window.location.replace('/spost/'+post+'/reply/'+uid+'?parent='+comment.uid), 1000)
                }
            })
        }else {
            $('.infoReply_mPACPcVB1.reply_'+comment.uid).css('color', '#db4e4e');
            $('.infoReply_mPACPcVB1.reply_'+comment.uid).text(t('comment.add.error'));
            $('.textArea_aReplyPcVB1.add_'+comment.uid).css('border-color', '#db4e4e');
        }
    }

    // -- Upload file
    const uploadFile = (fileName, dataObj, fileExt) => {
        var fileObject = fetch(dataObj) 
        .then(r => r.blob())
        .then(blob => {
            fileObject = new File([blob], fileName, {type: fileExt});
            // ...
            const formData = new FormData();
            formData.append("file", fileObject, fileName);
            formData.append("user", user.uid);
            formData.append("uid", uid);
            // UPLOAD
            axios.post(`https://api.snot.fr/v${API_VERSION}/upload/media-reply`, formData, {
                params: {
                    token: Cookies.get('loginToken'),
                    uid: user.uid,
                    app: 'web'
                }
            }).then(() => {
                // -- VERIFY MEDIA (NSFW)
                // -- -- Verify if file is an image & user didn't already checked NSFW
                if(acceptedImage.includes(fileExt) && !$('#ageReply').hasClass('selected')){
                    axios.post(
                        `https://api.snot.fr/v${API_VERSION}/ia/detect/nsfw?token=${Cookies.get('loginToken')}&uid=${user.uid}&app=web`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: { url: '../content.snot.fr/media/reply/'+user.uid+'/'+fileName, type: fileExt }
                        }
                    ).then(response => {
                        if(response.data.nsfw === 1){
                            $('.reply_'+comment.uid+' #ageReply').addClass('selected');
                            $('.infoReply_mPACPcVB1.reply_'+comment.uid).css('color', 'rgb(29, 155, 240)');
                            $('.infoReply_mPACPcVB1.reply_'+comment.uid).text(t('comment.reply.detected'));
                            // SET BLUR (is has media)
                            if($('.add_'+comment.uid+' .mediaReply_aReplyPcVB1')){
                                if($('.add_'+comment.uid+' .img_mReplyPcVB1')) $('.add_'+comment.uid+' .img_mReplyPcVB1').css('filter', 'blur(16px)');
                            }
                            setNSFWMark(true)
                            setNSFW("Yes");
                        }
                    })
                }
            })
        });
    }

    useEffect(() => {
        retreiveInfos().then((res) => {
            if(res !== "This user doesn't exist.") {
                // Set badges
                if (res.certified === "Yes") {
                    var colorBadge = "#000";
                    if(theme === "darkTheme"){
                        colorBadge = "#fff"
                    }
                    setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
                }
                if (res.staff === "Yes") {
                    setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }
            }
        })

        // EVENTS LISTENERS
        document.querySelector('.reply_'+comment.uid+' #mediaReply').addEventListener('click',  () => $('#mediaSelectReply_'+comment.uid).trigger('click'));
        $('.reply_'+comment.uid+' #ageReply').off('click')
        $('.reply_'+comment.uid+' #ageReply').on('click', () => {
            if(!$('.reply_'+comment.uid+' #ageReply').hasClass('selected')) {
                $('.reply_'+comment.uid+' #ageReply').addClass('selected');
                $('.infoReply_mPACPcVB1.reply_'+comment.uid).css('color', 'rgb(29, 155, 240)');
                $('.infoReply_mPACPcVB1.reply_'+comment.uid).text(t('comment.reply.nsfw'));
                // SET BLUR (is has media)
                if($('.add_'+comment.uid+' .mediaReply_aReplyPcVB1')){
                    if($('.add_'+comment.uid+' .img_mReplyPcVB1')) $('.add_'+comment.uid+' .img_mReplyPcVB1').css('filter', 'blur(16px)');
                }
                setNSFWMark(true);
            }else{
                $('.reply_'+comment.uid+' #ageReply').removeClass('selected');
                $('.infoReply_mPACPcVB1.reply_'+comment.uid).text('');
                // UNSET BLUR (is has media)
                if($('.add_'+comment.uid+' .mediaReply_aReplyPcVB1')){
                    if($('.add_'+comment.uid+' .img_mReplyPcVB1')) $('.add_'+comment.uid+' .img_mReplyPcVB1').css('filter', 'blur(0px)');
                }
                setNSFWMark(false);
            }
        })
        
        // -- To focus input
        document.querySelector('.textArea_aReplyPcVB1.add_'+comment.uid).addEventListener('click', (e) => {e.preventDefault();$('.add_'+comment.uid+' .replyContent_tAPcVB1 #content').trigger('focus')});
        if(document.querySelector('.add_'+comment.uid+' .mediaReply_aReplyPcVB1')){
            document.querySelector('.add_'+comment.uid+' .mediaReply_aReplyPcVB1').addEventListener('click', (e) => e.preventDefault());
        }

        // -- For file upload
        document.getElementById('mediaSelectReply_'+comment.uid).addEventListener('change', (e) => {
            for(const file of e.currentTarget.files){
                if(acceptedFF.includes(file.type)){
                    // Replace extension
                    var newExt = file.name.substr(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                    var replacedExtension = uid+'.'+newExt;
                    if (acceptedImage.includes(file.type)) {
                        replacedExtension = uid+".webp";
                        newExt = "webp";
                    }else if (acceptedVideo.includes(file.type)) {
                        replacedExtension = uid+".webm";
                        newExt = "webm";
                    }else if (acceptedAudio.includes(file.type)) {
                        replacedExtension = uid+".aac";
                        newExt = "aac";
                    }

                    // ...
                    if (acceptedImage.includes(file.type) || acceptedVideo.includes(file.type) || acceptedAudio.includes(file.type)){
                        // Create URL for media
                        var filePreview = URL.createObjectURL(file);
                        if(acceptedImage.includes(file.type)){
                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme}>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                    <img className="img_mReplyPcVB1"
                                        alt={replacedExtension}
                                        title={replacedExtension}
                                        src={filePreview}
                                        onError={({ currentTarget }) => {
                                            if(theme === "darkTheme"){
                                                currentTarget.src = errorMediaDark;
                                            }else{
                                                currentTarget.src = errorMediaLight;
                                            }
                                        }}
                                        referrerPolicy="no-referrer"
                                    />
                                </div>
                                </>
                            );
                        }else if(acceptedAudio.includes(file.type)){
                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme} style={{width: "100%"}}>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                    <Audio audioRef={0} audioSrc={filePreview} params={{stopPropagation: true, comment: true}}></Audio>
                                </div>
                                </>
                            );
                        }else{
                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme}>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                    <Video videoRef={0} videoSrc={filePreview} params={{stopPropagation: true, comment: true, nsfw: nsfwMark}}></Video>
                                </div>
                                </>
                            );
                        }

                        // Create a local storage variable
                        localStorage.setItem('media-reply-url', filePreview)
                    }else{
                        var icon = 'https://api.snot.fr/v'+API_VERSION+'/content/base_file?ext=png';
                        var alt = 'Shared file';
                        if(acceptedOther.includes(file.type)){
                            if(file.type === 'text/plain'){
                                icon = 'https://api.snot.fr/v'+API_VERSION+'/content/txt_icon?ext=png';
                                alt = 'Text file';
                            }else if (file.type === 'application/pdf'){
                                icon = 'https://api.snot.fr/v'+API_VERSION+'/content/pdf_icon?ext=png';
                                alt = 'PDF file';
                            }

                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme} style={{ display: "flex", alignItems: "center" }}>
                                    <div className="otherFile_mReplyPcVB1">
                                        <img src={icon} title={replacedExtension} alt={alt} />
                                        <p>{replacedExtension}</p>
                                    </div>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                </div>
                                </>
                            );
                        }
                    }
                    // LOCAL STORAGE & ARRAY
                    if(mediaObject.length >= 1){
                        mediaObject.splice(0, 1);
                    }
                    mediaObject.push('https://api.snot.fr/v'+API_VERSION+'/content/reply/'+user.uid+'/'+uid+"?ext="+newExt)
                    // -- Create local storage var
                    localStorage.setItem('media-reply', uid);
                    localStorage.setItem('media-reply-type', newExt)
                    localStorage.setItem('media-reply-type-mime', file.type)
                    // ON SUBMIT
                    $('.replyBtn_aReplyPcVB1.reply_'+comment.uid).on('click', () => uploadFile(replacedExtension, URL.createObjectURL(file), file.type));
                }
            }
        })
    }, [theme, user, post])

    useEffect(() => {
        // -- For submit button
        $('.replyBtn_aReplyPcVB1.reply_'+comment.uid).off('click')
        $('.replyBtn_aReplyPcVB1.reply_'+comment.uid).on('click', () => publish());
    }, [])

    useEffect(() => {
        // EVENT
        if(document.getElementById('deleteReplyMedia')){
            document.getElementById('deleteReplyMedia').addEventListener('click', () => {
                setMedia(<></>)
                localStorage.removeItem('media-reply');
                localStorage.removeItem('media-reply-type');
                localStorage.removeItem('media-reply-type-mime');
                localStorage.removeItem('media-reply-url');
            });
        }
    }, [media])

    useEffect(() => {
        if(localStorage.getItem('media-reply') && localStorage.getItem('media-reply-type-mime') && localStorage.getItem('media-reply-url') && localStorage.getItem('media-reply-type')){
            if(acceptedVideo.includes(localStorage.getItem('media-reply-type-mime'))){
                setMedia(
                    <>
                    <div className={"mediaReply_aReplyPcVB1 "+theme}>
                        <div id="deleteMediaReply" role="button">
                            <i className="fi fi-rr-cross"></i>
                        </div>
                        <Video videoRef={0} videoSrc={localStorage.getItem('media-reply-url')} params={{stopPropagation: true, comment: true, nsfw: nsfwMark}}></Video>
                    </div>
                    </>
                );
            }
        }
    }, [nsfwMark])

    return (
        <>
            {/* ADD REPLY POP UP */}
            <aside id={"addReply_"+comment.uid} className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className={"modal-wrapper js-modal-stop "+theme} {...(theme === "darkTheme" ? { style: {backgroundColor: "#101010",padding: 0,minHeight: 500,width: 600,overflow: "hidden"} }: { style: {backgroundColor: "#EEE",color:"#000",padding: 0,minHeight: 500,width: 600,overflow: "hidden"}})}>
                    <div className={"topPart_fSLMPcB1 "+theme}>
                        <h1 {...(theme === "darkTheme" ? { style: {color: "#fff"}}: { style: {color:"#000"}})}>
                            {t('comment.reply.title')} <span {...(theme === "darkTheme" ? { style: { color: "#9A9A9A" } } : { style: { color: "#707070" } })}>@{comment.user.toLowerCase().replace('.', '_')}</span>
                        </h1>
                        <button className={"js-modal-close "+theme}>
                            <i className="fi fi-rr-cross"></i>
                        </button>
                    </div>
                    <div className="mainPart_aComPcVB1">
                        <p className={"infoReply_mPACPcVB1 reply_"+comment.uid} style={{margin: "-10px 0 10px 0",color:"#1D9BF0",fontSize: 14}}></p>
                        <div className="profilePart_mPACPcVB1">
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme } style={{ width: 50, height: 50 }}>
                                <img alt={"@"+user.usertag.replace('.', '_').toLowerCase()} title={user.username} src={user.avatar} className={"avatar_addReplyPcVB1 uid_"+comment.uid}
                                    onError={() => {
                                        if(!$('.avatar_addReplyPcVB1.uid_'+comment.uid).attr('src').includes('.googleusercontent.com')){
                                            $('.avatar_addReplyPcVB1.uid_'+comment.uid).attr('src', 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp')
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <span id="username">{user.username}</span>
                            <span id="usertag">@{user.usertag.toLowerCase().replace('.', '_')} {badges.certified}{badges.staff}</span>
                        </div>
                        <div className={"textArea_aReplyPcVB1 add_"+comment.uid+" "+theme}>
                            <div className="replyContent_tAPcVB1">
                                <textarea tabIndex={1} required={true} placeholder={t('comment.reply.placeholder')} title={t('comment.reply.placeholder')} name="content" id="content" maxLength={250}
                                onChange={(e) => {
                                    if(e.currentTarget.value.length > 200){
                                        setLenght(e.currentTarget.value.length);
                                        $('.add_'+comment.uid+' #charactersCount').css('display', 'block')
                                    }else{
                                        setLenght(e.currentTarget.value.length);
                                        $('.add_'+comment.uid+' #charactersCount').css('display', 'none')
                                    }
                                    // Error UX
                                    if(e.currentTarget.value.trim() !== "") {
                                        if(e.currentTarget.value.length > 250){
                                            $('.textArea_aReplyPcVB1.add_'+comment.uid).css({ borderColor: "#db4e4e" })
                                        }else{
                                            $('.textArea_aReplyPcVB1.add_'+comment.uid).css({ borderColor: "#CFCFCF" });
                                            if(theme === "darkTheme") $('.textArea_aReplyPcVB1.add_'+comment.uid).css({ borderColor: "#202020" });
                                        }
                                    }else{
                                        if($('.add_'+comment.uid+' .mediaReply_aReplyPcVB1') && localStorage.getItem('media')){}
                                        else{
                                            $('.textArea_aReplyPcVB1.add_'+comment.uid).css({ borderColor: "#db4e4e" })
                                        }
                                    }
                                }}
                                onInput={(e) => {
                                    var textarea = document.querySelector('.textArea_aReplyPcVB1.add_'+comment.uid+' #content')
                                    textarea.style.height = "";
                                    textarea.style.height = textarea.scrollHeight + "px"
                                }}
                                ></textarea>
                                {media}
                            </div>
                            <span id="charactersCount" style={{ fontSize: 14, marginLeft: 5, marginTop: 5, display: "none" }}>{lenghtContent}/250</span>
                        </div>
                        <div className={"options_textAreaACPcVB1 reply_"+comment.uid+" "+theme}>
                            <div id="ageReply">
                                <i className="fi fi-rr-age-restriction-eighteen"></i>
                            </div>
                            <div id="mediaReply">
                                <i className="fi fi-rr-add-image"></i>
                            </div>
                            <input title={t('comment.add.media')} type="file" name="mediaSelectReply" id={"mediaSelectReply_"+comment.uid} accept="video/*,image/*,audio/*" style={{ display: "none" }} />
                        </div>
                        <div className={"replyBtn_aReplyPcVB1 reply_"+comment.uid+" "+theme}>
                            <span>{t('comment.reply.button')}</span>
                        </div>
                        <div className="infos_aComPcVB1">
                            <i className="fi fi-rr-info" style={{ display: "flex",marginRight: 5 }}></i>
                            <span>{t('add.footer')} <a href={"https://about.snot.fr/"+i18n.resolvedLanguage+"/content"} hrefLang={i18n.resolvedLanguage} title={t('footer.content')} target="_blank" rel="noreferrer">{t('footer.content')}</a>.</span>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}

export const ReplyToPopUp = ({user, post, comment, reply, uid, theme}) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [media, setMedia] = useState(<></>);
    const [nsfwMark, setNSFWMark] = useState(false);
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>
    });
    const [mediaObject, setMediaObject] = useState([]);
    const [nsfw, setNSFW] = useState('No');
    // Set lenght
    const [lenghtContent, setLenght] = useState(0);
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    // Set file format (accepted)
    const acceptedFF = [
        // IMAGE
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
        // VIDEO
        "video/mj2",
        "video/mp4",
        "video/mp2t",
        "video/x-msvideo",
        "video/mpeg",
        "video/ogg",
        "video/webm",
        "video/3gpp",
        "video/3gpp2",
        "video/3gpp-tt",
        "video/AV1",
        "video/H264",
        "video/matroska",
        "video/raw",
        // AUDIO
        'audio/3gpp',
        'audio/3gpp2',
        'audio/aac',
        'audio/ac3',
        'audio/matroska',
        'audio/mhas',
        'audio/midi-clip',
        'audio/MPA',
        'audio/mp4',
        'audio/MP4A-LATM',
        'audio/mpa-robust',
        'audio/mpeg',
        'audio/mpeg4-generic',
        'audio/ogg',
    ]
    // -- Only images, videos and audio
    const acceptedImage = [
        // IMAGE
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
    ]
    const acceptedVideo = [
        "video/mj2",
        "video/mp4",
        "video/mp2t",
        "video/x-msvideo",
        "video/mpeg",
        "video/ogg",
        "video/webm",
        "video/3gpp",
        "video/3gpp2",
        "video/3gpp-tt",
        "video/AV1",
        "video/H264",
        "video/matroska",
        "video/raw",
    ]
    const acceptedAudio = [
        'audio/3gpp',
        'audio/3gpp2',
        'audio/aac',
        'audio/ac3',
        'audio/matroska',
        'audio/mhas',
        'audio/midi-clip',
        'audio/MPA',
        'audio/mp4',
        'audio/MP4A-LATM',
        'audio/mpa-robust',
        'audio/mpeg',
        'audio/mpeg4-generic',
        'audio/ogg',
    ]
    // -- Others formats
    const acceptedOther = [
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain'
    ]

    // Publish functions
    const publish = () => {
        $('.infoReply_mPACPcVB1.reply_'+reply.uid).css('color', '#707070');
        $('.infoReply_mPACPcVB1.reply_'+reply.uid).text(t('comment.add.loading'));
        // ...
        // FOR MARKED
        // -- Override function
        const renderer = {
            link(href) {
                return `<a href="${href}" target="_blank" title="${href}">${href}</a>`;
            }
        };
        // -- Set options
        marked.use({
            breaks: true,
            renderer,
        }, mangle());

        if((DOMPurify.sanitize($('.textArea_aReplyPcVB1.add_'+reply.uid+' #content').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}) !== '') || ($('.add_'+reply.uid+' .mediaComment_aComPcVB1') && localStorage.getItem('media-reply'))){
            axios.post(
                `https://api.snot.fr/v${API_VERSION}/${post}/${comment.uid}/reply`,
                { uid: uid, user: user.uid, reply: marked.parseInline(DOMPurify.sanitize($('.textArea_aReplyPcVB1.add_'+reply.uid+' #content').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']})), textonly: DOMPurify.sanitize($('.textArea_aReplyPcVB1.add_'+reply.uid+' #content').val().trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br'], FORBID_ATTR: ['style']}), media: JSON.stringify(mediaObject), nsfw: nsfw },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(response => {
                if(response.data.message === "post_created"){
                    $('.infoReply_mPACPcVB1.reply_'+reply.uid).css('color', 'rgb(29, 155, 240)');
                    $('.infoReply_mPACPcVB1.reply_'+reply.uid).text(t('comment.reply.success'));
                    // ...
                    setTimeout(() => window.location.replace('/spost/'+post+'/reply/'+uid+'?parent='+comment.uid), 1000)
                }
            })
        }else {
            $('.infoReply_mPACPcVB1.reply_'+reply.uid).css('color', '#db4e4e');
            $('.infoReply_mPACPcVB1.reply_'+reply.uid).text(t('comment.add.error'));
            $('.textArea_aReplyPcVB1.add_'+reply.uid).css('border-color', '#db4e4e');
        }
    }

    // -- Upload file
    const uploadFile = (fileName, dataObj, fileExt) => {
        var fileObject = fetch(dataObj) 
        .then(r => r.blob())
        .then(blob => {
            fileObject = new File([blob], fileName, {type: fileExt});
            // ...
            const formData = new FormData();
            formData.append("file", fileObject, fileName);
            formData.append("user", user.uid);
            formData.append("uid", uid);
            // UPLOAD
            axios.post(`https://api.snot.fr/v${API_VERSION}/upload/media-reply`, formData, {
                params: {
                    token: Cookies.get('loginToken'),
                    uid: user.uid,
                    app: 'web'
                }
            }).then(() => {
                // -- VERIFY MEDIA (NSFW)
                // -- -- Verify if file is an image & user didn't already checked NSFW
                if(acceptedImage.includes(fileExt) && !$('#ageReply').hasClass('selected')){
                    axios.post(
                        `https://api.snot.fr/v${API_VERSION}/ia/detect/nsfw?token=${Cookies.get('loginToken')}&uid=${user.uid}&app=web`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: { url: '../content.snot.fr/media/reply/'+user.uid+'/'+fileName, type: fileExt }
                        }
                    ).then(response => {
                        if(response.data.nsfw === 1){
                            $('.reply_'+reply.uid+' #ageReply').addClass('selected');
                            $('.infoReply_mPACPcVB1.reply_'+reply.uid).css('color', 'rgb(29, 155, 240)');
                            $('.infoReply_mPACPcVB1.reply_'+reply.uid).text(t('comment.reply.detected'));
                            // SET BLUR (is has media)
                            if($('.add_'+reply.uid+' .mediaReply_aReplyPcVB1')){
                                if($('.add_'+reply.uid+' .img_mReplyPcVB1')) $('.add_'+reply.uid+' .img_mReplyPcVB1').css('filter', 'blur(16px)');
                            }
                            setNSFWMark(true)
                            setNSFW("Yes");
                        }
                    })
                }
            })
        });
    }

    useEffect(() => {
        retreiveInfos().then((res) => {
            if(res !== "This user doesn't exist.") {
                // Set badges
                if (res.certified === "Yes") {
                    var colorBadge = "#000";
                    if(theme === "darkTheme"){
                        colorBadge = "#fff"
                    }
                    setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: colorBadge }}></i>});
                }
                if (res.staff === "Yes") {
                    setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
                }
            }
        })

        // EVENTS LISTENERS
        document.querySelector('.reply_'+reply.uid+' #mediaReply').addEventListener('click',  () => $('#mediaSelectReply_'+reply.uid).trigger('click'));
        $('.reply_'+reply.uid+' #ageReply').off('click')
        $('.reply_'+reply.uid+' #ageReply').on('click', () => {
            if(!$('.reply_'+reply.uid+' #ageReply').hasClass('selected')) {
                $('.reply_'+reply.uid+' #ageReply').addClass('selected');
                $('.infoReply_mPACPcVB1.reply_'+reply.uid).css('color', 'rgb(29, 155, 240)');
                $('.infoReply_mPACPcVB1.reply_'+reply.uid).text(t('comment.reply.nsfw'));
                // SET BLUR (is has media)
                if($('.add_'+reply.uid+' .mediaReply_aReplyPcVB1')){
                    if($('.add_'+reply.uid+' .img_mReplyPcVB1')) $('.add_'+reply.uid+' .img_mReplyPcVB1').css('filter', 'blur(16px)');
                }
                setNSFWMark(true);
            }else{
                $('.reply_'+reply.uid+' #ageReply').removeClass('selected');
                $('.infoReply_mPACPcVB1.reply_'+reply.uid).text('');
                // UNSET BLUR (is has media)
                if($('.add_'+reply.uid+' .mediaReply_aReplyPcVB1')){
                    if($('.add_'+reply.uid+' .img_mReplyPcVB1')) $('.add_'+reply.uid+' .img_mReplyPcVB1').css('filter', 'blur(0px)');
                }
                setNSFWMark(false);
            }
        })
        
        // -- To focus input
        document.querySelector('.textArea_aReplyPcVB1.add_'+reply.uid).addEventListener('click', (e) => {e.preventDefault();$('.add_'+reply.uid+' .replyContent_tAPcVB1 #content').trigger('focus')});
        if(document.querySelector('.add_'+reply.uid+' .mediaReply_aReplyPcVB1')){
            document.querySelector('.add_'+reply.uid+' .mediaReply_aReplyPcVB1').addEventListener('click', (e) => e.preventDefault());
        }

        // -- For file upload
        document.getElementById('mediaSelectReply_'+reply.uid).addEventListener('change', (e) => {
            for(const file of e.currentTarget.files){
                if(acceptedFF.includes(file.type)){
                    // Replace extension
                    var newExt = file.name.substr(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                    var replacedExtension = uid+'.'+newExt;
                    if (acceptedImage.includes(file.type)) {
                        replacedExtension = uid+".webp";
                        newExt = "webp";
                    }else if (acceptedVideo.includes(file.type)) {
                        replacedExtension = uid+".webm";
                        newExt = "webm";
                    }else if (acceptedAudio.includes(file.type)) {
                        replacedExtension = uid+".aac";
                        newExt = "aac";
                    }

                    // ...
                    if (acceptedImage.includes(file.type) || acceptedVideo.includes(file.type) || acceptedAudio.includes(file.type)){
                        // Create URL for media
                        var filePreview = URL.createObjectURL(file);
                        if(acceptedImage.includes(file.type)){
                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme}>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                    <img className="img_mReplyPcVB1"
                                        alt={replacedExtension}
                                        title={replacedExtension}
                                        src={filePreview}
                                        onError={({ currentTarget }) => {
                                            if(theme === "darkTheme"){
                                                currentTarget.src = errorMediaDark;
                                            }else{
                                                currentTarget.src = errorMediaLight;
                                            }
                                        }}
                                        referrerPolicy="no-referrer"
                                    />
                                </div>
                                </>
                            );
                        }else if(acceptedAudio.includes(file.type)){
                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme} style={{width: "100%"}}>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                    <Audio audioRef={0} audioSrc={filePreview} params={{stopPropagation: true, comment: true}}></Audio>
                                </div>
                                </>
                            );
                        }else{
                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme}>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                    <Video videoRef={0} videoSrc={filePreview} params={{stopPropagation: true, comment: true, nsfw: nsfwMark}}></Video>
                                </div>
                                </>
                            );
                        }

                        // Create a local storage variable
                        localStorage.setItem('media-reply-url', filePreview)
                    }else{
                        var icon = 'https://api.snot.fr/v'+API_VERSION+'/content/base_file?ext=png';
                        var alt = 'Shared file';
                        if(acceptedOther.includes(file.type)){
                            if(file.type === 'text/plain'){
                                icon = 'https://api.snot.fr/v'+API_VERSION+'/content/txt_icon?ext=png';
                                alt = 'Text file';
                            }else if (file.type === 'application/pdf'){
                                icon = 'https://api.snot.fr/v'+API_VERSION+'/content/pdf_icon?ext=png';
                                alt = 'PDF file';
                            }

                            setMedia(
                                <>
                                <div className={"mediaReply_aReplyPcVB1 "+theme} style={{ display: "flex", alignItems: "center" }}>
                                    <div className="otherFile_mReplyPcVB1">
                                        <img src={icon} title={replacedExtension} alt={alt} />
                                        <p>{replacedExtension}</p>
                                    </div>
                                    <div id="deleteReplyMedia" role="button">
                                        <i className="fi fi-rr-cross"></i>
                                    </div>
                                </div>
                                </>
                            );
                        }
                    }
                    // LOCAL STORAGE & ARRAY
                    if(mediaObject.length >= 1){
                        mediaObject.splice(0, 1);
                    }
                    mediaObject.push('https://api.snot.fr/v'+API_VERSION+'/content/reply/'+user.uid+'/'+localStorage.getItem('media-reply')+"?ext="+localStorage.getItem('media-reply-type'))
                    // -- Create local storage var
                    localStorage.setItem('media-reply', uid);
                    localStorage.setItem('media-reply-type', newExt)
                    localStorage.setItem('media-reply-type-mime', file.type)
                    // ON SUBMIT
                    $('.replyBtn_aReplyPcVB1.reply_'+reply.uid).on('click', () => uploadFile(replacedExtension, URL.createObjectURL(file), file.type));
                }
            }
        })
    }, [theme, user, post])

    useEffect(() => {
        // -- For submit button
        $('.replyBtn_aReplyPcVB1.reply_'+reply.uid).off('click')
        $('.replyBtn_aReplyPcVB1.reply_'+reply.uid).on('click', () => publish());
    }, [])

    useEffect(() => {
        // EVENT
        if(document.getElementById('deleteReplyMedia')){
            document.getElementById('deleteReplyMedia').addEventListener('click', () => {
                setMedia(<></>)
                localStorage.removeItem('media-reply');
                localStorage.removeItem('media-reply-type');
                localStorage.removeItem('media-reply-type-mime');
                localStorage.removeItem('media-reply-url');
            });
        }
    }, [media])

    useEffect(() => {
        if(localStorage.getItem('media-reply') && localStorage.getItem('media-reply-type-mime') && localStorage.getItem('media-reply-url') && localStorage.getItem('media-reply-type')){
            if(acceptedVideo.includes(localStorage.getItem('media-reply-type-mime'))){
                setMedia(
                    <>
                    <div className={"mediaReply_aReplyPcVB1 "+theme}>
                        <div id="deleteMediaReply" role="button">
                            <i className="fi fi-rr-cross"></i>
                        </div>
                        <Video videoRef={0} videoSrc={localStorage.getItem('media-reply-url')} params={{stopPropagation: true, comment: true, nsfw: nsfwMark}}></Video>
                    </div>
                    </>
                );
            }
        }
    }, [nsfwMark])

    return (
        <>
            {/* ADD REPLY POP UP */}
            <aside id={"addReply_"+reply.uid} className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
                <div className={"modal-wrapper js-modal-stop "+theme} {...(theme === "darkTheme" ? { style: {backgroundColor: "#101010",padding: 0,minHeight: 500,width: 600,overflow: "hidden"} }: { style: {backgroundColor: "#EEE",color:"#000",padding: 0,minHeight: 500,width: 600,overflow: "hidden"}})}>
                    <div className={"topPart_fSLMPcB1 "+theme}>
                        <h1 {...(theme === "darkTheme" ? { style: {color: "#fff"}}: { style: {color:"#000"}})}>
                            {t('comment.reply.title')} <span {...(theme === "darkTheme" ? { style: { color: "#9A9A9A" } } : { style: { color: "#707070" } })}>@{reply.user.toLowerCase().replace('.', '_')}</span>
                        </h1>
                        <button className={"js-modal-close "+theme}>
                            <i className="fi fi-rr-cross"></i>
                        </button>
                    </div>
                    <div className="mainPart_aComPcVB1">
                        <p className={"infoReply_mPACPcVB1 reply_"+reply.uid} style={{margin: "-10px 0 10px 0",color:"#1D9BF0",fontSize: 14}}></p>
                        <div className="profilePart_mPACPcVB1">
                            <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme } style={{ width: 50, height: 50 }}>
                                <img alt={"@"+user.usertag.replace('.', '_').toLowerCase()} title={user.username} src={user.avatar} className={"avatar_addReplyPcVB1 uid_"+reply.uid}
                                    onError={() => {
                                        if(!$('.avatar_addReplyPcVB1.uid_'+reply.uid).attr('src').includes('.googleusercontent.com')){
                                            $('.avatar_addReplyPcVB1.uid_'+reply.uid).attr('src', 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp')
                                        }
                                    }}
                                style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                            </div>
                            <span id="username">{user.username}</span>
                            <span id="usertag">@{user.usertag.toLowerCase().replace('.', '_')} {badges.certified}{badges.staff}</span>
                        </div>
                        <div className={"textArea_aReplyPcVB1 add_"+reply.uid+" "+theme}>
                            <div className="replyContent_tAPcVB1">
                                <textarea tabIndex={1} required={true} placeholder={t('comment.reply.placeholder')} title={t('comment.reply.placeholder')} defaultValue={'@'+reply.user.toLowerCase().replace('.', '_')} name="content" id="content" maxLength={250}
                                onChange={(e) => {
                                    if(e.currentTarget.value.length > 200){
                                        setLenght(e.currentTarget.value.length);
                                        $('.add_'+reply.uid+' #charactersCount').css('display', 'block')
                                    }else{
                                        setLenght(e.currentTarget.value.length);
                                        $('.add_'+reply.uid+' #charactersCount').css('display', 'none')
                                    }
                                    // Error UX
                                    if(e.currentTarget.value.trim() !== "") {
                                        if(e.currentTarget.value.length > 250){
                                            $('.textArea_aReplyPcVB1.add_'+reply.uid).css({ borderColor: "#db4e4e" })
                                        }else{
                                            $('.textArea_aReplyPcVB1.add_'+reply.uid).css({ borderColor: "#CFCFCF" });
                                            if(theme === "darkTheme") $('.textArea_aReplyPcVB1.add_'+reply.uid).css({ borderColor: "#202020" });
                                        }
                                    }else{
                                        if($('.add_'+reply.uid+' .mediaReply_aReplyPcVB1') && localStorage.getItem('media')){}
                                        else{
                                            $('.textArea_aReplyPcVB1.add_'+reply.uid).css({ borderColor: "#db4e4e" })
                                        }
                                    }
                                }}
                                onInput={(e) => {
                                    var textarea = document.querySelector('.textArea_aReplyPcVB1.add_'+reply.uid+' #content')
                                    textarea.style.height = "";
                                    textarea.style.height = textarea.scrollHeight + "px"
                                }}
                                ></textarea>
                                {media}
                            </div>
                            <span id="charactersCount" style={{ fontSize: 14, marginLeft: 5, marginTop: 5, display: "none" }}>{lenghtContent}/250</span>
                        </div>
                        <div className={"options_textAreaACPcVB1 reply_"+reply.uid+" "+theme}>
                            <div id="ageReply">
                                <i className="fi fi-rr-age-restriction-eighteen"></i>
                            </div>
                            <div id="mediaReply">
                                <i className="fi fi-rr-add-image"></i>
                            </div>
                            <input title={t('comment.add.media')} type="file" name="mediaSelectReply" id={"mediaSelectReply_"+reply.uid} accept="video/*,image/*,audio/*" style={{ display: "none" }} />
                        </div>
                        <div className={"replyBtn_aReplyPcVB1 reply_"+reply.uid+" "+theme}>
                            <span>{t('comment.reply.button')}</span>
                        </div>
                        <div className="infos_aComPcVB1">
                            <i className="fi fi-rr-info" style={{ display: "flex",marginRight: 5 }}></i>
                            <span>{t('add.footer')} <a href={"https://about.snot.fr/"+i18n.resolvedLanguage+"/content"} hrefLang={i18n.resolvedLanguage} title={t('footer.content')} target="_blank" rel="noreferrer">{t('footer.content')}</a>.</span>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    )
}