export const LoadingAppBar = () => {
    document.body.className = "darkTheme";
    
    return (
        <>
            <header className="headerMain_pcverSB1 darkTheme">
                <img alt="SNOT" title="SNOT" src="https://snot.fr/img/logo/logo_vbanner.png" className="logo_headerMAmVeB1" style={{ cursor: "default" }} />
                <div id="search" className="skeleton darkTheme"></div>
            </header>
        </>
    );
}

export const LoadingNavBar = () => {
    return (
        <>
            <nav className="navBar_pcverSB1 darkTheme">
                <div className="actions_bNBmVeB1" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <div id="home" className="skeleton darkTheme" style={{ width: 50, height: 50, borderRadius: 5, marginTop: -15 }}></div>
                    <div id="friends" className="skeleton darkTheme" style={{ width: 50, height: 50, borderRadius: 5 }}></div>
                    <div id="messages" className="skeleton darkTheme" style={{ width: 50, height: 50, borderRadius: 5 }}></div>
                    <div id="profile" className="skeleton darkTheme" style={{ width: 50, height: 50, borderRadius: 5 }}></div>
                    <div id="add" className="skeleton darkTheme" style={{ width: 50, height: 50, borderRadius: 5 }}></div>
                </div>
            </nav>
        </>
      )
}