// import Skeleton
import Skeleton from "./Animation/Skeleton"

export default function FriendCardLoading({widthUser, widthUserTag}) {
    return (
        <>
            <div className="userContainer_friendsMMVB1">
                <div id="profilePart">
                    <Skeleton className={"avatar"} theme={"darkTheme"} />
                    <div className="user skeleton darkTheme" style={{ width: widthUser }}></div>
                    <div className="usertag skeleton darkTheme" style={{ marginTop: 30, width: widthUserTag }}></div>
                </div>
                <span className="friendsDate_UCMpcVB1" style={{ width: 100, height: 30 }}></span>
                <div id="actionsButtons">
                    <div className="chat_actionsBtnMVB1 skeleton darkTheme" style={{ cursor: "default" }}></div>
                    <div className="more_actionsBtnMVB1 skeleton darkTheme" style={{ cursor: "default" }}></div>
                </div>
            </div>
        </>
    )
}