import { useEffect, useState } from 'react';
import $ from 'jquery';
import { FadeLoader } from 'react-spinners';
// import Images
import errorMediaDark from '../../../img/error/media_dark.png';
import errorMediaLight from '../../../img/error/media_light.png';

const Audio = ({audioRef, audioSrc, params: { stopPropagation, marginLeft, comment, chat, key }}) => {
    // Set data
    const [playing, setPlay] = useState(false);
    const [playPauseIcon, setPlayPauseIcon] = useState('fi-sr-play');
    const [volumeIcon, setVolumeIcon] = useState('fi-ss-volume');
    const [duration, setDuration] = useState('0:00');
    const [timeCode, setTimeCode] = useState('0:00');
    // Set error
    const [error, setError] = useState(false);
    // Set loading
    const [loading, setLoading] = useState(true);
    // Set theme
    const [theme, setTheme] = useState('darkTheme');

    const containerSettings = (e) => {
        if(stopPropagation === true){
            e.stopPropagation();
        }
    }

    // FOR PLAY/PAUSE
    const togglePlayPause = () => {
        var playPromise = document.querySelector('audio[class="ref_'+audioRef+'"]').play();
        const audioPlayer = document.querySelector('audio[class="ref_'+audioRef+'"]');

        if(!playing){
            setPlay(true)
            setPlayPauseIcon('fi-sr-pause');
            audioPlayer.play();
        }else{
            setPlay(false)
            setPlayPauseIcon('fi-sr-play');
            if (playPromise !== undefined) {
                playPromise.then(_ => audioPlayer.pause())
                .catch(error => console.log(error));
            }            
        }
    }

    // TOOGLE MUTE
    const toogleMute = () =>{
        const volumeSlider = document.querySelector('.ref_'+audioRef+' .volume-slider');
        const audioPlayer = document.querySelector('audio[class="ref_'+audioRef+'"]');

        if (audioPlayer.muted) {
            audioPlayer.muted = false;
            volumeSlider.value = audioPlayer.volume;
        }else {
            audioPlayer.muted = true;
            volumeSlider.value = 0;
        }
    }

    // UPDATE PROGRESS BAR
    const updateProgressBar = (e) => {
        const progressBar = document.querySelector('.ref_'+audioRef+' .progressBar_bBarB1');
        const audioPlayer = document.querySelector('audio[class="ref_'+audioRef+'"]');
        const progress = audioPlayer.currentTime / audioPlayer.duration;

        progressBar.value = duration * progress;
        if (progress === 1) {
            setPlayPauseIcon('fi-sr-play');
            setPlay(false);
        }
        // Update time code span
        setTimeCode(calculateTime(audioPlayer.currentTime));
    }
    // UPDATE VOLUME ICON
    const updateVolumeIcon = () => {
        const audioPlayer = document.querySelector('audio[class="ref_'+audioRef+'"]');

        if (audioPlayer.muted || audioPlayer.volume === 0) {
            setVolumeIcon("fi-ss-volume-mute");
        }else if (audioPlayer.volume < 0.5) {
            setVolumeIcon("fi-ss-volume-down");
        } else {
            setVolumeIcon("fi-ss-volume");
        }
    }

    // GO TO SELECTED TIME CODE
    const goTimecode = (input) => {
        const audioPlayer = document.querySelector('audio[class="ref_'+audioRef+'"]');
        // SET TIME CODE
        audioPlayer.currentTime = input.value;

        if(!playing){
            setPlay(true);
            setPlayPauseIcon('fi-sr-pause');
            audioPlayer.play();
        }
    }

    // CALCULATE TIME OF VIDEO
    const calculateTime = (secs) => {
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
    }

    useEffect(() => {
        const audioPlayer = document.querySelector('audio[class="ref_'+audioRef+'"]');
        audioPlayer.volume = 0.5;

        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [])

    useEffect(() => {
        // EVENT LISTENERS FOR COMMENT AND REPLY
        if(comment === true){
            if(loading === false && error === false){
                // -- PLAY PAUSE
                document.querySelector('.play-pause-button').addEventListener('click', togglePlayPause);
                // -- VOLUME
                document.querySelector('.volume-icon').addEventListener('click', (e) => {
                    e.stopPropagation();
                    toogleMute();
                });
                document.querySelector('.volume-slider').addEventListener('click', (e) => e.stopPropagation());
            }
        }
    }, [comment, error, loading])

    return (<>
        {
            chat === true ?
            <>
            <div {...(key ? { key: key }: {})} className={"attachFiles_chatFMMVB1 ref_"+audioRef} style={{maxWidth:450,cursor:"default"}}>
                {
                    error === false ?
                        loading === false ?
                        <div className={"audioContainer_audioAMB1 "+theme+" ref_"+audioRef} onClick={(e) => containerSettings(e)}>
                            <div className="play-pause-button" onClick={togglePlayPause} style={{ background: "none" }}><i className={"fi "+playPauseIcon} style={{display:"flex",fontSize:18,marginTop:0}}></i></div>
                            <div className="timeCurrentDuration_bBarB1">{timeCode}/{calculateTime(duration)}</div>
                            <div className='timeCode_container'>
                                <input className="progressBar_bBarB1" type='range' min={0} max={duration} step={0.1} defaultValue={0} onInput={({currentTarget}) => goTimecode(currentTarget)} onClick={(e) => e.stopPropagation()} />
                            </div>
                            <div className="volumeControl_bBarB1" style={{width: 50}}>
                                <div className="volume-icon" onClick={() => toogleMute()} style={{width: 50,height: 50,cursor: "pointer",display: "flex",alignItems: 'center',justifyContent: 'center',marginLeft:0,marginTop: -10}}>
                                    <i className={"fi "+volumeIcon} style={{fontSize: 18,display: "flex"}}></i>
                                </div>
                                <div className="volume-back">
                                    <input type="range" className="volume-slider" min={0} max={1} step={0.05} defaultValue={0.5}
                                        onInput={({ currentTarget }) => {
                                            const audioPlayer = document.querySelector('audio[class="ref_' + audioRef + '"]');
                                            audioPlayer.volume = currentTarget.value;
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </div>
                            </div>
                            <audio id="audioPlayer" className={"ref_"+audioRef} src={audioSrc} preload="metadata" onTimeUpdate={updateProgressBar} onVolumeChange={updateVolumeIcon} onError={() => setError(true)} onLoadedData={({currentTarget}) => {setDuration(currentTarget.duration);setLoading(false);}} playsInline={true}></audio>
                        </div>
                        :
                        <div className={"audioContainer_audioAMB1 "+theme+" ref_"+audioRef} onClick={(e) => containerSettings(e)}>
                            <div className="play-pause-button" style={{ background: "none",cursor: "default" }}>
                                <FadeLoader
                                    color="#ffffff"
                                    cssOverride={{
                                        top: 19,
                                        left: 22
                                    }}
                                    height={6}
                                    margin={-10}
                                    speedMultiplier={0.4}
                                    width={3}
                                />
                            </div>
                            <div className="timeCurrentDuration_bBarB1">0:00/0:00</div>
                            <div className='timeCode_container'>
                                <div className="progressBar_bBarB1" style={{cursor: "default"}}></div>
                            </div>
                            <div className="volumeControl_bBarB1" style={{width: 50}}>
                                <div className="volume-icon" onClick={(e) => containerSettings(e)} style={{width: 50,height: 50,cursor: "default",display: "flex",alignItems: 'center',justifyContent: 'center',marginLeft:0,marginTop: -10}}>
                                    <i className={"fi "+volumeIcon} style={{fontSize: 18,display: "flex"}}></i>
                                </div>
                            </div>
                            <audio id="audioPlayer" className={"ref_"+audioRef} src={audioSrc} preload="metadata" onTimeUpdate={updateProgressBar} onVolumeChange={updateVolumeIcon} onError={() => setError(true)} onLoadedData={({currentTarget}) => {setDuration(currentTarget.duration);setLoading(false);}} playsInline={true}></audio>
                        </div>
                    :
                    <img src={errorMediaDark} alt="Error" title="Error while loading the audio" referrerPolicy="no-referrer"/>
                }
            </div>
            </>
            :
            <>
            <div id="attachMedia_divMSdB1" className={"ref_"+audioRef}>
                {
                    error === false ?
                        loading === false ?
                        <div className="mediaSpost" {...(comment === true ? {style: { cursor: "default", width: 'calc(100% - 40px)', marginTop: -46, border: 'none' }}: {style:{cursor: "default", width: "calc(100% - 55px)", marginLeft: marginLeft }})}>
                            <div className={"audioContainer_audioAMB1 "+theme+" ref_"+audioRef} onClick={(e) => containerSettings(e)}>
                                <div className="play-pause-button" onClick={togglePlayPause} style={{ background: "none" }}><i className={"fi "+playPauseIcon} style={{display:"flex",fontSize:18,marginTop:0}}></i></div>
                                <div className="timeCurrentDuration_bBarB1">{timeCode}/{calculateTime(duration)}</div>
                                <div className='timeCode_container'>
                                    <input className="progressBar_bBarB1" type='range' min={0} max={duration} step={0.1} defaultValue={0} onInput={({currentTarget}) => goTimecode(currentTarget)} onClick={(e) => e.stopPropagation()} />
                                </div>
                                <div className="volumeControl_bBarB1" style={{width: 50}}>
                                    <div className="volume-icon" onClick={() => toogleMute()} style={{width: 50,height: 50,cursor: "pointer",display: "flex",alignItems: 'center',justifyContent: 'center',marginLeft:0,marginTop: -10}}>
                                        <i className={"fi "+volumeIcon} style={{fontSize: 18,display: "flex"}}></i>
                                    </div>
                                    <div className="volume-back">
                                        <input type="range" className="volume-slider" min={0} max={1} step={0.05} defaultValue={0.5}
                                            onInput={({ currentTarget }) => {
                                                const audioPlayer = document.querySelector('audio[class="ref_' + audioRef + '"]');
                                                audioPlayer.volume = currentTarget.value;
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </div>
                                </div>
                                <audio id="audioPlayer" className={"ref_"+audioRef} src={audioSrc} preload="metadata" onTimeUpdate={updateProgressBar} onVolumeChange={updateVolumeIcon} onError={() => setError(true)} onLoadedData={({currentTarget}) => {setDuration(currentTarget.duration);setLoading(false);}} playsInline={true}></audio>
                            </div>
                        </div>
                        :
                        <div className="mediaSpost" {...(comment === true ? {style: { cursor: "default", width: 'calc(100% - 40px)', marginTop: -46, border: 'none' }}: {style:{cursor: "default", width: "calc(100% - 55px)", marginLeft: marginLeft }})}>
                            <div className={"audioContainer_audioAMB1 "+theme+" ref_"+audioRef} onClick={(e) => containerSettings(e)}>
                                <div className="play-pause-button" style={{ background: "none",cursor: "default" }}>
                                    <FadeLoader
                                        color="#ffffff"
                                        cssOverride={{
                                            top: 19,
                                            left: 22
                                        }}
                                        height={6}
                                        margin={-10}
                                        speedMultiplier={0.4}
                                        width={3}
                                    />
                                </div>
                                <div className="timeCurrentDuration_bBarB1">0:00/0:00</div>
                                <div className='timeCode_container'>
                                    <div className="progressBar_bBarB1" style={{cursor: "default"}}></div>
                                </div>
                                <div className="volumeControl_bBarB1" style={{width: 50}}>
                                    <div className="volume-icon" onClick={(e) => containerSettings(e)} style={{width: 50,height: 50,cursor: "default",display: "flex",alignItems: 'center',justifyContent: 'center',marginLeft:0,marginTop: -10}}>
                                        <i className={"fi "+volumeIcon} style={{fontSize: 18,display: "flex"}}></i>
                                    </div>
                                </div>
                                <audio id="audioPlayer" className={"ref_"+audioRef} src={audioSrc} preload="metadata" onTimeUpdate={updateProgressBar} onVolumeChange={updateVolumeIcon} onError={() => setError(true)} onLoadedData={({currentTarget}) => {setDuration(currentTarget.duration);setLoading(false);}} playsInline={true}></audio>
                            </div>
                        </div>
                    :
                    <div className="mediaSpost" {...(comment === true ? {style: { cursor: "default", marginTop: -46, border: 'none' }}: {style:{cursor: "default", marginLeft: marginLeft }})}>
                        <img src={errorMediaDark} alt="Error" title="Error while loading the audio" referrerPolicy="no-referrer"/>
                    </div>
                }
            </div>
            </>
        }
    </>)
}

export default Audio;