import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HtmlToReact from "html-to-react";
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import * as User from "../../Components/Functions/Profile";
import { API_VERSION } from "../Functions";

export const ProfileSearch = ({ infos, theme }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // Set data
    const [badges, setBadges] = useState({
        certified: <></>,
        staff: <></>,
    });
    const [followers, setFollowers] = useState(0);
    const [about, setAbout] = useState('');
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    // GET USER'S ABOUT
    const getAbout = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/about`,
                headers: {
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                resolve(response.data.about);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    useEffect(() => {
        // Set badges
        if (infos.certified === "Yes" && infos.staff === "Yes") {
            setBadges({ certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
        }else{
            if (infos.staff === "Yes") {
                setBadges({ ...badges, staff: <i className="fi fi-ss-shield-check" style={{color:"#259DE1"}}></i>});
            }
            if (infos.certified === "Yes") {
                setBadges({ ...badges, certified: <i className="fi fi-sr-badge-check" style={{ color: theme === "darkTheme" ? "#fff": "#000" }}></i>});
            }
        }

        // Set followers count
        User.getFollowersCount(infos.user_uid).then((res) => {
            setFollowers(res)
        });

        // Get about of [user]
        getAbout(infos.user_uid).then((res) => {
            setAbout(res.aboutme);
        })
    }, [])

    // For account date
    var date = new Date(infos.date_account);
    var created = 'Créé avant le 09/08/23';
    var classMW = "before";
    if (infos.date_account !== "Created before the 09/08/23") {
        created = 'Créé le '+date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'numeric',year: '2-digit' });
        classMW = "after";
    }

    return (
        <div className={"profile_searchMVB1 "+theme}>
            <div className={"infoPart_profileSearchMVB1 "+classMW}>
                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                    <img alt={"@"+infos.usertag.replace('.', '_').toLowerCase()} title={infos.username} src={infos.avatar} className={"avatar_infoProfilePcVB1 ref_"+infos.user_uid}
                        onError={(e) => {
                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                            }
                        }}
                        style={loadingAvatar ? {display: "none"}: {}} onLoad={() => setLoadingAvatar(false)} referrerPolicy="no-referrer" />
                </div>
                <span id="username">{infos.username} {badges.certified}{badges.staff}</span>
                <span id="usertag" onClick={() => navigate("/@"+infos.usertag.toLowerCase().replace('.', '_'))}>@{infos.usertag.toLowerCase().replace('.', '_')}</span>
            </div>
            <div className="datePart_profileSearchMVB1">
                <span>{created}</span>
            </div>
            <div className={"aboutPart_profileSearchMVB1 "+theme}>
                <span>{HtmlToReact.Parser().parse(about)}</span>
            </div>
            <div className="bottomActions">
                <div id="followers">
                    <div className="followerTextPart_fBAMVB1">
                        <span>Followers</span>
                    </div>
                    <div className="countFollowersPart_fBAMVB1">
                        <span>{followers}</span>
                    </div>
                </div>
                <div id="profile" onClick={() => navigate('/@'+infos.usertag.toLowerCase().replace('.', '_'))}>
                    <span>{t('profile.modal.button')}</span>
                </div>
            </div>
        </div>
    )
}