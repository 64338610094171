// FOR AVATAR DECORATION
const generateRandomStyles = (params) => {
    if(params === null || params === undefined){
        const left = Math.random() * 100;
        const duration = Math.random() * 7 + 5;
        const delay = Math.random() * 10 + 8;
        const size = Math.random() * 7 + 9;
        
        const driftEnd = Math.random() * 125 - 25;    // 0% à 50%
    
        return {
            left: `${left}%`,
            width: `${size}px`,
            height: `${size}px`,
            animationDuration: `${duration}s`,
            animationDelay: `${delay}s`,
            animationTimingFunction: "linear",
            '--driftStart': left+'%',
            '--driftEnd': driftEnd+'%',
        };
    }else if(params === "medium"){
        const left = Math.random() * 100;
        const duration = Math.random() * 7 + 5;
        const delay = Math.random() * 10 + 8;
        const size = Math.random() * 3 + 5;
        
        const driftEnd = Math.random() * 125 - 25;    // 0% à 50%
    
        return {
            left: `${left}%`,
            width: `${size}px`,
            height: `${size}px`,
            animationDuration: `${duration}s`,
            animationDelay: `${delay}s`,
            animationTimingFunction: "linear",
            '--driftStart': left+'%',
            '--driftEnd': driftEnd+'%',
        };
    }else{
        const left = Math.random() * 100;
        const duration = Math.random() * 7 + 5;
        const delay = Math.random() * 10 + 8;
        const size = Math.random() * 1 + 3;
        
        const driftEnd = Math.random() * 125 - 25;    // 0% à 50%
    
        return {
            left: `${left}%`,
            width: `${size}px`,
            height: `${size}px`,
            animationDuration: `${duration}s`,
            animationDelay: `${delay}s`,
            animationTimingFunction: "linear",
            '--driftStart': left+'%',
            '--driftEnd': driftEnd+'%',
        };
    }
};

export default generateRandomStyles;