import React from "react";
import { useLocation } from "react-router-dom";
import * as Pusher from "pusher-js/with-encryption";
import Cookies from "js-cookie";
// import Context
import { usePresence } from "./Presence";
// import Functions
import {API_VERSION} from "../Functions";
// import Auth
import { retreiveInfos } from "../Functions/Auth";

const PusherContext = React.createContext(undefined);
export const usePusher = () => React.useContext(PusherContext);

export const PusherProvider = ({ children }) => {
    const clientRef = React.useRef()
    const location = useLocation();

    const [data, setData] = React.useState([]);
    const [logged, setLogged] = React.useState(0);

    const { setPresenceChannel } = usePresence();

    React.useEffect(() => {
        if (Cookies.get('logged') === '1') {
            setLogged(1);
            retreiveInfos().then((res) => setData(res));
        }
    }, []);

    React.useEffect(() => {
        if (logged === 1) {
            if (data !== undefined && data.length !== 0 && !clientRef.current) {
                clientRef.current = new Pusher("f5e8e38a42bd531cd908", {
                    cluster: "eu",
                    forceTLS: true,
                    authEndpoint: `https://api.snot.fr/v${API_VERSION}/pusher/user-auth?token=${Cookies.get("loginToken")}&uid=${data.uid}&username=${data.username}&usertag=${data.usertag}&app=web`,
                    userAuthentication: {
                        endpoint: `https://api.snot.fr/v${API_VERSION}/pusher/user-auth?token=${Cookies.get("loginToken")}&uid=${data.uid}&username=${data.username}&usertag=${data.usertag}&app=web`,
                    },
                    channelAuthorization: {
                        endpoint: `https://api.snot.fr/v${API_VERSION}/pusher/auth?token=${Cookies.get("loginToken")}&uid=${data.uid}&username=${data.username}&usertag=${data.usertag}&app=web`,
                    },
                });
                clientRef.current.signin();
                // Subscribe to presence
                const presenceChannel = clientRef.current.subscribe('presence-indicator');
                setPresenceChannel(presenceChannel);
            }
        }
    }, [data, clientRef, logged, location, setPresenceChannel]);

    return (
        <PusherContext.Provider value={clientRef?.current}>
            {children}
        </PusherContext.Provider>
    );
};